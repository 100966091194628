
import Pay from '@/components/payComment/payIndex.vue'
import {wxUtil} from '@/utils/wx_util'
import {
  defineComponent,
  onMounted,
  onUpdated,
  onUnmounted,
  reactive,
} from 'vue'
// import { Dialog, } from 'vant';
import { useRoute } from "vue-router";
import {useStore} from 'vuex'
import {orderDetail,orderStageDetail,orderInstallmentDetail } from  '@/api/order'
import {zswlPay, haierPay, psbcPay, msPay, haierxjPay, xydPay} from '@/api/pay'
import {handleDebounce} from "@/utils/tools"
import price from "@/utils/price";
export default defineComponent({
  name: 'Settlement',
  components: {
     Pay
  },
  props: {},
  setup() {
    // const router = useRouter()
    const route = useRoute();
    const store = useStore()
    store.commit('hidePaying')
    //表单数据
    let info = reactive({
        hasPay:route.query.hasPay,
        orderNumber:route.query.orderNumber,
        detail:{},
        returnUrl:location.href,
        payStatus:{
             0:'订单已取消', 1:'待支付', 2:'支付成功', 3:'退款申请中...', 4:'退款中...', 5:'已退款'
        },
        payType:{1:'微信', 2:'支付宝', 3:'虚拟币支付', 4:'苹果支付', 5:'学习卡兑换', 6:'赠送', 7:'优惠券支付', 8:'线下支付', 9:'积分兑换', 10:'免费'}
    })
    // 生命周期
    onMounted(async () => {
      let request =  route.query.type=='stages'?orderStageDetail:route.query.type=='installment'?orderInstallmentDetail:orderDetail
      info.detail = await request(
          info.orderNumber
      )
    })
 
    const showPay = handleDebounce(async ()=>{
        if(info.detail['channelTypeId']==38 || info.detail['channelTypeId']==7 || info.detail['channelTypeId']==43||info.detail['channelTypeId']==56 || info.detail['channelTypeId']==63 || info.detail['channelTypeId']==64){
            let data = null
            //芝士分期
            if(info.detail['channelTypeId']==38){
                data = await zswlPay({
                    "tagType": 4,
                    "orderNumber": info.orderNumber
                })
                
            }else if(info.detail['channelTypeId']==43){
                //邮储在线
                data = await psbcPay({
                    "tagType": 4,
                    "orderNumber": info.orderNumber,
                    returnUrl:location.href
                })
            }else if(info.detail['channelTypeId']==56){
              //民生银行
              data = await msPay({
                    "tagType": 4,
                    'channelCode':wxUtil.checkIsInWx()?'WX':'ALI',
                    "orderNumber": info.orderNumber,
                    returnUrl:location.href
                })
                console.log(data)
            }else if(info.detail['channelTypeId']==63){
              //海尔消金
              data = await haierxjPay({
                    "tagType": 4,
                    "orderNumber": info.orderNumber,
                })
            } else if(info.detail['channelTypeId']==64){
              //小雨点
              data = await xydPay({
                "tagType": 4,
                "orderNumber": info.orderNumber,
              })
            }
            else{
                data = await haierPay({
                    "tagType": 4,
                    "orderNumber": info.orderNumber
                })
            }
            window.open(data['body'])
        }else{
            store.commit('showPaying')
        } 
    },500) 

    const showType = ()=>{
      if(wxUtil.checkIsInWx()){
        return [1]
      }else if(wxUtil.checkIsInAli()){
        return [2]
      }else{
        return [1,2]
      }
    }
    // 暴露给模板
    return {
      showPay,
      info,
      price,
      showType
    }
  },
  computed: {},
})
