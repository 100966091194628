<template>
  <div>
    <div
      v-if="isMobile"
      class="full-screen"
    >
      <Swipe
        v-if="hasReport"
        ref="swiperRef"
        vertical
        :loop="false"
        :show-indicators="false"
        style="height: 100%;"
        @change="onSwipeChange"
      >
        <SwipeItem>
          <ReportPageHome :active="active == 0" />
        </SwipeItem>
        <SwipeItem>
          <ReportPageLetter :active="active == 1" />
        </SwipeItem>
        <!-- <SwipeItem>
          <ReportPageServiceData :active="active == 2" />
        </SwipeItem> -->
        <!-- <SwipeItem v-if="reportData.wordCount">
          <ReportPageWords :active="active == 3" />
        </SwipeItem> -->
        <SwipeItem v-if="reportData.totalStudyHour">
          <ReportPageCourse :active="active == 2" />
        </SwipeItem>
        <SwipeItem>
          <ReportPagePractice :active="active == nextCount(2, reportData.totalStudyHour, 0)" />
        </SwipeItem>
        <SwipeItem v-if="reportData.passSubjectCount">
          <ReportPageExam :active="active == nextCount(3, reportData.totalStudyHour, 0)" />
        </SwipeItem>
        <SwipeItem>
          <ReportPageWishMessage
            :active="active == nextCount(3, reportData.totalStudyHour, reportData.passSubjectCount)"
            @replay="replay"
          />
        </SwipeItem>
      </Swipe>
      <div
        v-else
        class="full-screen"
      >
        <ReportPageEmpty v-if="dataLoaded" />
      </div>
      <audio
        ref="audioRef"
        autoplay
        preload
        loop
        style="display:none;"
        @play="playing = true"
        @pause="playing = false"
      >
        <source
          src="~@/assets/student/studyReport/audio/report-bgm.mp3"
          type="audio/mpeg"
        >
      </audio>
    </div>
    <div v-else>
      <ReportQrcode />
    </div>
    <div
      v-show="isMobile"
      :class="{ 'bgm-controller': true, playing: playing }"
      @click="toggleBgm"
    />
    <div
      v-show="isMobile"
      class="hard-logo"
    />
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick, provide } from "vue";
import { useStore } from "vuex";
import { loadReportData2023 } from "@/api/studyReport"
import { Swipe, SwipeItem } from 'vant';
import ReportPageHome from "./components/studyReport2023/ReportPageHome.vue";
import ReportPageLetter from "./components/studyReport2023/ReportPageLetter.vue";
import ReportPageExam from "./components/studyReport2023/ReportPageExam.vue";
import ReportPageCourse from "./components/studyReport2023/ReportPageCourse.vue";
import ReportPagePractice from "./components/studyReport2023/ReportPagePractice.vue";
import ReportPageWishMessage from "./components/studyReport2023/ReportPageWishMessage.vue";
import ReportPageEmpty from "./components/studyReport2023/ReportPageEmpty.vue";
import ReportQrcode from "./components/studyReport2023/ReportQrcode.vue";
const store = useStore();
const swiperRef = ref();
const audioRef = ref();
const active = ref(-1);
const playing = ref(false);
const isMobile = ref(false);

const onSwipeChange = (index) => {
  active.value = index;
}
const replay = () => {
  swiperRef.value.swipeTo(0);
}
const toggleBgm = () => {
  if (audioRef.value.paused) {
    audioRef.value.play();
  } else {
    audioRef.value.pause();
  }
}
const hasReport = ref(false);
const reportData = reactive({
  authName: "",
  registerTime: "",
  totalDay: 0,
  studyCourseCount: 0,
  totalStudyHour: 0,
  exceedRatio: 0,
  submitReportCount: 0,
  submitReportQuesCount: 0,
  passSubjectCount: 0,
  maxPassSubjectScore: 0,
  maxPassSubjects: null
});
const checkMobileDivice = () => {
  var devices = ["iPhone", "Android"];
  var ua = window.navigator.userAgent;
  for (var i = 0; i < devices.length; i++) {
    if (ua.indexOf(devices[i]) != -1) {
      return true;
    }
  }
  return false;
}
const nextCount = (base, word, course) => {
  return base + (word ? 1 : 0) + (course ? 1 : 0);
}
const loading = ref(false);
const dataLoaded = ref(false);
onMounted(async () => {
  isMobile.value = checkMobileDivice();
  if (!store.state.user['token']) {
    //没有登录信息做一个缓冲
    await nextTick();
  }
  loading.value = true;
  dataLoaded.value = false
  //请求数据
  setTimeout(async () => {
    try {
      const { data } = await loadReportData2023();
      loading.value = false;
      dataLoaded.value = true
      if (data) {
        hasReport.value = true;
        Object.assign(reportData, data);
        nextTick(() => {
          active.value = 0;
        })
      }
    } catch (_) {
      //do nothing
    }
    loading.value = false;
    dataLoaded.value = true
  }, 500);
})
document.addEventListener("WeixinJSBridgeReady", function () {
  setTimeout(() => {
    if (audioRef.value.paused) {
      try {
        audioRef.value.muted = false;
        audioRef.value.pause();
        audioRef.value.play();
      } catch (e) {
        //
      }
    }
  }, 500);
}, false);
provide("reportData", reportData);
</script>
<style lang="scss" scoped>
.full-screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.bgm-controller {
  position: fixed;
  top: 40px;
  left: 40px;
  font-size: 48px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: url("~@/assets/student/studyReport/music-o-pause.png");
  background-size: 100%;

  &.playing {
    background: url("~@/assets/student/studyReport/music-o.png");
    background-size: 100%;
    animation: rotate-animate 1.5s linear infinite;
  }

  @keyframes rotate-animate {
    from {
      transform: rotateZ(0deg);
    }

    to {
      transform: rotateZ(360deg);
    }
  }
}

.hard-logo {
  position: fixed;
  top: 40px;
  right: 40px;
  width: 209px;
  height: 31px;
  background: url("~@/assets/student/studyReport/2023/hard-logo.png");
  background-size: 100%;
}
</style>