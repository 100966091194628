import request from '@/utils/request'
import { AxiosPromise } from 'axios'
  //正常支付 
  export function orderDetail(orderNumber): AxiosPromise {
    return request({
      url: `/hd-api/os/app/order/custom/collection/order-detail?orderNumber=${orderNumber}`,
      method: 'get',
    })
  }

  //分期支付
  export function orderStageDetail(orderNumber): AxiosPromise {
    return request({
      url: `/hd-api/os/app/order/installment/repayment/order-detail?orderNumber=${orderNumber}`,
      method: 'get',
    })
  }
  //学年分期支付
  export function orderInstallmentDetail(orderNumber): AxiosPromise {
    return request({
      url: `/hd-api/os/app/order/year-installment/repayment/order-detail?orderNumber=${orderNumber}`,
      method: 'get',
    })
  }