<template>
  <div :class="{ 'report-empty-page': true }">
    <PageSkeleton :show-footer="false">
      <div class="report-empty-page-content">
        <div class="report-text">
          暂无报告
        </div>
      </div>
      <div
        class="btn-relogin"
        @click="relogin"
      >
        重新登录
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import {useRouter} from "vue-router";
import PageSkeleton from "./PageSkeleton.vue";
const router = useRouter();
const relogin = () => {
    router.replace({ name: "Login" })
}
</script>
<style lang="scss" scoped>
.report-empty-page {
    background: url("~@/assets/student/studyReport/2023/bg-empty.png");
    background-size: 100%;

    &-content {
        margin-top: 280px;
        text-align: center;
    }

    .btn-relogin {
        width: 300px;
        height: 80px;
        line-height: 80px;
        border: 2px solid #333333;
        font-size: 34px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        text-align: center;
        margin: 70px auto 0 auto;
    }
}
</style>