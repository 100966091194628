<template>
  <div
    id="pdfjs"
    class="PDFpreview"
  >
    <iframe
  
      width="100%"
      height="100%"
      border="0"
      class="pdf-window"
      scrolling="yes"
      :src="pdfUrl"
    />
  </div>
</template>
  <script setup >
      import {ref,onMounted,nextTick} from "vue";
      import {wxUtil} from '@/utils/wx_util'
      import {useStore} from 'vuex'
      import wx from 'weixin-js-sdk'
      import { useRoute,useRouter } from "vue-router";
      import { getWechatSign,wxGetRedict } from  '@/api/pay'
      import { Toast} from 'vant';
      let pdfUrl = ref('')
      const store = useStore()
      const route = new useRoute(), router = useRouter()
    //   router.beforeEach((to, from, next) => {
    //         console.log(to)
    //       document.title = to.query.fileName ?to.query.fileName : 'pdf预览';
    //       next();
    //   });
      const showPage = ref(false)
     const  previewPdf = (urll)=>{
            let url = urll
            wx.showLoading({
            title: '加载中'
            });
            wx.downloadFile({
            url,
            success: ({
                    tempFilePath: filePath
                }) => {
                    wx.openDocument({
                    filePath,
                    fail: (err) => {
                        console.log('这是打开报错值：' + err)
                    },
                    complete: () => {
                        wx.hideLoading()
                    }
                    })
                },
                fail: (err) => {
                    console.log('这是请求报错：', err)
                    wx.hideLoading()
                }
            })
        }
        const fileUrl = 'static/pdf/web/viewer.html?file=';
        console.log(pdfUrl.value)
       
        console.log(454545454787878)
        console.log(route.query.file)
      onMounted(async () => {
        // encodeURIComponent
        pdfUrl.value = fileUrl + (route.query.file)
        setTimeout(()=>{
            showPage.value = true
        },100)
        //   if(wxUtil.checkIsInWx()){
        //         previewPdf(route.query.file)
              // if(!store.state.user.openId){
              //   let authUrl = await wxGetRedict({redirectUri:location.origin + '/getAuthUrl?p='+currentUrl})
              //   location.href = authUrl
              // }
            //   let weChatParameter = await getWechatSign({
            //       url:location.href.split('#')[0]
            //   })

            //   wx.config({
            //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
            //       appId: weChatParameter.appId, // 必填，公众号的唯一标识
            //       timestamp: weChatParameter.timestamp, // 必填，生成签名的时间戳
            //       nonceStr: weChatParameter.nonceStr, // 必填，生成签名的随机串
            //       signature: weChatParameter.signature,// 必填，签名
            //       jsApiList: ['onMenuShareAppMessage','onMenuShareTimeline','updateAppMessageShareData','updateTimelineShareData'], // 必填，需要使用的JS接口列表
                 
            //   });
             
            //   wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            //       let obj = {
            //           title: route.query.fileName, // 分享标题
            //           desc: '', // 分享描述
            //           link: location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            //           imgUrl: retuire('../../assets/file/pdfImg.png'), // 分享图标
            //           success: function () {
            //               Toast('分享成功')
            //           }
            //       }
            //       console.log(obj)
            //       if(wx.updateAppMessageShareData) {
            //           wx.updateAppMessageShareData({...obj})
            //           wx.updateTimelineShareData({...obj})
            //       } else {
            //           wx.onMenuShareAppMessage({...obj})
            //       }
                
            //   });
  
              //   wx.config({
              //       debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
              //       appId: weChatParameter.appId, // 必填，公众号的唯一标识
              //       timestamp: weChatParameter.timestamp, // 必填，生成签名的时间戳
              //       nonceStr: weChatParameter.nonceStr, // 必填，生成签名的随机串
              //       signature: weChatParameter.signature,// 必填，签名
              //       jsApiList: ['onMenuShareAppMessage','onMenuShareTimeline','updateAppMessageShareData','updateTimelineShareData'], // 必填，需要使用的JS接口列表
                   
              //   });
               
              //   wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
              //       let obj = {
              //           title: route.query.fileName, // 分享标题
              //           desc: '', // 分享描述
              //           link: location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              //           imgUrl: retuire('../../assets/file/pdfImg.png'), // 分享图标
              //           success: function () {
              //               Toast('分享成功')
              //           }
              //       }
              //       console.log(obj)
              //       if(wx.updateAppMessageShareData) {
              //           wx.updateAppMessageShareData({...obj})
              //           wx.updateTimelineShareData({...obj})
              //       } else {
              //           wx.onMenuShareAppMessage({...obj})
              //       }
                  
              //   });
    
          //   }else{
              // showPage.value = true
              // pdfUrl.value = fileUrl + encodeURIComponent(route.query.file)
          //   }
        });
  
      </script>
      <style lang="scss" scoped>
      .PDFpreview {
          width: 100%;
          height: 100vh;
      }
      </style>