<template>
  <div class="calendar-page">
    <div class="calendar-content">
      <div class="week">
        <div class="week-list" v-for="(item, index) in daysOfWeek" :key="index">
          {{ item }}
        </div>
      </div>
      <div class="date">
        <template v-for="(item, index) in calendarWeeks" :key="index">
          <div
            class="date-list"
            :class="{ check: item.checkStatus }"
            @click="chooseDate(item, index)"
          >
            <div
              class="date-num"
              :class="{ 'disabled-color': item.isWeekEnd || !item.isAllTime }"
            >
              {{ formatDate(item.dateTimeStamp * 1000) }}
            </div>
            <div
              class="date-status"
              :class="{ 'disabled-color': item.isWeekEnd || !item.isAllTime }"
            >
              <template v-if="!item.isWeekEnd">
                <span
                  v-if="
                    item.amReservationNum != 0 && item.pmReservationNum != 0
                  "
                  >可约</span
                >
                <span
                  v-if="
                    item.amReservationNum == 0 && item.pmReservationNum == 0
                  "
                  >约满</span
                >
                <span
                  v-if="
                    item.amReservationNum != 0 && item.pmReservationNum == 0
                  "
                  >上午</span
                >
                <span
                  v-if="
                    item.amReservationNum == 0 && item.pmReservationNum != 0
                  "
                  >下午</span
                >
              </template>
              <template v-if="item.isWeekEnd">
                <span style="color: #fff">空</span>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineExpose, defineEmits } from "vue";
const props = defineProps(["stepNum", "stepType", "calendarList"]);
const emit = defineEmits(["showSelectLayer", "chooseSelectTime"]);
const daysOfWeek = ref(["一", "二", "三", "四", "五", "六", "日"]);
// 往后30天的日历数组
const calendarWeeks = ref([]);
// 格式化日期格式
const formatDate = (date) => {
  let time = new Date(date);
  const month = String(time.getMonth() + 1).padStart(2, "0");
  const day = String(time.getDate());
  if (isToday(time)) {
    return `今天`;
  }
  if (isNewMonth(date)) {
    return `${time.getMonth() + 1}月`;
  }
  return `${day}`;
};
// 判断是否为今天
const isToday = (date) => {
  return (
    date.getFullYear() === new Date().getFullYear() &&
    date.getMonth() === new Date().getMonth() &&
    date.getDate() === new Date().getDate()
  );
};
// 判断是否为新月份的第一天
const isNewMonth = (timestamps) => {
  const currentDate = new Date(props.calendarList[0].dateTimeStamp * 1000); // 第一个时间戳对应的日期，即今天
  const nextDate = new Date(timestamps);
  // 比较当前日期的月份与下一个日期的月份
  if (
    currentDate.getMonth() !== nextDate.getMonth() &&
    currentDate.getDate() == 1
  ) {
    // 如果月份不同，表示已跨月，返回这个时间戳
    return true;
  } else {
    // 如果没有跨月，则返回null或其他合适的值
    return null;
  }
};
// 生成日历
const generateCalendar = () => {
  let dateList = props.calendarList;
  // 日历中周末的数据增加标识，置灰，不显示上下午数量
  for (let index = 0; index < dateList.length; index++) {
    // dateList[index].dateTimeStamp = dateList[index].dateTimeStamp * 1000;
    let weekTime = new Date(dateList[index].dateTimeStamp * 1000).getDay();
    if (weekTime == 6 || weekTime == 0) {
      dateList[index].isWeekEnd = true;
    } else {
      dateList[index].isWeekEnd = false;
    }
    if (
      !dateList[index].amReservationNum &&
      !dateList[index].pmReservationNum
    ) {
      dateList[index].isAllTime = false;
    } else {
      dateList[index].isAllTime = true;
    }
  }
  const today = new Date(dateList[0].dateTimeStamp * 1000);
  const firstDayOfWeek = today.getDay(); // 获取今天是一周的第几天
  // 往前补全第一周
  const startDate = new Date(today);
  //推算出周一是月几号
  startDate.setDate(today.getDate() - firstDayOfWeek + 1);
  // // 往后取30天
  // const endDate = new Date(today);
  // endDate.setDate(today.getDate() + 30);

  // 创建日历数组
  let firstWeekDate = new Date(startDate);
  let nowWeekDate = new Date(today);
  console.log(today.getDate(), firstDayOfWeek);
  while (firstWeekDate < nowWeekDate) {
    dateList.unshift({
      dateTimeStamp: firstWeekDate.getTime() / 1000,
      isWeekEnd: true,
    });
    firstWeekDate.setDate(firstWeekDate.getDate() + 1);
  }
  console.log(dateList);
  calendarWeeks.value = dateList;
};
const chooseDate = (data, index) => {
  if (
    !data.isWeekEnd &&
    data.isAllTime &&
    data.amReservationNum &&
    data.pmReservationNum
  ) {
    emit("showSelectLayer", { data, index });
  } else if (
    !data.isWeekEnd &&
    (data.amReservationNum || data.pmReservationNum)
  ) {
    let reservationTimeType = data.amReservationNum > 0 ? 1 : 2;
    emit("chooseSelectTime", { reservationTimeType, index });
  }
};

const checkDate = (index) => {
  for (let index = 0; index < calendarWeeks.value.length; index++) {
    calendarWeeks.value[index].checkStatus = false;
  }
  calendarWeeks.value[index].checkStatus = true;
};
defineExpose({
  generateCalendar,
  checkDate,
});
</script>
<style lang="scss" scoped>
.calendar-content {
  width: 100%;
  padding: 0 16px 30px 16px;
  box-sizing: border-box;
}
.week {
  width: 100%;
  display: flex;
  margin: 30px 0 16px 0;
  // justify-content: space-around;
  .week-list {
    width: 76px;
    text-align: center;
    color: #999;
    font-size: 24px;
    margin-right: 20px;
  }
  .week-list:last-child {
    margin-right: 0;
  }
}
.date {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-around;
  .date-list {
    width: 76px;
    height: 76px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #333;
    font-size: 22px;
    margin-right: 20px;
    border-radius: 8px;
    .date-num {
      margin-bottom: 8px;
      line-height: 1;
      font-weight: 600;
    }
    .date-status {
      color: #1e4290;
    }
    .disabled-color {
      color: #999;
    }
  }
  .date-list:nth-child(7n) {
    margin-right: 0;
  }
  .check {
    background: #1e4290;
    color: #fff;
    .date-status {
      color: #fff;
    }
  }
}
</style>
