<template>
  <div class="certificate-base-info">
    <div class="info-bg-con">
      <div class="title">领取信息</div>
      <div class="content">
        <div class="item">
          领取状态：{{
            infoData.status == 0
              ? "待匹配"
              : infoData.status == 1
              ? "待发放"
              : infoData.status == 2
              ? "待领取"
              : infoData.status == 3
              ? "待签署"
              : infoData.status == 4
              ? "待发货"
              : infoData.status == 5
              ? "已发货"
              : infoData.status == 6
              ? "已预约"
              : "已领取"
          }}
        </div>
        <template v-if="infoData.status != 2">
          <div class="item">
            领取方式：{{
              infoData.receiveType == 1
                ? "邮寄领取"
                : infoData.receiveType == 2
                ? "本人校区领取"
                : "他人校区代领"
            }}<span
              class="item-btn"
              @click="showContract(infoData.contractUrl)"
              v-if="
                (infoData.receiveType == 1 &&
                  (infoData.status == 4 ||
                    infoData.status == 5 ||
                    infoData.status == 7)) ||
                infoData.receiveType == 3
              "
              >委托书</span
            >
          </div>
          <!-- 邮寄领取 -->
          <template v-if="infoData.receiveType == 1">
            <div
              class="item"
              v-if="infoData.status == 5 || infoData.status == 7"
            >
              物流单号：{{ infoData.logisticsNumber
              }}<span class="item-btn" @click="copyText">复制</span>
            </div>
            <div class="item" v-if="infoData.status == 7">
              确认领取时间：{{ infoData.receiveTime }}
            </div>
          </template>
          <!-- 自己领取 -->
          <template v-if="infoData.receiveType == 2">
            <div
              class="item"
              v-if="infoData.status == 6 || infoData.status == 7"
            >
              预约时间：{{ infoData.reservationDate
              }}{{ infoData.reservationTimeType == 1 ? "上午" : "下午" }}
            </div>
            <div
              class="item"
              v-if="infoData.status == 6 || infoData.status == 7"
            >
              领取地点：{{ infoData.takeAddress }}
            </div>
          </template>
          <!-- 他人领取 -->
          <template v-if="infoData.receiveType == 3">
            <div class="item">
              预约时间：{{ infoData.reservationDate }}
              {{ infoData.reservationTimeType == 1 ? "上午" : "下午" }}
            </div>
            <div class="item">领取地点：{{ infoData.takeAddress }}</div>
            <div class="item" @click="changHide">
              代领人：{{ infoData.takeAuthName }}（{{
                longPress ? infoData.takeAuthNum : infoData.takeAuthNumHide
              }}）{{ longPress ? "显示" : "隐藏" }}
            </div>
          </template>
          <template
            v-if="
              (infoData.receiveType == 2 || infoData.receiveType == 3) &&
              infoData.status == 7
            "
          >
            <div class="item">确认领取时间：{{ infoData.receiveTime }}</div>
            <div class="item">领取合照：</div>
            <div class="item-img">
              <div
                class="img-url"
                v-for="(item, index) in infoData.receivePhotoFiles"
                :key="index"
              >
                <van-image
                  class="img-url-src"
                  fit="cover"
                  :src="item.path"
                  @click="showImgPreview(item.path)"
                />
              </div>
            </div>
            <div class="item">领取签名：</div>
            <div class="item-img" v-if="infoData.receiveSignFile">
              <div class="img-url qian">
                <van-image
                  class="img-url-src"
                  fit="contain"
                  :src="infoData.receiveSignFile.path"
                />
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineExpose,
  defineEmits,
  defineOptions,
  directives,
} from "vue";
import { ImagePreview, Toast, Image as VanImage } from "vant";
import commonJs from "@/utils/common";
import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();
const props = defineProps(["infoData"]);
const emit = defineEmits(["showCertificatePrivew"]);
const longPress = ref(false);
// let startTimeNum = 0;
// const startTime = () => {
//   startTimeNum = Math.floor(Date.now() / 1000);
// };
// const endTime = () => {
//   let nowTimeNum = Math.floor(Date.now() / 1000);
//   console.log(nowTimeNum, startTimeNum);
//   if (nowTimeNum - startTimeNum >= 2) {
//     longPress.value = true;
//   }
// };
const changHide = () => {
  longPress.value = !longPress.value;
};
const showImgPreview = (src) => {
  ImagePreview({
    images: [src],
    showIndex: false,
    minZoom: 1,
  });
};
const copyText = async () => {
  try {
    await toClipboard(props.infoData.logisticsNumber);
    Toast("复制成功");
  } catch (e) {
    Toast("复制失败");
  }
};

//打开委托书
const showContract = (url) => {
  let typeJudge = commonJs.judgeUserAgent();
  if (typeJudge.isiOS) {
    window.webkit.messageHandlers.openContract.postMessage(url); //Contract交互的方法名
  } else {
    window.hade.openContract(url);
  }
};
</script>
<style lang="scss" scoped>
.certificate-base-info {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 160px;
  .info-bg-con {
    width: 100%;
    background: #fff;
    padding: 30px 0 0 40px;
    box-sizing: border-box;
  }
  .title {
    font-size: 28px;
    color: #222;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .item {
    font-size: 24px;
    color: #222;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
  }
  .item-btn {
    color: #1e4290;
    margin-left: 20px;
  }
  .item-img {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    .img-url {
      width: 200px;
      height: 200px;
      border-radius: 8px;
      margin-right: 34px;
      overflow: hidden;
    }
    .qian {
      width: 200px;
      height: 72px;
      background: #f7f7f7;
    }
    .img-url-src {
      width: 100%;
      height: 100%;
    }
    .img-url:last-child {
      margin-right: 0;
    }
  }
}
</style>
