<template>
  <div class="activityPoster">
    <div class="bgPoster">
      <img
        src="../../assets/activity/bgPoster_graduate.jpg"
      >
      <div
        class="ruleBtn c-fff flexBox h-center"
        @click="showRule"
      >
        活动规则
      </div>
      <div
        v-if="activeInfo.status == 2"
        class="formPost c-fff"
      >
        <div class="title">
          <img
            src="../../assets/activity/form-title.png"
          >
        </div>
        <Field
          v-model="resourcesName"
          name="resourcesName"
          placeholder="您的姓名"
        />
        <Field
          v-model="resourcesMobile"
          style="margin-top:14px;"
          name="resourcesMobile"
          placeholder="您的电话"
          type="number"
          maxlength="11"
        />
        <div class="flexBox derectionColumn mt30">
          <img
            class="btnImg"
            src="../../assets/activity/submitBtn_graduate.png"
            alt=""
            @click="submitForm(false)"
          >
          <!-- <img
            class="btnImg mt15"
            src="../../assets/activity/sharefrend_graduate.png"
            alt=""
            @click="sharePost"
          > -->
        </div>
      </div>
      <div
        v-if="activeInfo.status == 2"
        class="btn-share-wrap"
      >
        <img
        
          class="btn-share"
          src="../../assets/activity/sharefrend_graduate_new.png"
          alt=""
          @click="sharePost"
        >
      </div>
      <div
        v-else
        class="formPost disable flexBox h-center derectionColumn"
      >
        <img
          class="noData"
          src="../../assets/activity/disableActive.png"
          alt=""
        >
        <div>活动已结束，期待下次参与</div>
      </div>
    </div>
    <CreatedPost
      v-if="showShareBox"
      :list="posterList"
      :params="params"
      @closeShare="closeShare"
    />
    <Popup
      v-model:show="showRuleBox"
      round 
      closeable
      position="bottom"
      :style="{ height: '250px' }"
    >
      <div class="c-333 p35 tipsss">
        <div class="ruleTitle">
          活动规则
        </div>
        <div class="ruleContent">
          • 活动期间，凡是哈德教育学员亲友报名学历提升课程，即可申领亲友助学补贴，最高4900元助学金；
        </div>
        <div class="ruleContent">
          • 亲友同行并成功报名，最高可获得500元/人转介奖金；
        </div>
        <div class="ruleContent">
          • 活动名额有限，先到先得。
        </div>
      </div>
    </Popup>
    <!--补充表单-->
    <Popup
      v-model:show="showFixModal"
      round 
      :closeable="false"
      position="bottom"
      :style="{ height: '260px' }"
    >
      <div class="p35">
        <div class="share-title-wrap">
          <img
            class="share-title"
            src="../../assets/activity/share-title.png"
            alt=""
          >
        </div>
        <Field
          v-model="resourcesName"
          name="resourcesName"
          placeholder="您的姓名"
        />
        <Field
          v-model="resourcesMobile"
          style="margin-top:14px;"
          name="resourcesMobile"
          placeholder="您的电话"
          type="number"
          maxlength="11"
        />
        <div class="share-submit-wrap">
          <img
            class="share-submit"
            src="../../assets/activity/share-own-qr.png"
            alt=""
            @click="sharePost"
          >
        </div>
      </div>
    </Popup>
  </div>
</template>
<script setup>
    import { reactive, ref, onMounted, } from 'vue';
    import { Toast,Field,Popup } from 'vant';
    import { activityVisit,submitVisit,getPosterList} from "@/api/activity"
    import {wxGetRedict,wxGetOpenid} from  '@/api/pay'
    import {wxUtil} from '@/utils/wx_util.ts'
    import {uuid,isPhoneNumber} from "@/utils/tools"
    import {useRoute } from 'vue-router'
    import {useStore} from 'vuex'
    import CreatedPost from "./components/createdPost.vue";
    const hdUuid = ref('')
    const route = new useRoute()
    const store = useStore()
    const resourcesMobile = ref(''),resourcesName = ref('')
    const showShareBox = ref(false),posterList = ref([]),showRuleBox=ref(false),activeInfo=ref({
        
    })
    const showFixModal = ref(false);
    const params = ref({
            wxOpenid:'',
            hdUuid:hdUuid.value,
            joinerId:route.query.joinerId
     })
    const closeShare = ()=>{
        showShareBox.value = false  
    }
    
    onMounted( async () => {
        if(wxUtil.checkIsInWx()){
            if(!store.state.user.openId){
              let currentUrl = JSON.stringify({
                name:route.name,
                query:route.query
              })
              let authUrl = await wxGetRedict({redirectUri:location.origin + '/getAuthUrl?p='+currentUrl})
              location.href = authUrl
            }else{
                params.value.wxOpenid = store.state.user.openId
            }
        
        }else{
            params.value.wxOpenid = ''
        }
        if(localStorage.getItem('uuid')){
            hdUuid.value = localStorage.getItem('uuid')
        }else{
            hdUuid.value = uuid()
            localStorage.setItem('uuid', hdUuid.value )
        }
        params.value.hdUuid = hdUuid.value;
        activeInfo.value = await activityVisit({
            ...params.value,
            posterId:route.query.posterId,
            referrer:location.href
        });
        console.log(activeInfo.value)
    })
    const submitForm = async (clearToast)=>{
        if(!resourcesName.value || !resourcesMobile.value){
            Toast("请填写完整的姓名和电话");
            return 
        }
        if(!isPhoneNumber(resourcesMobile.value)){
            Toast("请填写正确的手机格式");
            return 
        }
        await submitVisit({
            resourcesName:resourcesName.value,
            resourcesMobile:resourcesMobile.value,
            posterId:route.query.posterId,
            sharedId:route.query.sharedId || '',
            referrer:location.href,
            ...params.value,
        });
        if(!clearToast){
          Toast("提交成功");
        }
    }   

    const sharePost = async ()=>{
        if(!resourcesName.value || !resourcesMobile.value){
          if(!showFixModal.value){
            showFixModal.value = true;
          }else{
            Toast("请填写完整的姓名和电话");
          }
          return 
        }
        
        if(!isPhoneNumber(resourcesMobile.value)){
            if(!showFixModal.value){
              showFixModal.value = true;
            }else{
              Toast("请填写正确的手机格式");
            }
            return 
        }
        await submitForm(true);
        let data = await getPosterList({
            ...params.value,
        });
        console.log(data)
        posterList.value = data
        
        setTimeout(()=>{
            showFixModal.value = false;
            showShareBox.value = true  
        },500)
    }

    const showRule = ()=>{
        showRuleBox.value = true
    }
</script>
<style lang="scss" scoped>
    .mt30{
        margin-top:30px;
    }
    .mt15{
        margin-top:25px;
    }
    .p35{
        padding:35px;
    }
    .tipsss{
        line-height: 44px;
        .ruleTitle{
            font-size: 30px;
            text-align: center;
            font-weight: bolder;
            margin-bottom:25px; 
        }
        .ruleContent{
          text-indent: var(--van-font-size-md);
          font-size: var(--van-font-size-md);
        }
        div{
            font-size: 28px;
            margin-bottom:15px;
        }
    }
    .activityPoster{
        
        :deep(.van-cell) {
            padding: 18px 20px !important;
            border-radius: 6px !important;
            background: #F2F2F2;
            font-size:24px;
        }
        .bgPoster{
            position: relative;
            width:100%;
            img{
                width:100%;
            }
            .ruleBtn{
                position:absolute;
                top:25px;
                right:40px;
                width: 160px;
                height:44px;
                border-radius: 90px;
                border: 1px solid #FFFFFF;
                text-align: center;
                font-size:24px;
            }
            $form-top:19.3%;
            $form-height:420px;
            .formPost{
                position: absolute;
                top: $form-top;
                height:$form-height;
                left:40px;
                right: 40px;
                border: 1px solid #FFFFFF;
                border-radius: 20px;
                backdrop-filter: blur(0px);
                padding:30px 38px;
                background-color: #fff;
                box-sizing: border-box;
                .title{
                    margin-bottom:30px;
                    text-align: center;
                    line-height: 0;
                    img{
                      width: 80%;
                    }
                }
                .btnImg{
                    width:434px;
                 
                }
            }
            .btn-share-wrap{
              position: absolute;
              top:calc(#{$form-top} + #{$form-height} + 10px);
              left:40px;
              right: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              .btn-share{
                width: 546px;
                height:134px;
              }
            }
            .disable{
                background:#fff;
                color:#222222;
                font-size: 28px;
                font-weight: bolder;
                .noData{
                    width:285px;
                }
            }
        }
      
    }
    .share-title-wrap{
      display: flex;
      justify-content: center;
      margin-bottom: 42px;
      .share-title{
        width: 484px;
        height: 48px;
      }
    }
    .share-submit-wrap{
      display: flex;
      justify-content: center;
      margin-top: 28px;
      .share-submit{
        width: 594px;
        height: 80px;
      }
    }
</style>
