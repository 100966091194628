<template>
  <div class="drainage">
     <van-image style="width:100%;" :src="require('@/assets/landPage/bg@2x.png')" fit="cover"/>
     <div class="contentBox">
         <div class="norem-content">
            <img class="logoImg" src="@/assets/landPage/hade@2x.png" alt="">
            <div class="courseBox">
                <div class="titleImg"><img src="@/assets/landPage/title@2x.png" alt=""></div>
                <div class="navTab">
                    <div class="tabItems">
                        <div class="tabItem" :class="{'active':count==0}" @click="count=0">考研英语</div>
                        <div class="tabItem" :class="{'active':count==1}" @click="count=1">考研政治</div>
                        <div class="tabItem" :class="{'active':count==2}" @click="count=2">管理类联考</div>
                    </div>
                </div>
                <div class="courseCard">
                    <div class="cardItem" v-for="(item,index) in list[count]" :key="index" @click="toLink(item)">
                        <div class="shopImg"><img :src="item.type==0?item.imgUrl:require('@/assets/landPage/file@2x.png')" alt=""></div>
                        <div class="shopInfo">
                            <div class="headBox">
                                <div class="shopTitle">{{item.title}}</div>
                                <div class="shopTip">{{item.tip}}</div>
                            </div>
                            <div class="btmBox">
                                <div class="leftBtm" v-show="item.type==0">
                                    <div class="noMoney">免费</div>
                                    <div class="price">¥599</div>
                                </div>
                                <div  v-show="item.type==1"></div>
                                <div class="downLoadImg" v-show="item.type==1"><img src="@/assets/landPage/downLoad@2x.png" alt=""></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-image style="width:100%;" :src="require('@/assets/landPage/lastBgm2x.png')" fit="cover"/>
     </div>
     
  </div>
</template>
<script>

import { Image as VanImage } from 'vant';
import {
  ref,
  onMounted,
  onUpdated,
  onUnmounted,
} from 'vue'
export default {
   components: {
    VanImage
  },
   setup() {
    const count = ref(0)
    const list = {
        0:[
            {
                type:0,  //0是课程，1是文件
                title:'英语精品强化课',
                tip:'从基础到强化分期复习·高效实用',
                imgUrl:require('@/assets/landPage/0-1.jpg'),
                price:'599',
                linkUrl:'https://m.hadecj.com/course_detail?id=109&courseType=5&school_id=2'
            },
            {
                type:1,  //0是下载，1是文件
                title:'历年英语作文关键词',
                tip:'从基础到强化分期复习·高效实用',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=5'
            },
            {
                type:1,  //0是下载，1是文件
                title:'2022考研英语真题参考答案',
                 tip:'熟练掌握必背单词，考试拿高分',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=6'
            },
            {
                type:1,  //0是下载，1是文件
                title:'英语2真题必背3200',
                tip:'做真题，找题感最新考研真题',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=7'
            },
        ],
           1:[
            {
                type:0,  //0是下载，1是文件
                title:'2023届考研政治全程导学课',
                tip:'精细化各项知识点，强化课程',
                imgUrl:require('@/assets/landPage/1-1.png'),
                price:'599',
                linkUrl:'https://m.hadecj.com/course_detail?id=74&courseType=5&school_id=2'
            },
          
            {
                type:0,  //0是下载，1是文件
                title:'2023届考研政治（政治经济学）',
                tip:'深刻解读政治核心概念',
                imgUrl:require('@/assets/landPage/1-2.png'),
                price:'599',
                linkUrl:'https://m.hadecj.com/course_detail?id=57&courseType=5&school_id=2'
            },
            {
                type:1,  //0是下载，1是文件
                title:'考研政治史纲熟读材料',
                 tip:'全国考研政治顶级名师整理',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=8'
            },
             {
                type:1,  //0是下载，1是文件
                title:'考研政治思修背诵材料',
                tip:'精准定位政治考研高频考点',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=9'
            },
        ],
        2:[
            {
                type:0,  //0是下载，1是文件
                title:'0基础管综强化复习课',
                tip:'导师亲讲视频，指点重难习题',
                imgUrl:require('@/assets/landPage/2-1.png'),
                price:'599',
                linkUrl:'https://m.hadecj.com/course_detail?id=108&courseType=5&school_id=2'
            },
          
            {
                type:1,  //0是下载，1是文件
                title:'2022年管理类联考综合能力真题及答案',
                 tip:'全国考研政治顶级名师整理',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=2'
            },
            {
                type:1,  //0是下载，1是文件
                title:'MBA&MEM院校复试真题',
                tip:'最新最全MBA院校信息统计',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=3'
            },
              {
                type:1,  //0是下载，1是文件
                title:'管理院校信息表（仅供参考）',
                tip:'往年各大热门院校的复试真题',
                imgUrl:'',
                price:'599',
                linkUrl:'https://m.hadecj.com/library_detail?id=4'
            },
        ]

    }
    const toLink = (item) => {
     location.href=item.linkUrl
    }
    // 生命周期
    onMounted(() => {
      console.log('mounted vue3 typescript')
    })
    onUpdated(() => {
      console.log('updated vue3 typescript')
    })
    onUnmounted(() => {
      console.log('onUnmounted vue3 typescript')
    })
    // 暴露给模板
    return {
      count,
      list,
      toLink
    }
  },
}
</script>
<style lang="less" scoped>
    html,body{
        width: 100%;height: 100%;
    }
    .drainage{
      .contentBox{
           width:100%;
           margin-top:-18px;
            background-image: url("~@/assets/landPage/bgBtm@2x.png");
            background-size:100% 100%;
            background-repeat: no-repeat;
          
          .norem-content{
            width:100%;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            padding:0 37px 0 21px;
            position: relative;
            .logoImg{
                position: absolute;
                // z-index:-1;
                right:20px;
                top:12px;
                width:277px;
            }
            .courseBox{
                background:#fff;
                width:100%;
                margin:110px 0 40px 0;
                position: relative;
                border-radius: 12px;
                box-sizing: border-box;
                // padding-bottom:23px;
                .titleImg{
                    width:640px;
                    margin:-32px auto 0 auto;
                    img{
                        width:100%;
                        height:auto;
                    }
                }
                    .navTab{
                        margin:27px auto 0 auto;
                        border-top:1px solid #EBF5FF;
                        .tabItems{
                            padding:20px 0;
                            display: flex;
                            align-items: center;
                            width:92%;
                            margin:0 auto;
                             background:#EBF5FF;
                              border-radius: 0px 0px 12px 12px;
                            .tabItem{
                                width:calc(100% / 3);
                                font-size:28px;
                                font-family: PingFangSC-Regular, PingFang SC;
                                color: #333333;
                                line-height: 40px;
                                text-align: center;
                                border-right:1px solid #333;
                                padding:0 10px;
                                z-index:1;
                                position: relative;
                                &.tabItem:last-child{
                                    border-right:none;
                                }
                            }
                            .active{
                                line-height: 48px;
                                font-size: 34px;
                                font-family: PingFangSC-Medium, PingFang SC;
                                font-weight: 800;
                                color: #333333;
                                position:relative
                            }  
                            .active:after{
                                content: '';
                                position:absolute;
                                left:calc((100% - 142px) / 2);
                                bottom:-2px;
                                width: 142px;
                                height: 16px;
                                z-index:-1;
                                border-bottom: 16px solid #F4D952;
                                display: inline-block;
                                
                            } 
                        }
                        
                    }
                    .courseCard{
                        padding:0 30px;
                        margin-top:32px;
                        .cardItem{
                            
                            box-sizing: border-box;
                            width:100%;
                            border-radius: 12px;
                            box-shadow:0px 2px 12px 0px rgba(0, 0, 0, 0.06);
                            padding:22px 20px;
                            display: flex;
                            justify-content: space-between;
                            margin-bottom:30px;
                            .shopImg{
                                width: 221px;
                                height: 148px;
                                border-radius: 4px;
                                img{
                                    width: 100%;
                                    height:100%;
                                }
                            }
                            .shopInfo{
                                flex:1;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                padding-left:20px;
                                .headBox{
                                   .shopTitle{
                                       width: 360px;
                                       line-height: 36px;
                                        font-size: 28px;
                                        font-family: PingFangSC-Medium, PingFang SC;
                                        font-weight: bolder;
                                        color: #333333;
                                        line-height: 36px;
                                    } 
                                   .shopTip{
                                    font-size: 24px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #999999;
                                    line-height: 33px;
                                    margin-top:4px;
                                   }
                                }
                                .btmBox{
                                    display: flex;
                                    align-items: flex-end;
                                    justify-content: space-between;
                                    .leftBtm{
                                        display: flex;
                                        align-items: flex-end;
                                        .noMoney{
                                            font-size: 28px;
                                            font-family: PingFangSC-Semibold, PingFang SC;
                                            font-weight: 600;
                                            color: #FF7023;
                                            line-height: 40px;
                                            margin-right:9px;
                                        }
                                        .price{
                                           font-size: 20px;
                                            font-family: PingFangSC-Light, PingFang SC;
                                            font-weight: 300;
                                            color: #CCCCCC;
                                            line-height: 28px; 
                                            text-decoration:line-through
                                        }
                                    } 
                                    .downLoadImg{
                                        display: flex;
                                        align-items: flex-end;
                                        width: 130px;
                                        margin-top:7px;
                                        img{
                                            width:100%;
                                        }
                                    }  
                                }
                            }
                        }
                    }
                
                    }  
                }
      }
        
    }
</style>