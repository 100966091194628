<template>
    <div></div>
</template>
<script>
import {onMounted} from 'vue'
import {useRoute,useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {wxGetOpenid } from  '@/api/pay'
export default {
    name:'GetAuthUrl',
     setup() {
         const route = new useRoute()
         const router = new useRouter()
         const store = useStore()
         // 生命周期
        onMounted(async () => {
            let code = route.query.code
            let openId = await wxGetOpenid({code:code})
            store.commit('setOpenId',openId)
            let url = JSON.parse(route.query.p)
            let query = {...url.query,canPay:1}
            console.log(query)
            router.replace({
                name:url.name,
                query:query
            })
           
        })
        return {
            
        };
    },
}
</script>
