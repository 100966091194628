// 收费api

import request from '@/utils/request'
import { AxiosPromise } from 'axios'

export function unpaidOrder(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/fee/plan/unpaid-order/${params.feePlanId}`,
    method: 'get',
  })
}

export function feePlan(params): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/fee/plan/class-list/${params.feePlanId}/class/${params.classId}`,
    method: 'get',
  })
}

export function getclassList(feePlanId): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/fee/plan/class-list/${feePlanId}`,
    method: 'get',
  })
}

export function getSchoolList(): AxiosPromise {
  return request({
    url: `/hd-api/os/user/student/user-order/school-area`,
    method: 'get',
  })
}

export function createdOrder(params): AxiosPromise {
  return request({
    url: `/hd-api/os/h5/order/down-order/fee-plan`,
    method: 'post',
    data: params,
  })
}

export function getOrderDetail(orderNumber): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/fee/plan/order-detail/${orderNumber}`,
    method: 'get',
  })
}

export function getRecordList(feePlanStudentId): AxiosPromise {
  return request({
    url: `/hd-api/os/app/order/fee/plan/order-item/${feePlanStudentId}`,
    method: 'get',
  })
}

export function cancelOrder(orderId): AxiosPromise {
  return request({
    url: `/hd-api/os/h5/order/cancel-order/${orderId}`,
    method: 'delete',
  })
}


// 判断报考时间冲突情况(报考费)
export function examTimeConflict(params): AxiosPromise {
  return request({
    url: `/hd-api/os/h5/order/down-order/exam-time-conflict`,
    method: 'POST',
    data:params
  })
}
export function getSchoolExamPlan(planId:number): AxiosPromise {
  return request({
    url: `/hd-api/os/school-exam-plan/query/info/${planId}`,
    method: 'GET',
  })
}
