import request from '@/utils/request'
import { AxiosPromise } from 'axios'
export const wxUtil = {
    /**
     * 是否在微信浏览器中打开
     */
     checkIsInWx(): boolean {
        const ua = window.navigator.userAgent.toLowerCase();
        const match = ua.match(/MicroMessenger/i);
        if (match === null) {
            return false;
        }
        if (match.includes('micromessenger')) {
            return true;
        }
        return false;
    },

    /**
     * 是否在支付宝中打开
     */
     checkIsInAli(): boolean {
        return /AlipayClient/.test(window.navigator.userAgent);
    }

}

export default wxUtil