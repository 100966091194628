<template>
  <div class="reservation-other-page">
    <reservationStep :stepType="3" :stepNum="2"></reservationStep>
    <div class="reservation-other-time">
      <div class="page-content">
        <div class="calendar-title">预约信息</div>
        <div class="calendar-address">
          <div class="content-left">领取地点</div>
          <div class="content-right">{{ takeAddress }}</div>
        </div>
        <div class="calendar-time">
          <div class="content-left">预约时间</div>
          <div
            class="content-right"
            :class="{ 'empty-time': !reservationDate }"
          >
            {{ reservationDate ? reservationDate : "请点击日历选择" }}
          </div>
        </div>
        <calendar
          ref="calendarObj"
          @showSelectLayer="showSelectLayer"
          @chooseSelectTime="chooseSelectTime"
          :calendarList="calendarList"
        ></calendar>
      </div>
      <div class="calendar-content-bottom">
        <div class="tip-text">
          1、每天接待人数有限，请在预约后按时到校区领取;
        </div>
        <div class="tip-text">
          2、请勿选择周末及节假日，校区无人，容易空跑哦!
        </div>
      </div>
    </div>
    <div class="page-bottom">
      <div
        class="bottom-btn"
        :class="{ disabled: !reservationDate }"
        @click="submitOther"
      >
        确认预约时间
      </div>
    </div>
  </div>
  <reservationDialog
    :dialogType="4"
    :reservationDate="reservationDate"
    :takeAddress="takeAddress"
    v-if="showDialog"
    @closeDialog="closeDialog"
    @submitDialog="saveReceive"
  ></reservationDialog>
  <calendarSelectDate
    :dateData="choosDate"
    :index="choosIndex"
    @closeSelectLayer="closeSelectLayer"
    @chooseSelectTime="chooseSelectTime"
    v-show="showSelectStatus"
  ></calendarSelectDate>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import reservationStep from "./components/reservationStep.vue";
import reservationDialog from "./components/reservationDialog.vue";
import calendar from "./components/calendar.vue";
import calendarSelectDate from "./components/calendarSelectDate.vue";
import { calendarData, otherReceive } from "@/api/certificate";
import { initTime } from "@/utils/tools";
const routeQuery = new useRoute();
const router = useRouter();
const showDialog = ref(false);
//日历组件
const calendarObj = ref({});
//选择的时间
const choosDate = ref("");
//选中日历得索引
const choosIndex = ref(0);
const showSelectStatus = ref(false);
//领取地址
const takeAddress = ref("");
//预约日期
const reservationDate = ref("");
//上午1  下午2
const reservationTimeType = ref(1);
//日历数据
const calendarList = ref([]);
//证书ID
let id = routeQuery.query.id;
const getPageData = async () => {
  const res = await calendarData(id);
  if (res.code == 200) {
    takeAddress.value = res.data.takeAddress;
    calendarList.value = res.data.calendarList;
    setTimeout(() => {
      calendarObj.value.generateCalendar();
    }, 50);
  }
};
onMounted(() => {
  getPageData();
});
const showSelectLayer = (date) => {
  choosDate.value = date.data;
  choosIndex.value = date.index;
  showSelectStatus.value = true;
};
const closeSelectLayer = () => {
  showSelectStatus.value = false;
};
//选择上下午
const chooseSelectTime = (e) => {
  reservationTimeType.value = e.reservationTimeType;
  let timeIndex = e.index != undefined ? e.index : choosIndex.value;
  let timeData =
    e.index != undefined ? calendarList.value[e.index] : choosDate.value;
  choosDate.value = timeData;
  calendarObj.value.checkDate(timeIndex);
  showSelectStatus.value = false;
  let timeType = reservationTimeType.value == 1 ? "上午" : "下午";
  reservationDate.value = initTime(timeData.dateTimeStamp, 1);
  reservationDate.value = reservationDate.value + timeType;
};
//提交预约
const saveReceive = async () => {
  const res = await otherReceive({
    id: id,
    reservationTimestamp: choosDate.value.dateTimeStamp,
    reservationTimeType: reservationTimeType.value,
    takeReason: window.localStorage.getItem("takeReason"),
    takeAuthName: window.localStorage.getItem("takeAuthName"),
    takeAuthNum: window.localStorage.getItem("takeAuthNum"),
  });
  if (res.code == 200) {
    if (res.data.isContract) {
      router.push({
        name: "certificateInfo",
        query: {
          id: id,
        },
      });
    } else {
      router.push({
        name: "reservationEntrust",
        query: {
          id: id,
          type: 3,
        },
      });
    }
  }
};
//确认预约
const submitOther = () => {
  if (!reservationDate.value) {
    return;
  }
  showDialog.value = true;
};
//取消弹层
const closeDialog = () => {
  showDialog.value = false;
};
</script>
<style lang="scss" scoped>
.reservation-other-page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
}
.reservation-other-time {
  width: 100%;
  padding: 40px 30px 0 30px;
  box-sizing: border-box;
}
.page-content {
  width: 100%;
  border-radius: 24px;
  background: #fff;
  .calendar-title {
    padding: 30px 0 40px 30px;
    font-size: 28px;
    color: #222;
    font-weight: 600;
  }
  .calendar-address,
  .calendar-time {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    font-size: 24px;
    color: #222;
  }
  .calendar-address {
    margin-bottom: 30px;
  }
  .empty-time {
    color: #999;
  }
}
.calendar-content-bottom {
  width: 100%;
  padding: 30px;
  border-radius: 24px;
  font-size: 24px;
  margin-top: 40px;
  box-sizing: border-box;
  color: #999;
  .tip {
    color: #ff0000;
    margin-bottom: 24px;
  }
}
.page-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 152px;
  background: #fff;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  .bottom-btn {
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #1e4290;
    font-size: 28px;
    color: #fff;
    border-radius: 88px;
  }
  .disabled {
    background: #8997b3;
  }
}
</style>
