<template>
    <div class="flexBox derectionColumn h-center bg-specific">
        <img class="loadingImg" src="@/assets/quesheng.png" alt="">
        <div class="loadingText">{{dataText}}</div>
        <div class="reLogin" @click="logout" v-show="dataText.includes('无需缴费')">重新登录</div>
        <div class="reLogin" @click="toIndex" v-show="dataText.includes('暂无购买记录')">返回首页</div>
    </div>
   
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted
} from 'vue'
import { useRoute,useRouter } from "vue-router";
import { useStore } from 'vuex'
import { Dialog } from 'vant';
export default defineComponent({
    name: "",
    setup() {
        const store = useStore()
        const router = useRouter()
         const route = useRoute();
        const dataText = ref('暂无数据');
         // 生命周期
        onMounted(() => {
            if(route.query.data){
                dataText.value = route.query.data
            }
        })
        const logout = ()=>{
            store.dispatch('Logout')
        }
        const toIndex = ()=>{
            router.push({
                name:'Settlement',
                query:{
                    feePlanId:route.query.feePlanId
                }
            })
        }
        return {
            dataText,
            logout,
            toIndex
        };
    },
});
</script>
<style lang="scss" scoped>
    .loadingImg{
        width:201px;
    }
    .loadingText{
        margin-top:45px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
    }
    .reLogin{
        width: 620px;
        height: 80px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 80px;
        border-radius: 40px;
        margin:74px auto 0 auto;
        text-align: center;
        background: #157CFF;
    }
</style>