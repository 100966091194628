<template>
  <div class="full-screen">
    <div class="qr-content">
      <vue-qr
        v-if="loaded"
        :text="text"
        :size="200"
      />
      <div>请使用手机微信扫码查看报告~</div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onBeforeMount } from "vue";
import { useStore } from 'vuex';
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
const store = useStore();
const loaded = ref(false);
onBeforeMount(async () => {
    if (!store.state.settings.otherSetting) {
        await store.dispatch("getOtherSetting");
    }
    loaded.value = true;
})
const text = computed(() => {
    return store.state.settings.otherSetting ? store.state.settings.otherSetting.new_h5_address + "/student/study-report/2023" : "";
})
</script>
<style lang="scss" scoped>
.full-screen {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .qr-content {
        width: 200px;
        height: 200px;
        text-align: center;
        font-size: 12px;
    }
}
</style>