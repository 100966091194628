<template>
  <div :class="{ 'report-letter-page': true, active: active }">
    <PageSkeleton :animate="active">
      <ReportLeader
        text="一「研」为定"
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        亲爱的<span
          v-if="reportData.authName"
          class="text-bold"
        >{{ reportData.authName }}</span>同学
      </div>
      <div class="report-text slide-in slide-in-2">
        <span class="text-bold clear-left">{{ reportData.firstEnrollTime }}</span>我们第一次相遇
      </div>
      <div class="report-text slide-in slide-in-3">
        从此你的生活有了点小不同
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-4">
        这是我们认识的第<span class="text-bold">{{ reportData.totalDay }}</span>天
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-5">
        <span class="text-bold clear-left">{{ reportData.totalDay }}</span>天你用心学习
      </div>
      <div class="report-text slide-in slide-in-6">
        我帮你记录美好
      </div>
      <div class="report-text slide-in slide-in-7">
        平行世界遇见另一个自己
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import {inject} from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
    active: {
        type: Boolean,
        default: false
    }
});
const reportData = inject("reportData")
</script>
<style lang="scss" scoped>
.report-letter-page {
    background: url("~@/assets/student/studyReport/bg-letter.png");
    background-size: 100%;
}
</style>