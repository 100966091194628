//支付模块
import request from '@/utils/request'
import { AxiosPromise } from 'axios'

//支付宝
export function alipay(params): AxiosPromise {
    return request({
      url: `/hd-api/os/pay/alipay/start-pay`,
      method: 'POST',
      data:params
    })
  }

  // 微信支付
  export function wxpay(params): AxiosPromise {
    return request({
      url: `/hd-api/os/pay/wxpay/start-pay`,
      method: 'POST',
      data:params
    })
  }


  // 微信获取JSSDK配置
  export function getWechatSign(params): AxiosPromise {
    return request({
      url: `/hd-api/os/pay/wx/web/js-config`,
      method: 'POST',
      data:params
    })
  }

//获取openId
export function wxGetOpenid(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/wx/web/access_token`,
    method: 'POST',
    data:params
  })
}
//微信网页授权地址
export function wxGetRedict(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/wx/web/oauth2-authorize`,
    method: 'POST',
    data:params
  })
}

// 芝士分期

export function zswlPay(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/zswl/start-pay`,
    method: 'POST',
    data:params
  })
}

// 海尔分期
export function haierPay(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/haier/start-pay`,
    method: 'POST',
    data:params
  })
}

// 邮储在线
export function psbcPay(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/psbc/start-pay`,
    method: 'POST',
    data:params
  })
}

//民生银行
export function msPay(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/eduxxx-pay/start-pay`,
    method: 'POST',
    data:params
  })
}
// 海尔消金
export function haierxjPay(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/haiercash/start-pay`,
    method: 'POST',
    data:params
  })
}
// 海尔消金
export function xydPay(params): AxiosPromise {
  return request({
    url: `/hd-api/os/pay/xyd/start-pay`,
    method: 'POST',
    data:params
  })
}
