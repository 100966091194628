import request from '@/utils/request'
import { AxiosPromise } from 'axios'

const companyApi = {
  app_download: '/api/app/config/app_download',
}

export function appDownload(): AxiosPromise {
  return request({
    url: companyApi.app_download,
    method: 'get',
  })
}