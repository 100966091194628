
import Pay from '@/components/payComment/payIndex.vue'
import {
  defineComponent,
  ref,
  onMounted,
  onUpdated,
  onUnmounted,
  reactive,
  computed,
  watch
} from 'vue'
import { Dialog,CountDown, Toast  } from 'vant';
import CardItem from './components/feeCard.vue'
import { areaList } from '@vant/area-data';
import { createdOrder,getOrderDetail,cancelOrder } from '@/api/reportFee'
import { useRoute,useRouter } from "vue-router";
import {useStore} from 'vuex'
export default defineComponent({
  name: 'Settlement',
  props: {},
  computed: {},
  components: {
     CardItem,
     Pay,
     CountDown
  },
  setup() {
    const area = ref('')
    const router = useRouter()
    const route = useRoute();
    let planText = ref('')
    const store = useStore()
    store.commit('hidePaying')

    //表单数据
    const cancel = ref(false);
    let fee = reactive({
        info:{},
        orderInfo:{},
        countTime:'',
        returnUrl:location.href,
        params:{
            feePlanStudentId:'',
            feePlanId:"",
            channelTypeId:0
        }
    })
     const showPlan = ():void=>{
        Dialog.alert({
            title: '计划说明',
            message: planText.value,
            confirmButtonColor:'#157CFF',
            messageAlign:'left'
            }).then(() => {
            // on close
        });
    }
    
    // 生命周期
    onMounted(async () => {
       if(route.query.orderNumber){
           cancel.value = true
           let info = await getOrderDetail(route.query.orderNumber)
            info['feePlanName'] = info['name']
            info['itemVoList'] = info['orderItemList'].map(item=>{
               let val = item
               val.isChecked = true
               return val
           })
           fee.info = info
           if(fee.info['cancelOrderCountdown'] == 0){
               sessionStorage.removeItem('feeInfo')
               router.replace({
                   name:'Settlement',
                   query:{
                       feePlanId:info['feePlanId']
                   }
               })
           }
            
       }else{
           fee.info = JSON.parse(sessionStorage.getItem('feeInfo'))
       }
       fee.info['itemVoList'].forEach(item=>{
           item.feeType = fee.info['feeType']
       })
        fee.params.feePlanStudentId = fee.info['feePlanStudentId']
        fee.params.feePlanId = fee.info['feePlanId']
        planText.value = fee.info['remark']
       getAreaValue()
    })
    onUpdated(() => {
      console.log('updated vue3 typescript')
    })
    onUnmounted(() => {
      console.log('onUnmounted vue3 typescript')
    })

    const showPay = async ()=>{
        if(route.query['orderNumber'] || fee.info['orderNumber']){
            fee.returnUrl = window.location.origin +`/payComplate?feePlanStudentId=${fee.info['feePlanStudentId']}&feePlanId=${fee.info['feePlanId']}`
            fee.orderInfo['orderNumber'] = fee.info['orderNumber']
            sessionStorage.setItem("feeInfo",JSON.stringify(fee.info))
            if(computePrice.value==0){
                sessionStorage.removeItem('feeInfo')
                router.replace({
                    name:'FeeRecord',
                    query:{
                        feePlanStudentId:fee.info['feePlanStudentId'],
                        feePlanId:fee.info['feePlanId']
                    }
                })
            }else{
                store.commit('showPaying')
            }
            return 
        }
        let info = JSON.parse(JSON.stringify(fee.info))
        let feePlanUserFormList = info.feePlanUserFormVoList.map(item=>{
            return {
                fieldId:item.fieldId,
                fieldValue1:item.fieldValue1,
                fieldValue2:item.fieldValue2
            }
        })
        let itemIdList = info.itemVoList.filter(item=>item.isChecked).map(val=>val.id)
        let params = {
            feePlanUserFormList,
            itemIdList,
            feePlanStudentId:info.feePlanStudentId,
            orderPrice:computePrice.value
        } 
        if(!fee.orderInfo['orderNumber']){
            let data = await createdOrder(params)
            fee.orderInfo['orderNumber'] = data['orderNumber']
            fee.info['orderNumber'] = data['orderNumber']
        }
        sessionStorage.setItem("feeInfo",JSON.stringify(fee.info))
        fee.returnUrl = window.location.origin +`/payComplate?feePlanStudentId=${fee.info['feePlanStudentId']}&feePlanId=${fee.info['feePlanId']}`
        if(computePrice.value==0){
            sessionStorage.removeItem('feeInfo')
            router.replace({
                name:'FeeRecord',
                query:{
                    feePlanStudentId:fee.info['feePlanStudentId'],
                    feePlanId:fee.info['feePlanId']
                }
            })
        }else{
            store.commit('showPaying')
        }
       
    }
    const cancelPay = ():void=>{
       Dialog.confirm({
        title: '提示',
        message:'确认要取消订单吗？',
        confirmButtonColor:'#157CFF',
        })
        .then(async () => {
            await cancelOrder(fee.info['orderId'])
            Toast("取消成功")
            sessionStorage.removeItem('feeInfo')
            router.replace({
                name:'Settlement',
                query:{
                    feePlanId:fee.info['feePlanId']
                }
            })
        })
        .catch(() => {
            // on cancel
        }); 
    }
     const getAreaValue = ()=>{
        let areaCode = []
        fee.info['feePlanUserFormVoList'].forEach(element => {
            if(element.fieldId == 7){
                areaCode.push(element.fieldValue1)
                areaCode.push(element.fieldValue2) 
            }
        });
        console.log(areaCode[0])
        areaCode[0]&&areaCode[0] != 0?area.value=areaList.province_list[areaCode[0]]:area.value = ''
        areaCode[1] && areaCode[1] != 0?area.value +='/'+areaList.city_list[areaCode[1]]:area.value += ''
        
    }
    const getNumberTime = (timeData)=>{
        if(timeData.total==0){
            router.replace({
                name:'Settlement',
                query:{
                    feePlanId:fee.info['feePlanId']
                }
            })
            return false
        }
        let hours = timeData.hours
        hours = timeData.hours + (timeData.days * 24)
        return {
            hours:hours<10?'0'+hours:hours,
            minutes:timeData.minutes<10?'0'+timeData.minutes:timeData.minutes,
            seconds:timeData.seconds<10?'0'+timeData.seconds:timeData.seconds
        }
    }
    const computePrice = computed(()=>{
        if(!fee.info || !fee.info['itemVoList']){
            return 0
        }
        let objArr = fee.info['itemVoList'].filter(item=>{
            return item.isChecked
        })
        let price = objArr.reduce((total,v)=>{
            return total + parseFloat(v.itemPrice)
        },0)
        return price
    })
    const payStart = ()=>{
        sessionStorage.removeItem('feeInfo')
    }
    // 暴露给模板
    return {
      showPlan,
      areaList,
      showPay,
      cancel,
      cancelPay,
      fee,
      computePrice,
      area,
      getNumberTime,
      payStart
    }
  },
})
