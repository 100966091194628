<template>
    <div class="payComplate">
        <img class="loadingImg" src="@/assets/payComment/jiazai@2x.png" alt="">
        <div class="loadingText">加载中...</div>
    </div>
</template>
<script>
import { onMounted} from 'vue'
import { useRouter,useRoute} from "vue-router";
export default {
    name: "payComplate",
    setup() {
         // 生命周期
        onMounted(() => {
            const router = useRouter();
            const route = useRoute()
            console.log(route.query.feePlanStudentId)
            sessionStorage.removeItem('feeInfo')
           setTimeout(()=>{
               router.push({
                   name:'FeeRecord',
                    query:{
                    feePlanStudentId:route.query.feePlanStudentId,
                    feePlanId:route.query.feePlanId
                }
               })
           },3000)
        })
        return {
            
        };
    },
}
</script>
<style lang="scss" scoped>
    .payComplate{
        background: #F5F8FF;
        height: 100vh;
        overflow: hidden;
        text-align:center;
    }
    .loadingImg{
        width:198px;
        margin:350px auto 50px auto;
        animation: rotate 4s linear infinite;
    }
    .loadingText{
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 40px;
    }
    @keyframes rotate{
       to{
            transform: rotate(360deg);
        }
    }
</style>