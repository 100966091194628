const companysList = [
    {id:1,
     title:'银鹭食品集团',
     url:require('@/assets/landPage/company/banner-1.png'),
     bid:4},
    {id:2,
        title:'宜贝',
        url:require('@/assets/landPage/company/banner-2.png'),
        bid:4},
    {id:3,
        title:'厦门梦工坊影城',
        url:require('@/assets/landPage/company/banner-3.png'),
        bid:4},
    {id:4,
        title:'茶巢',
        url:require('@/assets/landPage/company/banner-4.png'),
        bid:4},
    {id:5,
        title:'绝味鸭脖',
        url:require('@/assets/landPage/company/banner-5.png'),
        bid:4},
    {id:6,
        title:'新盛洲',
        url:require('@/assets/landPage/company/banner-6.png'),
        bid:4},
    {id:7,
        title:'亚锝光电',
        url:require('@/assets/landPage/company/banner-7.png'),
        bid:4},
    {id:8,
        title:'鹭岛读书会',
        url:require('@/assets/landPage/company/banner-8.png'),
        bid:4},
    {id:9,
        title:'平安/北方事业部',
        url:require('@/assets/landPage/company/banner-9.png'),
        bid:4},
    {id:10,
        title:'坤厨',
        url:require('@/assets/landPage/company/banner-10.jpg'),
        bid:4},
    {id:11,
        title:'翱鹰',
        url:require('@/assets/landPage/company/banner-11.png'),
        bid:4},
    {id:12,
        title:'安溪亲友团',
        url:require('@/assets/landPage/company/banner-12.jpg'),
        bid:4},
    {id:13,
        title:'狄耐克',
        url:require('@/assets/landPage/company/banner-13.jpg'),
        bid:4},
    {id:14,
        title:'立林科技',
        url:require('@/assets/landPage/company/banner-14.jpg'),
        bid:4},
    {id:15,
        title:'闽南猪脚饭',
        url:require('@/assets/landPage/company/banner-15.jpg'),
        bid:4},
    {id:16,
        title:'日华',
        url:require('@/assets/landPage/company/banner-16.jpg'),
        bid:4},
    {id:17,
        title:'厦华',
        url:require('@/assets/landPage/company/banner-17.jpg'),
        bid:4},
    {id:18,
        title:'毛毛哥亲友团',
        url:require('@/assets/landPage/company/banner-18.jpg'),
        bid:4},
    {id:19,
        title:'一站式',
        url:require('@/assets/landPage/company/banner-19.jpg'),
        bid:4},

]

export default companysList;