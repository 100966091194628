
import { defineComponent, computed} from "vue";
import { useStore} from "vuex";
import {Popup, ConfigProvider} from 'vant';

export default defineComponent ({
  name: "gainClass",
  components:{
    Popup,
    ConfigProvider
  },
  setup(){
     const store = useStore();
     const currentClass= store.state.classPurchase.currentClass
     let gainClassShow = computed( ()=>{
        return store.state.classPurchase.gainClassShow
     })
    const themeVars = {
      popupRoundBorderRadius: '10px',
      popupCloseIconSize: '10px'
    };
    const handleClose = ():void =>{
       store.dispatch('handleSetGainClassShow' , false)
    }
    let handleDown = ():void =>{
      store.dispatch('handleBrowseLog', {activityId: store.state.classPurchase.activityId, type:3})
      const ua = window.navigator.userAgent.toLowerCase();
      const match = ua.match(/MicroMessenger/i);
      if (match && match.includes('micromessenger')) {
        store.dispatch('handleSetWxModelShow', true)
        return;
      }
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        const loadDateTime = new Date();
        location.href = "hadeedu://hade";
        window.setTimeout(()=> { //如果没有安装app,便会执行setTimeout跳转下载页
          const timeOutDateTime = new Date();
          if (Number(timeOutDateTime) - Number(loadDateTime) < 5000) {
            location.href = "https://apps.apple.com/cn/app/%E5%93%88%E5%BE%B7%E6%95%99%E8%82%B2/id1602171074"; //ios下载地址
          } else {
            window.close();
          }
        }, 3500);

      } else {
        const loadDateTime = new Date();
        location.href = "hadeedu://hade ";
        window.setTimeout(()=> {//如果没有安装app,便会执行setTimeout跳转下载页
          const timeOutDateTime = new Date();
          if (Number(timeOutDateTime) - Number(loadDateTime) < 5000) {
            location.href = "https://hd-img.hadecj.com/app/apk/1.2.2/hade_v1.2.2.apk";

          } else {
            window.close();
          }
        }, 500);
      }
    }
     return{
       gainClassShow,
       themeVars,
       currentClass,
       handleDown,
       handleClose
     }
  }
})
