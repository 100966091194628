<template>
  <div class="my-certificate-page">
    <template v-if="certificateList.length > 0">
      <template v-for="(item, index) in certificateList" :key="index">
        <div class="my-certificate-page-list" @click="linkToPage(item)">
          <div
            class="list-top-box"
            :class="{
              'list-top-box2': item.certificateType == 2,
              'list-top-box3': item.certificateType == 3,
            }"
          >
            <div class="top-title-left">
              <img
                src="@/assets/certificate/my-list-left-icon1.png"
                alt=""
                v-if="item.certificateType == 1"
              />
              <img
                src="@/assets/certificate/my-list-left-icon2.png"
                alt=""
                v-if="item.certificateType == 2"
              />
              <img
                src="@/assets/certificate/my-list-left-icon3.png"
                alt=""
                v-if="item.certificateType == 3"
              />
            </div>
            <div class="top-title-right">
              <img src="@/assets/certificate/my-list-right-icon.png" alt="" />
            </div>
            <div class="top-title">
              {{
                item.certificateType == 1
                  ? "毕业证书"
                  : item.certificateType == 2
                  ? "学位证书"
                  : "资格证书"
              }}<span>{{ item.issuanceDateStr }}</span>
            </div>
          </div>
          <div class="list-content">
            <div class="content-left">
              <div class="certificate-img" v-if="item.certificateType != 3">
                <template v-if="item.schoolBadgeUrl">
                  <van-image
                    class="certificate-img-src"
                    round
                    fit="cover"
                    :src="item.schoolBadgeUrl"
                  />
                </template>
                <template v-if="!item.schoolBadgeUrl">
                  <img
                    class="certificate-img-src"
                    src="@/assets/certificate/item-bg.png"
                    alt=""
                  />
                </template>
              </div>
              <div class="certificate-info">
                <template v-if="item.certificateType != 3">
                  <div class="certificate-title">{{ item.collegeName }}</div>
                  <div class="certificate-tip">
                    {{
                      item.majorName
                        ? item.majorName + " （" + item.layeredName + "）"
                        : "—"
                    }}
                  </div>
                </template>
                <template v-if="item.certificateType == 3">
                  <div class="certificate-title">{{ item.projectName }}</div>
                  <div class="certificate-tip">—</div>
                </template>
              </div>
            </div>
            <div
              class="content-right"
              :class="item.status != 7 ? 'content-right-type' : ''"
            >
              <img
                src="@/assets/certificate/my-list-true.png"
                alt=""
                v-if="item.status == 7"
              />
              <img src="@/assets/certificate/my-list-false.png" alt="" v-else />
              <span>{{
                item.status == 0
                  ? "待匹配"
                  : item.status == 1
                  ? "待发放"
                  : item.status == 2
                  ? "待领取"
                  : item.status == 3
                  ? "待签署"
                  : item.status == 4
                  ? "待发货"
                  : item.status == 5
                  ? "已发货"
                  : item.status == 6
                  ? "已预约"
                  : "已领取"
              }}</span>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-if="certificateList.length == 0 && !laoding">
      <div class="empty-data">
        <div>暂无数据</div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Image as VanImage } from "vant";
import { getCertificateList } from "@/api/certificate";
import { waitToken } from "@/utils/tools";
const certificateList = ref([]);
const router = useRouter();
const laoding = ref(true);
const getCertificatePage = async () => {
  const res = await getCertificateList({
    limit: 20,
    page: 1,
  });
  laoding.value = false;
  if (res.code == 200) {
    certificateList.value = res.data.list;
  }
};
onMounted(async () => {
  await waitToken(2000);
  getCertificatePage();
});
//跳转页面
const linkToPage = (item) => {
  router.push({
    name: "certificateInfo",
    query: {
      id: item.id,
    },
  });
  // if (item.status == 2) {
  //   router.push({
  //     name: "receiveType",
  //     query: {
  //       id: item.id,
  //     },
  //   });
  // } else if (item.status == 7) {
  //   router.push({
  //     name: "certificateInfo",
  //     query: {
  //       id: item.id,
  //     },
  //   });
  // }
};
</script>
<style lang="scss" scoped>
.my-certificate-page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding: 40px 30px 20px 30px;
  box-sizing: border-box;
}
.my-certificate-page-list {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 16px;
  background: #ffffff;
  overflow: hidden;
  margin-bottom: 40px;
  .list-top-box {
    position: relative;
    width: 100%;
    height: 98px;
    background-color: #1e4290;
    display: flex;
    justify-content: space-between;
    .top-title-left {
      width: 128px;
      height: 98px;
      margin-left: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .top-title-right {
      width: 196px;
      height: 88px;
      margin-right: 18px;
      margin-top: 14px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .top-title {
      position: absolute;
      left: 40px;
      top: 26px;
      font-size: 24px;
      color: #fff;
      box-sizing: border-box;
      span {
        font-size: 20px;
        padding-left: 10px;
      }
    }
  }
  .list-top-box2 {
    background: #f5a623;
  }
  .list-top-box3 {
    background: #4e3ab5;
  }
  .list-content {
    position: absolute;
    top: 84px;
    left: 0;
    width: 100%;
    height: 166px;
    background: #fff;
    border-radius: 16px;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content-left {
    display: flex;
    align-items: center;
    .certificate-img {
      width: 120px;
      height: 120px;
      overflow: hidden;
      margin-right: 24px;
      .certificate-img-src {
        width: 120px;
        height: 120px;
      }
    }
    .certificate-info {
      flex: 1;
      color: #222;
      .certificate-title {
        font-size: 32px;
        margin-bottom: 20px;
        font-weight: 600;
      }
      .certificate-tip {
        font-size: 24px;
      }
    }
  }
  .content-right {
    flex: none;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 24px;
    color: #1e4290;
    font-weight: 600;
    img {
      width: 32px;
      height: 32px;
      margin-right: 8px;
    }
  }
  .content-right-type {
    color: #d00102;
  }
}
.empty-data {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 356px;
  height: 322px;
  background: url("~@/assets/certificate/empty.png") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
  box-sizing: border-box;
  font-size: 32px;
  color: #222;
  font-weight: 600;
}
</style>
