<template>
  <template v-if="targetFileds.includes('classInfoList') && formState.classInfoList && formState.classInfoList.length">
    <div
      v-for="(item, index) in formState.classInfoList"
      :key="index"
    >
      <div class="colleage-header">
        <div>{{ item.className }}</div>
        <div class="layer">
          {{ item.layerName }}
        </div>
      </div>
      <Field
        readonly
        :label-width="'3.4rem'"
      >
        <template #label>
          <span>报读院校</span>
        </template>
        <template #input>
          {{ item.collegeName || "-" }}
        </template>
      </Field>
      <Field
        readonly 
        :label-width="'3.4rem'"
      >
        <template #label>
          <span>报读专业</span>
        </template>
        <template #input>
          {{ item.majorName || "-" }}
        </template>
      </Field>
    </div>
  </template>
</template>
<script setup>
import { inject } from "vue";
import { Field } from 'vant';
defineProps({
    targetFileds: {
        type: Array,
        default: () => {
            return []
        }
    }
})
const formState = inject("formState");
</script>
<style lang="scss" scoped>
.colleage-header {
    background-color: #F5F7FA;
    font-size: var(--van-cell-font-size);
    color: #333;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    .layer{
        color: #1E4290;
        flex-shrink: 0;
    }
}
</style>