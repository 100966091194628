import request from '@/utils/request'
import { AxiosPromise } from 'axios'
export function loadReportData(): AxiosPromise {
    return request({
        url: `/hd-api/os/h5/student/study/report`,
        method: 'get',
    })
}
export function loadReportData2023(): AxiosPromise {
    return request({
        url: `/hd-api/os/h5/student/study/report/2023`,
        method: 'get',
    })
}
export function loadPostGraduateReportData(): AxiosPromise {
    return request({
        url: `/hd-api/os/h5/student/study/report/postgraduate/2023`,
        method: 'get',
    })
}