<template>
    <div class="feeCard">
        <div class="cardItem" v-for="(item,index) in listData.list.filter(item=>item.isChecked)" :key="index" v-show="item.isChecked">
            <img class="yigoumai" v-if="item.purchaseStatus&&(item.purchaseStatus != 0)" :src="item.purchaseStatus==1?require('@/assets/Settlement/shuiyin-yigoumai.png'):require('@/assets/Settlement/shuiyin-yidengji.png')" alt="">
            <div class="indexLabel c-999 flexBox h-center c-333">{{index+1}}</div>
            <div class="subjectBox flexBox between">
                <div class="subjectInfo">
                    <div class="classTitle c-333">{{item.itemName}}</div>
                    <div class="time c-666"  v-show="item.feeType==1">考试时间 {{item.examTime?item.examTime:'无'}}</div>
                </div>
                <div class="price c-333">￥{{item.itemPrice / 100}}</div>
            </div>
        </div> 
    </div>
</template>
<script>
import { reactive,watch } from 'vue'
export default {
    name:'feeCard',
    props:{
        list:Array,
       
    },
    setup(props){
        const listData = reactive({
            list:[],
           
        })
        watch(
            () => props.list,
            (newVal, oldVal) => {
                console.log(newVal)
                listData.list = newVal
            }
        )
        
        return { listData };
    }
}
</script>
<style lang="scss" scoped>
    .cardItem{
        background: #FFFFFF;
        border-radius: 10px;    
        display: flex;  
        margin-bottom:30px;
        position: relative;
        .indexLabel{
            min-width: 40px;
            background: #EEEEEE;
            border-radius: 10px 0px 0px 10px;
            font-size: 28px;
        }
        .subjectBox{
            flex:1;
            padding:24px 20px;
            font-size:28px;
            color:#3333;
            .subjectInfo{
                .classTitle{
                    line-height: 40px; 
                    
                }
                .time{
                    font-size: 24px;
                    margin-top: 20px;
                    line-height: 33px;
                }
            }
        }
         .yigoumai{
            position:absolute;
            right:0;
            top:0;
            width:82px;
        }
    }
    .flexBox{
        display: flex;
        align-items: center;
    }
    .h-center{
        text-align: center;
        justify-content: center;
    }
    .between{
        justify-content: space-between;
    }
    .c-333{
        color:#333
    }
    .c-666{
        color: #666666;
    }
</style>