<template>
  <div class="company">
      <div>
        <VanImage
            :src="currentActivityInfo.url"
            fit="cover"
            lazy-load
        />

        <ConfigProvider :theme-vars="themeVars">
        <div class="company-form">
          <div class="from-list">
            <Field
                v-model="ranked"
                readonly
                is-link
                placeholder="请选择报读层次"
                @click="showPicker = true"
            />
          </div>
          <div class="from-list">
            <Field
                v-model="nickName"
                placeholder="请填写您的姓名"
                clearable
            />
          </div>
          <div class="from-list">
            <Field
                v-model="mobile"
                placeholder="请填写您的手机号"
                clearable
                type="number"
                maxlength="11"
            />
          </div>
          <div class="form-btn flex-xy-center" @click="handleSubmit">提交获取企业专项优惠</div>
        </div>
        </ConfigProvider>
<!--        选择报读层次-->
        <Popup v-model:show="showPicker" position="bottom">
          <Picker
              :columns="columns"
              @confirm="onConfirm"
              @cancel="showPicker = false"
          />
        </Popup>

        <VanImage
            :src="require('@/assets/landPage/company/hot.png')"
            fit="cover"
        />
        <VanImage
            :src="require('@/assets/landPage/company/bg-1.png')"
            fit="cover"
        />

        <div class="tab flex">
          <div class="tab-list flex-grow-1 flex-xy-center" @click="handleChangeTab(list.id)" :class="tabIndex == list.id?'tab-list-actived':''" v-for="list in tabList" :key="list.id">
             {{list.title}}
          </div>
        </div>
        <div v-for="list in tabList" :key="list.id">
          <div class="" v-show="tabIndex == list.id">
            <VanImage
                :src="list.url"
                fit="cover"
            />
          </div>
        </div>

        <div class="compay-footer">
           <div class="compay-footer-title">全国招生热线：4008-163-998</div>
           <div class="compay-footer-title">ALL Rights reserved copyright 哈德教育 版权所有</div>
           <div class="compay-footer-title">ICP备案：闽ICP备19022781号-1</div>
        </div>


      </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue"
import { Image as VanImage, Field,ConfigProvider, Popup, Picker, Toast, Dialog } from 'vant';
import {isPhoneNumber,handleDebounce} from "@/utils/tools"
import companysList from "@/utils/companys";
import {useRoute} from "vue-router";
import axios from 'axios'
import sensorsApi from "@/sensors/sensors";
export default {
  name: "CompanyLandPage",
  components: {
    VanImage,
    Field,
    ConfigProvider,
    Popup,
    Picker,
  },
  setup(){
    const router = useRoute();
    const currentActivity = router.query.id
    let currentActivityInfo = {}
    companysList.forEach(item=>{
       if(item.id == currentActivity){
         currentActivityInfo = item;
       }
    })
    console.log(currentActivityInfo);

    sensorsApi.sensors.setOnceProfile({ user_source: "渠道", first_view_product: "渠道", first_view_trench_id: "企业留资",first_view_activity_id: currentActivityInfo.title,first_visit_time: new Date(),});
    let formInfo = reactive({
      nickName:'',
      mobile:'',
      level:'',
      ranked:'',
      showPicker:false,
      tabIndex:1,
      tabList:[
        {title:'哈德文化',id:1,url:require('@/assets/landPage/company/cutule.png')},
        {title:'学员风采',id:2,url:require('@/assets/landPage/company/show.png')},
        {title:'哈德荣誉',id:3,url:require('@/assets/landPage/company/honor.png')}]
    })

    const columns = ['大专', '本科', '研究生', '技能证书'];
    const themeVars = {
      popupRoundBorderRadius: '10px',
      cellHorizontalPadding: '0px',
      fieldInputTextColor: '#333333',
      fieldPlaceholderTextColor: '#999999',
      popupCloseIconSize: '18px'
    };
    const handleSubmit= ()=>{
      if(!formInfo.ranked){
        Toast('请选择报读层次')
        return false;
      }
      if(!formInfo.nickName){
        Toast('请填写您的姓名')
        return false;
      }
      if(!formInfo.mobile){
        Toast('请填写您的电话')
        return false;
      }

      if (!isPhoneNumber(formInfo.mobile)) {
        Toast( `请填写正确的手机号码`);
        return false;
      }

      const data={
        name : formInfo.nickName,
        mycall: formInfo.mobile,
        cenci: formInfo.ranked,
        enews: 'AddFeedback',
        xueli:'aaaa',
        time:'aaaa',
        age:'aaaa',
        bid: currentActivityInfo.bid,
        tsxueli:'aaaa',
        title: currentActivityInfo.title,
        ecmsfrom:'aaaa',
        source:document.location.hostname,
      }
      axios.post('https://xz.hade.cn/e/enews/index.php', data).then(res=>{
        if( res.data.status == 200 ){
          Dialog.alert({
            title: '提交成功',
            message: '请保持手机畅通，24小时内将有专业学考规划师与您取得联系，为您定制学考规划！',
            confirmButtonColor: '#157CFF',
          }).then(() => {
            // on close
          });
        }else{
          Toast(res.data.msg)
        }
      })
    }

    const onConfirm = (value) => {
      formInfo.ranked = value
      formInfo.showPicker = false;
    };

    const handleChangeTab=(index)=>{
      if(formInfo.tabIndex == index) return;
      formInfo.tabIndex = index;
    }

    return {
      ...toRefs(formInfo),
      columns,
      themeVars,
      currentActivityInfo,

      handleSubmit,
      onConfirm,
      handleChangeTab
    }
  }
}
</script>

<style scoped lang="scss">
.company{
  background-color: rgba(50, 71, 92, 100);
  font-family: PingFangSC-regular;
    .company-form{
      padding:24px;
       .form-btn{
         width: 100%;
         height: 100px;
         border-radius: 8px;
         background-color: rgba(245, 200, 145, 100);
         color: rgba(50, 71, 92, 100);
         font-size: 32px;
         margin-bottom: 40px;
       }
       .from-list{
         width: 100%;
         margin-bottom: 30px;
         box-sizing: border-box;
         border-radius: 8px;
         padding:0 25px;
         background: #FFFFFF;
         :deep(.van-field__body){
           input:-webkit-autofill {
             transition: background-color 5000s ease-in-out 0s;
             box-shadow: 0 0 0px 1000px #ffffff inset !important;
           }
         }
       }
    }
    .van-image{
      display: inline;
    }
    .tab{
       .tab-list{
         height: 80px;
         color: rgba(245, 200, 145, 100);
         font-size: 32px;
         box-sizing: border-box;
         background-color: rgba(72, 83, 103, 100);
       }
      .tab-list-actived{
        color: rgba(50, 71, 92, 100);
        background-color: rgba(245, 200, 145, 100);
        border: 1px solid rgba(245, 200, 145, 100);
      }
    }
    .compay-footer{
      width: 100%;
      height: 210px;
      color: rgba(144, 161, 178, 60);
      font-size: 26px;
      text-align: center;
      box-sizing: border-box;
      padding-top: 15px;
         .compay-footer-title{
           margin-bottom: 14px;
         }
    }
}

</style>