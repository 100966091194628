<template>
  <div class="admission-ticket">
    <div
      ref="a4Ref"
      class="a4-pager"
    >
      <div class="outer-border">
        <div class="inner-border">
          <div class="examinfo-container">
            <div class="examinfo-header">
              <div class="examinfo-header-text">
                福建省高等教育自学考试
              </div>
              <div class="examinfo-header-text">
                实践准考证
              </div>
            </div>
          </div>
          <div class="student-info-wrapper d-flex">
            <div
              class="d-flex-start student-info-item"
              :class="[isLongName ? 'mr20':'mr40']"
            >
              姓名：<span :class="{'mini-text': isLongName}">{{ studentInfo.authName }}</span>
            </div>
            <div class="d-flex d-flex-1">
              <div
                class="student-info-item"
                :class="[isLongName ? 'mr20':'mr40']"
              >
                性别：{{ studentInfo.sex }}
              </div>
              <div
                class="student-info-item"
                :class="[isLongName ? 'mr20':'mr40']"
              >
                准考证号：{{ studentInfo.examinationNo }}
              </div>
            </div>
            <div class="d-flex-end student-info-item">
              证件号码：{{ studentInfo.authNum }}
            </div>
          </div>
          
          <div class="exam-info-grid">
            <div class="exam-info-grid-item section-title title">
              {{ examTitle }}
            </div>
            <div class="exam-info-grid-item section-title" />
            <div class="exam-info-grid-item section-title" />
            <div class="exam-info-grid-item section-title" />
            <div class="exam-info-grid-item section-title" />
            <div class="exam-info-grid-item section-title" />
            <div
              class="exam-info-grid-item title"
            >
              日期
            </div>
            <div
              class="exam-info-grid-item title"
            >
              时间
            </div>
            <div
              class="exam-info-grid-item title"
            >
              课程名称及代码
            </div>
            <div
              class="exam-info-grid-item title"
            >
              考试地点
            </div>
            <div
              class="exam-info-grid-item title"
            >
              考场
            </div>
            <div
              class="exam-info-grid-item title"
            >
              座位
            </div>
            <template
              v-for="(examInfo,index) in examDetailList"
              :key="index"
            >
              <div
                class="exam-info-grid-item"
                :class="{'last-row':index == 5}"
              >
                {{ examInfo.examDate || "- - -" }}
              </div>
              <div
                class="exam-info-grid-item"
                :class="{'last-row':index == 5}"
              >
                <div v-if="examInfo.startTime && examInfo.endTime">
                  {{ (examInfo.startTime + '-' + examInfo.endTime) }}
                </div>
                <div v-else>
                  - - -
                </div>
              </div>
              <div
                class="exam-info-grid-item px8"
                :class="{'last-row':index == 5,start:examInfo.examCourse && examInfo.examSubject}"
              >
                <div v-if="examInfo.examCourse && examInfo.examSubject">
                  <span
                    class="mr8"
                  >{{ examInfo.examCourse }}</span><span>{{ examInfo.examSubject || "- - -" }}</span>
                </div>
                <div v-else>
                  - - -
                </div>
              </div>
              <div
                class="exam-info-grid-item px8"
                :class="{'last-row':index == 5,start:!!examInfo.examPoint}"
              >
                {{ examInfo.examPoint || "- - -" }}
              </div>
              <div
                class="exam-info-grid-item"
                :class="{'last-row':index == 5}"
              >
                {{ examInfo.examRoom || "- - -" }}
              </div>
              <div
                class="exam-info-grid-item"
                :class="{'last-row':index == 5}"
              >
                {{ examInfo.examSeat || "- - -" }}
              </div>
            </template>
          </div>
          <div class="rules-wrapper">
            <div class="rules-header">
              考场规则
            </div>
            <p class="rule-item">
              1、考生应诚信应考，自觉服从考试工作人员管理，不得以任何理由妨碍考试工作人员履行职责，不得扰乱秩序。
            </p>
            <p class="rule-item">
              2、考生凭本人《准考证》、有效《居民身份证》、按规定的时间和地点参加考试。应主动接受监考员按规定进行的身份验证核查、安全检查和考试违禁物品检查。《居民身份证》丢失的，应持由当地派出所出具的身份证证明。
            </p>
            <p class="rule-item">
              3、考生入场时，携带好必需的考试用品，其他任何物品（如手机、电话手表等通讯工具）一律不得带入考场。
            </p>
            <p class="rule-item">
              4、考生入场后，对号入座，并将《准考证》和有效《居民身份证》，放在“座位号签”旁的桌面上以便核验。入座后，须在《考生签到表》上签字。领到答题卡和试卷后，在指定位置和规定时间内，准确、清楚地填涂本人姓名、准考证号、座位号等。凡因答题卡书写字迹不清等影响评卷结果的，责任由考生自负。
            </p>
            <p class="rule-item">
              5、开考前30分钟，考生可以进入考场，开考信号发出后，方可开始答题。交卷出考场时间不得早于当次科目考试结束前30分钟，交卷出场后，不得再进场续考，也不得在考场附近逗留或交谈。
            </p>
            <p class="rule-item">
              6、考生须在答题卡上与题号相对应的答题区域内答题，不得用规定以外的笔和纸答题，不得在答题卡上做任何标记。遇试卷、答题卡分发错误及试题字迹不清、重印、漏印或缺页等问题，可举手询问，涉及试题内容的疑问，不得向监考员询问。
            </p>
            <p class="rule-item">
              7、考生在考场内应保持安静，不得吸烟，不得喧哗，不得交头接耳、左顾右盼、打手势、做暗号，不得夹带、旁窥、抄袭或者有意让他人抄袭，不得传抄答案或交换试卷、答题卡、不得传递文具、物品等，不得将试题卷、答题卡带出考场。如身体出现异常情况，应立即报告。
            </p>
            <p class="rule-item">
              8、考试结束信号发出后，须立即停笔并停止答题，将自己的答题卡、试卷依序放在桌面上，根据监考员指令依次退出考场。考生离场后，根据考点工作人员的指引，有序离开考点。
            </p>
            <p class="rule-item">
              9、考试过程中，请考生自觉遵守考场纪律。如不遵守考场规则，扰乱考场秩序，不服从监考员考场管理等其他违规行为，将记入记录考试诚信档案。
            </p>
          </div>
        </div>
      </div>
      <div class="a4-footer">
        提示:考生所打印的准考证请妥善保管至本次所有科目考试结束。
      </div>
    </div>
    <img
      :src="pngData"
      class="preview-img"
    >
    <div
      class="btn-block"
    >
      <Button
        round
        block
        type="primary"
        class="btn"
        :disabled="!pngData"
        @click="onDownloadTicket"
      >
        下载准考证
      </Button>
    </div>
  </div>
</template>
<script setup>
import html2canvas from "html2canvas";
import { computed, nextTick, onMounted,reactive,ref } from "vue";
import { useRoute } from "vue-router";
import {getAdmissionTicketInfo} from "@/api/admissionTicket"
import { Button } from 'vant';
import commonJs from '../../utils/common'
import {waitToken} from "@/utils/tools"
const route = useRoute();
const a4Ref = ref();
const pngData = ref('')
const examTitle = ref("");
const examDetailList = ref([]);
const studentInfo = reactive({
  authName:"",
  sex:3,
  examinationNo:"",
  authNum:""
})
const isLongName = computed(()=>{
  return studentInfo.authName && studentInfo.authName.length > 10
})
const canvasToImg = () => {
    html2canvas(a4Ref.value,{
        allowTaint: true,
        taintTest: false,
        useCORS: true,
    }).then(canvas=>{
        pngData.value = canvas.toDataURL("image/png");
    })
};
//触发原生下载
const onDownloadTicket = ()=>{
  let typeJudge = commonJs.judgeUserAgent();
  if(typeJudge.isiOS){
    window.webkit.messageHandlers.jsImageSave.postMessage(pngData.value);// jsImageSave 交互的方法名
  }else{
    window.hade.jsImageSave(pngData.value);
  }
}
onMounted(async ()=>{
    await waitToken(2000);
    const {data:{title,authName,authNum,examinationNo,sex,examStudentList}} = await getAdmissionTicketInfo(route.query.id);
    examTitle.value = title;
    studentInfo.authName = authName;
    studentInfo.authNum = authNum;
    studentInfo.examinationNo = examinationNo;
    studentInfo.sex = sex === 0 ? '男' : sex === 1 ? '女' : '保密';
    const maxExamLength = 6;
    const safeExamList = examStudentList.slice(0,maxExamLength);
    examDetailList.value = safeExamList.concat([...Array(maxExamLength-safeExamList.length)].map(()=>{return {}}));
    nextTick(()=>{
      canvasToImg();
    },300)
});
</script>
<style lang="scss" scoped>
/**使用PX代替px避免被pxtorem转换 */
.admission-ticket{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
    .preview-img{
        width: 100vw;
    }
    .a4-pager {
        width: 595PX;
        height: 842PX;
        background-color: #fff;
        padding:26PX;
        box-sizing: border-box;
        position: absolute;
        color: #333;
        top: -1000PX;
        left:-1000PX;
        .outer-border{
            width: 100%;
            height: 740PX;
            border: 1PX solid #000;
            padding: 4PX;
            box-sizing: border-box;
            .inner-border{
                width: 100%;
                height: 100%;
                border: 1PX solid #999;
                padding-left:12PX;
                padding-right: 12PX;
                box-sizing: border-box;
                .examinfo-container{
                    padding-top: 17PX;
                    
                    .examinfo-header{
                        &-text{
                            text-align: center;
                            font-size:24PX;
                            line-height: 34PX;
                            letter-spacing: 8PX;
                            font-weight: bold;
                            margin-top:9PX;
                            
                        }
                    }
                }  
            }
            .student-info-wrapper{
                padding: 18PX 3PX 13PX 3PX;
                .student-info-item{
                    font-size: 10PX;
                    line-height: 14PX;
                    font-weight: 500;
                    flex-shrink: 0;
                    .mini-text{
                      font-size: 8PX !important;
                    }
                }
            }
            .rules-wrapper{
                margin-top:15PX;
                .rules-header{
                    text-align: center;
                    font-size: 13PX;
                    letter-spacing: 8PX;
                    margin-bottom: 12PX;
                }
                .rule-item{
                    margin: 0;
                    font-size: 8PX;
                    text-indent: 16PX;
                    line-height: 14PX;
                    text-align: justify;
                }
            }
        }
        .a4-footer{
            margin-top: 11PX;
            width: 100%;
            text-align: right;
            font-size: 8PX;
            line-height: 11PX;
            font-weight: 500;
            color: #333;
        }  
    
    }
}
.d-flex{
    display: flex;
    &.align-center{
        align-items: center;
    }
    &-1{
        flex:1;
    }
}
.mr20{
    margin-right: 20PX;
}
.mr40{
    margin-right: 40PX;
}
.mr8{
  margin-right: 8PX;
}
.exam-info-grid{
  display: grid;
  grid-template-columns: 54PX 60PX 160PX 158PX 37PX 40PX;
  grid-template-rows: 26PX 28PX 33PX 33PX 33PX 33PX 33PX 33PX;
  grid-template-areas: 'sec sec sec sec sec sec';
  font-size: 8PX;
  &-item{
    border-left:1PX solid #979797;
    border-top:1PX solid #979797;
    display: flex;
    align-items: center;
    justify-content: center;
    &:nth-child(6n){
      border-right:1PX solid #979797;
    }
    &.last-row{
      border-bottom:1PX solid #979797; 
    }
    &.px8{
      padding:0 8PX;
    }
    &.start{
      justify-content: flex-start !important;
    }
    &.title{
      font-size: 10PX;
      font-weight: 500;
    }
    &.section-title{
      grid-area: sec;
    }
  }
}
.btn-block {
    display: flex;
    padding: 12px 50px 68px 50px;
    justify-content: center;
    position: absolute;
    box-sizing: border-box;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;

    .btn {
      color: #fff;
      background-color: #1E4290;
      width: 650px;
      height: 88px;
      line-height: 88px;
      font-size: 32px;
      border-radius: 42px;
      border-color: #fff;
      margin: 20px auto 0 auto;
    }
  }
</style>