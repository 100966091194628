<template>
 <div class="downPage">
   <div class="downPage_bg" :style="{backgroundImage: 'url('+ backgroundImage +')'} ">
       <div class="downPage_title flex-x-center">当前课程需要前往app观看哦～</div>
       <div class="downPage_url flex-x-center">
         <van-image class="downPage_logo" :src="appConfigInfo.app_logo"></van-image>
       </div>
   </div>
   <div class="flex-x-center downPage_btn_wrap">
     <div class="downPage_btn flex-xy-center"  @click="handleDown">下载/打开app</div>
   </div>
   <div class="flex-x-center downPage_tip">{{appConfigInfo.app_slogan}}</div>

   <!--    微信蒙层提示-->
   <wxModelShowModel></wxModelShowModel>

 </div>
</template>

<script>
import { Image as VanImage } from 'vant';
import {reactive, toRefs, onUnmounted, onMounted} from "vue";
import wxModelShowModel from '@/views/landPage/classPurchase/components/wxModel.vue'
import {useStore} from "vuex";
import {app_download, appDownload} from '@/api/configInfo'
export default {
  name: "StudentDownPage",
  components: {
    VanImage,
    wxModelShowModel
  },
  setup(){
     let store = useStore()
     let data=reactive({
       backgroundImage:require('@/assets/student/downPage/bg.png'),
       logoImg:require('@/assets/student/downPage/logo.png'),
       appConfigInfo:{}
     })
    let handleDown = () =>{
      const ua = window.navigator.userAgent.toLowerCase();
      const match = ua.match(/MicroMessenger/i);
      if ((match && match.includes('micromessenger'))|| ua.indexOf('dingtalk')>-1 ) {
        store.dispatch('handleSetWxModelShow', true)
        return;
      }
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        const loadDateTime = new Date();
        location.href = "hadeedu://hade";
        window.setTimeout(()=> { //如果没有安装app,便会执行setTimeout跳转下载页
          const timeOutDateTime = new Date();
          if (Number(timeOutDateTime) - Number(loadDateTime) < 5000) {
            location.href = data.appConfigInfo.ios_url; //ios下载地址
          } else {
            window.close();
          }
        }, 2500);

      } else {
        const loadDateTime = new Date();
        location.href = "hadeedu://hade";
        window.setTimeout(()=> {//如果没有安装app,便会执行setTimeout跳转下载页
          const timeOutDateTime = new Date();
          if (Number(timeOutDateTime) - Number(loadDateTime) < 5000) {
            location.href = data.appConfigInfo.android_url;
          } else {
            window.close();
          }
        }, 2500);
      }
    }
    let handleConfigInfo = () => {
      appDownload().then(res=>{
         data.appConfigInfo = res.data.app_download;
      })
    }
    onMounted(()=>{
       handleConfigInfo();
    })
    onUnmounted(()=>{
      store.dispatch('handleSetWxModelShow', false)
    })
     return{
       ...toRefs(data),
       handleDown
     }
  }
}
</script>

<style scoped lang="scss">
  .downPage_bg{
    width: 100%;
    height: 673px;
    background-size: 100% 100%;
    box-sizing: border-box;
 }
  .downPage_title{
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 56px;
    text-shadow: 0px 4px 4px rgba(0,0,0,0.5000);
    padding-top: 310px;
  }
  .downPage_url{
    width: 100%;
    margin-top: 170px;
  }
  .downPage_logo{
    width: 266px;
    height: 266px;
 }
  .downPage_btn_wrap{
    padding:0 64px;
  }
  .downPage_btn{
    width: 100%;
    height: 80px;
    background: #157CFF;
    border-radius: 40px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 400px;
  }
  .downPage_tip{
    font-size: 24px;
    font-weight: 400;
    color: #666666;
    margin-top: 30px;
  }

</style>