import request from '@/utils/request'
import { AxiosPromise } from 'axios'

// 访问落地页事件
export function activityVisit(params): AxiosPromise {
    return request({
        url: `/hd-api/os/h5/activity/referral/visit`,
        method: 'post',
        data:params
    })
}

//提交表单
export function submitVisit(params): AxiosPromise {
    return request({
        url: `/hd-api/os/h5/activity/referral/submit`,
        method: 'post',
        data:params
    })
}

// 生成分享好友地址（海报列表）
export function getPosterList(params): AxiosPromise {
    return request({
        url: `/hd-api/os/h5/activity/referral/shared`,
        method: 'put',
        data:params
    })
}

//分享操作埋点
export function activitySharing(params): AxiosPromise {
    return request({
        url: `/hd-api/os/h5/activity/referral/sharing`,
        method: 'post',
        data:params
    })
}
