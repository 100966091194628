
import {
  defineComponent,
  ref,
  onMounted,
//   onUpdated,
//   onUnmounted,
  reactive,
  computed
} from 'vue'
import { useRouter,useRoute } from "vue-router";
import { useStore } from 'vuex'
import { unpaidOrder,getclassList,feePlan,getSchoolList ,examTimeConflict,getSchoolExamPlan } from '@/api/reportFee'
import { Picker ,Dialog, Form, Field, CellGroup,Area,Popup,Checkbox, CheckboxGroup,Toast } from 'vant';
import { areaList } from '@vant/area-data';
import commonJs from '../../utils/common'
export default defineComponent({
  name: 'Settlement',
  components: {
    Form,
    Field,
    CellGroup,
    Area,
    Popup,
    Checkbox,
    CheckboxGroup,
    Picker,
  },
  props: {},
  setup() {
    const pattern = /\d{6}/;
    const store = useStore()
    let classList:any = ref([])
    let schoolList:any = ref([])
    const schoolExamPlanAreaIds = ref<Array<number>>([]);
    let planText = ref('')
    const router = useRouter()
    const route = useRoute()
    let currentClass = reactive({
      class:{},
      info:{},
      customFieldName:{
        text: 'areaName',
        value: 'id',
      },
      subjectFieldName:{
        text: 'className',
        value: 'classId',
      }
    })
    const showPlan = ():void=>{
      Dialog.alert({
        title: '计划说明',
        message:planText.value,
        confirmButtonColor:'#157CFF',
        messageAlign:'left'
      }).then(() => {
        // on close
      });
    }
    const feePlanId = route.query.feePlanId || 24
    //表单数据
    const school = ref('')
    const area = ref('')
    const showArea =  ref(false)
    const showSchool = ref(false)
    const showSubject = ref(false)
    let submitText = ref('去结算')
    let checked = ref([]);
    const fal = ref(true)

    // 生命周期
    onMounted(async () => {
      let res = await unpaidOrder({feePlanId:feePlanId})
      if(!res['unpaid']){
        //没有未支付的订单
        init()
      }else{
        //  跳转到确认支付页面
        router.push({
          name:'PaySure',
          query:{
            // orderId:res['orderId'],
            type:'haspay',
            orderNumber:res['orderNumber'],

          }
        })
      }
    })
    // onUpdated(() => {
    //   console.log('updated vue3 typescript')
    // })
    // onUnmounted(() => {
    //   console.log('onUnmounted vue3 typescript')
    // })
    const init = async()=>{
      schoolList.value = await getSchoolList()
      classList.value = await getclassList(feePlanId)

      if(!classList.value[0]){
        router.push({
          name:'NoData',
          query:{
            data:"无需缴费，请联系班主任~",
            feePlanId:feePlanId
          }
        })
        return
      }
      console.log(classList)
      currentClass.class = JSON.parse(JSON.stringify(classList.value[0]))
      //如果有缓存中的数据，获取数据回填，没有就取后端接口
      computedOldData(true,false)
    }
    const computedOldData = async (flag=false,isChangeClass=false)=>{
      let oldInfo = JSON.parse(sessionStorage.getItem('feeInfo'));
      if(oldInfo&&(oldInfo.feePlanId == feePlanId)&&oldInfo.uid == store.state.user.info.id && !isChangeClass){
        //缓存
        currentClass.class['classId'] = oldInfo.classId
        currentClass.class['className']=oldInfo.className
        currentClass.info = oldInfo
        checked.value = oldInfo.itemVoList.filter(item=>(item.isChecked && item.purchaseStatus != 1)).map(val=>val.id)
        if(currentClass.info["schoolExamPlanId"]){
          const fetchedExamPlan = await getSchoolExamPlan(currentClass.info["schoolExamPlanId"]);
          if(fetchedExamPlan && fetchedExamPlan["areaIds"]){
            schoolExamPlanAreaIds.value = fetchedExamPlan["areaIds"]
          }else{
            schoolExamPlanAreaIds.value = []
          }
        }
      }else{
        feePlan({feePlanId:feePlanId,classId:currentClass.class['classId']}).then(async info=>{
          currentClass.info = info
          planText.value = currentClass.info['remark']
          // if(computedSubmit().flag){
          //     console.log("11111")
          //     console.log(info['itemVoList'])
          //     info['itemVoList'].forEach(item=>{
          //         if(item.isRecommend == 1){
          //             item.isChecked = true
          //         }
          //     })
          //     checked.value = info['itemVoList'].filter(item=>( item.isRecommend ==1)).map(val=>val.id)
          //     console.log(checked.value)

          // }


          getAreaValue()

          if(planText.value && flag && store.state.user.feePlanId != feePlanId){
            showPlan()
            store.commit('SET_SHOWPLAN',feePlanId)
          }

          computedSubmit()
          if(!computedSubmit().flag){
            Toast(computedSubmit().msg)
          }
          if(currentClass.info["schoolExamPlanId"]){
            const fetchedExamPlan = await getSchoolExamPlan(currentClass.info["schoolExamPlanId"]);
            if(fetchedExamPlan && fetchedExamPlan["areaIds"]){
              schoolExamPlanAreaIds.value = fetchedExamPlan["areaIds"]
            }else{
              schoolExamPlanAreaIds.value = []
            }
          }
        }).catch(()=>{
          setTimeout(()=>{
            router.push({
              name:'NoData',
              query:{
                data:"无需缴费，请联系班主任~",
                feePlanId:feePlanId
              }
            })
          },2500)
        })
      }

    }
    const onConfirm = (areaValues) => {
      showArea.value = false;
      area.value = areaValues
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
      currentClass.info['feePlanUserFormVoList'].forEach(element => {
        if(element.fieldId == 7){
          element.fieldValue1 = areaValues[0].code
          element.fieldValue2 = areaValues[1].code
        }
      });
    };
    const onSchoolConfirm = (value) =>{
      school.value = value.areaName;
      currentClass.info['feePlanUserFormVoList'].forEach(element => {
        if(element.fieldId == 5){
          element.fieldValue1 = value.areaName
        }
      });
      showSchool.value = false;
    }
    const onSubjectConfirm = (value)=>{
      showSubject.value = false

      if(currentClass.class['classId'] != value.classId){
        currentClass.class = value
        checked.value=[]
        computedOldData(false,true)
      }

    }
    const computePrice = computed(()=>{
      if(!currentClass.info || !currentClass.info['itemVoList']){
        return 0
      }
      let objArr = currentClass.info['itemVoList'].filter(item=>{
        return checked.value.includes(item.id)
      })
      let price = objArr.reduce((total,v)=>{
        return total + parseFloat(v.itemPrice)
      },0)
      return price
    })
    const toRecord = ()=>{
      //跳转到缴费记录页面
      router.push({
        name:'FeeRecord',
        query:{
          feePlanStudentId:currentClass.info['feePlanStudentId'],
          feePlanId:feePlanId
        }
      })
    }
    const submit = async ()=>{

      if(!computedSubmit().flag){
        Toast(computedSubmit().msg)
        return
      }
      if(!checked.value.length){
        Toast("请勾选至少一项费用")
        return
      }
      if(checked.value.length>16){
        Toast("费用数量不可超过16项")
        return
      }

      let info = JSON.parse(JSON.stringify(currentClass.info))

      //判断必填验证
      info.feePlanUserFormVoList.forEach(item=>{
        let flag = true
        if(item.fieldRequired==1){
          if(item.fieldId==7){
            flag =  item.fieldValue1 && item.fieldValue2
          }else{
            flag = !!item.fieldValue1
          }
          if(!flag){
            Toast(`${item.fieldName}为必填项`)
            throw new Error()
          }
        }
        if(item.fieldShow==1 && item.fieldValue1){
          if(item.fieldId==2){
            if(!commonJs.validatePhone.test(item.fieldValue1)){
              Toast(`请输入正确的手机格式`)
              throw new Error()
            }
          }
          if(item.fieldId==3){
            console.log(commonJs.validateCertificate(item.fieldValue1))
            if(!commonJs.validateCertificate(item.fieldValue1)){
              Toast(`请输入正确的身份证号`)
              throw new Error()
            }
          }
          if(item.fieldId==4 || item.fieldId==6){
            //   判断正整数
            if(!commonJs.validityNumber.test(item.fieldValue1)){
              Toast(`请输入正确的${item.fieldName}`)
              // validityNumber
              throw new Error()
            }
          }
        }
      })

      info.className = currentClass.class['className']
      // info.itemVoList = info.itemVoList.filter(item=>{
      //     return checked.value.includes(item.id)
      // })
      info.itemVoList.forEach(item=>{
        if(checked.value.includes(item.id) && item.purchaseStatus != 1){
          item['isChecked'] = true
        }else{
          item['isChecked'] = false
        }
      })
      if(info['feeType']==1){
        await judgeTime(info.itemVoList)
        console.log(fal.value)
        if(!fal.value){
          return
        }
      }
      info.uid = store.state.user.info.id
      sessionStorage.setItem("feeInfo",JSON.stringify(info))
      router.push({
        name:'PaySure',
        query:{
          // feePlanStudentId:currentClass.info['feePlanStudentId']
        }
      })
    }
    const getAreaValue = ()=>{
      let areaCode = []
      currentClass.info['feePlanUserFormVoList'].forEach(element => {
        if(element.fieldId == 7){
          areaCode.push(element.fieldValue1)
          areaCode.push(element.fieldValue2)
        }
      });
      areaCode[0] && areaCode[0] != 0?area.value=areaList.province_list[areaCode[0]]:area.value=''
      areaCode[1] && areaCode[1] != 0?area.value +='/'+areaList.city_list[areaCode[1]]:area.value +=''

    }

    const logout = ()=>{
      Dialog.confirm({
        title: '提示',
        message:'确定要退出本次报考计划吗？',
        confirmButtonColor:'#157CFF',
      })
          .then(async () => {
            store.dispatch('Logout')
          })
          .catch(() => {
            // on cancel
          });

    }
    const computedSubmit = ()=>{
      let startTime = currentClass.info['planStartTime']?commonJs.changeIosTime(currentClass.info['planStartTime']):0
      let endTime = currentClass.info['planEndTime']?commonJs.changeIosTime(currentClass.info['planEndTime']):0
      let nowTime = Date.now()
      let msg = '',flag=true

      if(startTime>nowTime){
        submitText.value = '未开始'
        msg='计划未开始'
        flag=false
      }else if(nowTime>endTime){
        submitText.value = '已结束'
        msg='计划已结束'
        flag=false
      }else{
        submitText.value = '去结算'
        msg=''
        flag=true
      }
      return {
        flag,
        msg
      }
    }

    // 判断时间段是否有重叠
    const judgeTime = async (list)=>{
      fal.value = true
      let infoData = list.filter(item=>{
        return item.isChecked && item.examTime
      })
      if(!infoData.length){
        fal.value = true
      }else{
        let itemIdList = infoData.map(item=>{
          let val = item
          return val.id
        })
        let data = await examTimeConflict(
            {
              "feePlanStudentId": currentClass.info['feePlanStudentId'],
              "itemIdList": itemIdList
            }
        )
        if(data['conflict']==1){
          Toast(data['message'])
          fal.value = false
        }
      }
      // let i,j
      // for(i = 0; i < infoData.length; i++){
      //     for(j = i + 1; j < infoData.length; j++){
      //         let datei = infoData[i].examTime.split(' ')[0]
      //         let datej = infoData[j].examTime.split(' ')[0]

      //         if(datei == datej){
      //             if(timePart(datei,infoData[i].examTime) == timePart(datej,infoData[j].examTime)){
      //                 Toast(`${infoData[i].itemName}与${infoData[j].itemName}考试时间冲突`)
      //                 throw new Error()
      //             }
      //         }else{
      //             j = ++i;
      //         }
      //     }
      // }
    }
    const timePart = (day,data)=>{
      let dayTime = day+' 12:00:00'
      let dayDate = commonJs.changeIosTime(dayTime)
      let dataDate = commonJs.changeIosTime(data)
      if(dataDate>dayDate){
        return 'after'
      }else{
        return 'moning'
      }
    }
    const schoolExamAreaOptions = computed(()=>{
      if(schoolExamPlanAreaIds.value && schoolExamPlanAreaIds.value.length){
        return schoolList.value.filter(s=>schoolExamPlanAreaIds.value.includes(s.id));
      }
      return schoolList.value;
    });
    // 暴露给模板
    return {
      showPlan,
      pattern,
      areaList,
      school,
      area,
      showArea,
      onConfirm,
      checked,
      classList,
      currentClass,
      schoolList,
      showSchool,
      onSchoolConfirm,
      submit,
      computePrice,
      getAreaValue,
      showSubject,
      onSubjectConfirm,
      toRecord,
      submitText,
      computedSubmit,
      judgeTime,
      timePart,
      logout,
      schoolExamAreaOptions
    }
  },
})
