<template>
  <div :class="{ 'report-practice-page': true, active: active }">
    <PageSkeleton
      :animate="active"
      clear-left
    >
      <ReportLeader
        text="一战成硕"
        right
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        <span v-if="reportData.submitReportCount <= 0">你还未在APP上作答过练习</span>
        <span v-else>你完成了</span>
      </div>
      <div
        v-if="reportData.submitReportCount > 0"
        class="report-text slide-in slide-in-2"
      >
        <span><span class="text-bold">{{ reportData.submitReportCount }}</span>次课后练习</span>
      </div>
      <div class="report-text" />
      <div v-if="reportData.submitReportCount <= 0">
        <div class="report-text slide-in slide-in-2">
          留白也是一种表达方式
        </div>
        <div class="report-text slide-in slide-in-3">
          但温故而知新
        </div>
        <div class="report-text slide-in slide-in-4">
          期待与你发生更多碰撞
        </div>
      </div>
      <div v-if="reportData.submitReportCount > 0 && reportData.submitReportCount <= 5">
        <div class="report-text slide-in slide-in-3">
          每一次练习都是向上的印记
        </div>
        <div class="report-text slide-in slide-in-4">
          所有淡定自若的背后
        </div>
        <div class="report-text slide-in slide-in-5">
          都是熟能生巧
        </div>
      </div>
      <div v-else-if="reportData.submitReportCount > 5 && reportData.submitReportCount <= 15">
        <div class="report-text slide-in slide-in-3">
          点滴知识被你轻轻呵护
        </div>
        <div class="report-text slide-in slide-in-4">
          你在暗自努力惊艳所有人
        </div>
        <div class="report-text slide-in slide-in-5">
          你在努力奔跑追上那个被寄予厚望的自己
        </div>
      </div>
      <div v-else-if="reportData.submitReportCount > 15 && reportData.submitReportCount <= 30">
        <div class="report-text slide-in slide-in-3">
          很多人称赞你的耀眼
        </div>
        <div class="report-text slide-in slide-in-4">
          却不如你在背后默默练习了
        </div>
        <div class="report-text slide-in slide-in-5">
          一遍又一遍
        </div>
      </div>
      <div v-else-if="reportData.submitReportCount > 30">
        <div class="report-text slide-in slide-in-3">
          勤奋是你的勋章
        </div>
        <div class="report-text slide-in slide-in-4">
          你是那个志在山顶的人
        </div>
        <div class="report-text slide-in slide-in-5">
          你是当之无愧的学习之王
        </div>
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import { inject } from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
  active: {
    type: Boolean,
    default: false
  }
});
const reportData = inject("reportData")
</script>
<style lang="scss" scoped>
.report-practice-page {
  background: url("~@/assets/student/studyReport/postgraduate/2023/bg-practice.png");
  background-size: 100%;
  text-align: right;
}
</style>