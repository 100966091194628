import request from '@/utils/request'
import { AxiosPromise } from 'axios'

  //获取学员在班的班级
  export function getStuClass(): AxiosPromise {
    return request({
      url: `/hd-api/os/schoolClassStudent/inClass/list`,
      method: 'get',
    })
  }

//班级课件-获取指定班级的课件科目列表
export function getSubject(classId): AxiosPromise {
    return request({
      url: `/hd-api/os/h5/schoolclass/ware/${classId}/subject`,
      method: 'post',
    })
  }

//班级课件-获取指定班级科目的课件列表
export function getWare(classId,subjectId): AxiosPromise {
    return request({
      url: `/hd-api/os/h5/schoolclass/ware/${classId}/${subjectId}/list`,
      method: 'post',
    })
  }
