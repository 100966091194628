<template>
    <div class="pay" v-if="showPay">
        <Popup
            v-model:show="showPay"
            :close-on-click-overlay="false"
            round
            closeable
            position="bottom"
            close-icon-position="top-left"
            @click-close-icon="closeHandle"
            @click-overlay="closeHandle"
        >
            <div class="payHeader">请选择</div>
            <div class="payContent">
              <!-- <CellGroup> -->
               
                  <Cell title="微信支付" is-link @click="toPay(1)" v-if="showType.includes(1)">
                    <template #title>
                        <div class="custom">
                          <img class="custom-img" src="@/assets/payComment/weixinzhifu.png" alt="">
                          <span class="custom-title">微信支付</span>
                        </div>
                      </template>
                  </Cell>
                  <Cell title="支付宝支付" is-link  @click="toPay(2)" v-if="showType.includes(2)">
                    <template #title>
                        <div class="custom">
                          <img class="custom-img" src="@/assets/payComment/zhifubao.png" alt="">
                          <span class="custom-title">支付宝支付</span>
                        </div>
                      </template>
                  </Cell>
              <!-- </CellGroup> -->
            </div>
        </Popup>
    </div>
      <!-- <div v-htm="state.html"></div> -->
</template>
<script>
import { reactive ,toRefs,computed,onMounted,onUnmounted,watch} from 'vue'
import {  Cell, CellGroup,Popup,Toast } from 'vant';
import {alipay,wxpay,getWechatSign,wxGetRedict } from  '@/api/pay'
import {useStore} from 'vuex'
import {useRoute,useRouter } from 'vue-router'
import {wxUtil} from '@/utils/wx_util.ts'
import commonJs from '../../utils/common'
import _ from "lodash"

export default {
    name: "Pay",
    props:{ 
        show:Boolean,
        orderNumber:String,
        params:Object,
        returnUrl:{
          type:String,
          default:location.href
        },
        showType:{
          type:Array,
          default:()=>{
          //1是微信，2是支付宝
            if(wxUtil.checkIsInAli()){
              return [2]
            }else{
              return [1,2]
            }
            
          }  
        }
    },
    components:{
      Popup,
      Cell,
      // CellGroup
    },
    emits: ["payStart"],
    setup(props,ctx) {
      const store = useStore()
      const propsValue = reactive(props)
      const route = new useRoute()
      const router = new useRouter()
      // reactive 数据双向绑定
      const state = reactive({
        orderNumber:props.orderNumber,
        params:props.params?props.params:{},
        returnUrl:props.returnUrl,
        weChatParameter:null
      })
      watch(
          () => [props.orderNumber,props.params],
          ([newVal, oldVal],[newPlanId, oldPlanId]) => {
            state.orderNumber = newVal,
            state.params = props.params?props.params:{},
            state.returnUrl = props.returnUrl
          }
      )
      watch(() => props.returnUrl, () => {
          state.returnUrl = props.returnUrl
          console.log(state.returnUrl);
        }
      );
     
    // 方法
      const methods={
        closeHandle:()=>{
          store.commit('hidePaying')
        },
        toPay:_.debounce(async(type)=>{
          if(type==2){
            alipay({
              tagType:4, //支付终端: 1:PC 2:安卓 3:IOS 4:H5 5:小程序 6:微信内置H5 7:IPAD
              orderNumber:state.orderNumber,
              returnUrl:state.returnUrl

            }).then(res=>{
              // ctx.emit('payStart')
              aliPay(res)
            })
          }else{
            weixinPay()
          }
        },800)
      }

      // 支付宝支付方法
      const aliPay = (res)=>{
          // 阿里支付
            const div = document.createElement('div')
            div.classList = 'ali-form'
            if (res.body) {
              div.innerHTML = res.body
              document.body.appendChild(div)
              const form = document.querySelector('.ali-form form')
              form.submit()
            } else {
              Toast('支付失败')
              location.reload()
            }
      }

      //微信支付
      const weixinPay = async ()=>{
        if(wxUtil.checkIsInWx()){
          //微信内
          console.log("微信内")
         if(!store.state.user.openId){
              console.log(location.origin + '/getAuthUrl')
              let query = {...route.query, orderNumber:state.orderNumber}
              let currentUrl = JSON.stringify({
                name:route.name,
                query:query
              })
              let authUrl = await wxGetRedict({redirectUri:location.origin + '/getAuthUrl?p='+currentUrl})
              location.href = authUrl
         }
         else{
            wxStartPay()
         }
        
        }else{
          //微信外
          wxpay({
            tagType:4, //支付终端: 1:PC 2:安卓 3:IOS 4:H5 5:小程序 6:微信内置H5 7:IPAD
            orderNumber:state.orderNumber,
            returnUrl:state.returnUrl,
            openId:''
          }).then(res=>{
             const div = document.createElement('div')
             div.classList="weixin"
             if (res.body) {
               console.log(res.body)
               location.href = res.body
            } else {
              Toast('支付失败')
            }
           
          }).catch(err=>{
            console.log(err)
            if(err.code=='400100'){
              location.href = state.returnUrl
            }
          })
          // window.location.replace(data.mwebUrl +'&redirect_url=' + encodeURIComponent(window.location.origin +`/payComplate`))
        }
      }

      const wxStartPay = async()=>{
           wxpay({
              "tagType": 6,
              "orderNumber": state.orderNumber,
              "returnUrl": state.returnUrl,
              "openId": store.state.user.openId
            }).then(res=>{
               console.log(JSON.parse(res.body))
              wechatPay(JSON.parse(res.body))
            }).catch(err=>{
              console.log(err)
               if(err.code=='400100'){
                  location.href = state.returnUrl
                }
            })
           
      }
      const wechatPay = (result) => {
      //封装请求微信支付接口数据
        var request_data = {
          appId: result.appId,
          timeStamp: result.timeStamp + "",
          nonceStr: result.nonceStr,
          package: result.package,
          signType: result.signType,
          paySign: result .paySign,
        };
        if (typeof window.WeixinJSBridge == "undefined") {
          if (window.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              onBridgeReady(request_data),
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent(
              "WeixinJSBridgeReady",
              onBridgeReady(request_data)
            );
            document.attachEvent(
              "OnWeixinJSBridgeReady",
              onBridgeReady(request_data)
            );
          }
        } else {
          onBridgeReady(request_data);
        }
    }
    const onBridgeReady = (request_data) => {
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        request_data,
        (res) => {
            let url = commonJs.getUrl(location.href,{
              orderNumber:state.orderNumber
            })
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // router.push({
            //   name: "PayComplate",
            //   query:{
            //     feePlanStudentId:props.params['feePlanStudentId'],
            //     feePlanId:props.params['feePlanId']
            //   }
            // });
            location.href = state.returnUrl
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            Toast('取消支付') 
            if(location.href.includes('canPay')){
              let url = location.href
              url = url.replace('canPay=1','canPay=2')
              window.history.pushState(null,null,url)
            }

          } else if (res.err_msg == "get_brand_wcpay_request:fail") {
            Toast('支付失败')
             if(location.href.includes('canPay')){
              let url = location.href
              url = url.replace('canPay=1','canPay=2')
              window.history.pushState(null,null,url)
            }
            
          }
        }
      );
    };

      //页面加载完成
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onMounted(async () => {
        if(wxUtil.checkIsInWx() && props.params.channelTypeId == 0){
          let weChatParameter = await getWechatSign({
            url:location.href
          })
          state.weChatParameter = weChatParameter
          if(route.query.canPay&&route.query.canPay == 1){
            state.orderNumber = route.query.orderNumber
            wxStartPay()
          }
        }
         
      })

    return {
      // toRefs转换为响应式数据
      ...toRefs(state),
      ...methods,
      showPay: computed(() => store.state.showPay),
    }
  }
}
</script>
<style lang="scss" scoped>
  .van-cell:first-child {
    &:before{
      position: absolute;
      box-sizing: border-box;
      content: " ";
      pointer-events: none;
      right: var(--van-padding-md);
      top: 0;
      left: var(--van-padding-md);
      border-top: 0.02667rem solid var(--van-cell-border-color);
      transform: scaleY(.5);
    }
  }
  .payHeader{
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bolder;
      color: #333333;
      line-height: 40px;
      text-align: center;
      padding:20px;
    }
  .payContent{
    padding:0 20px 30px 20px;
    margin-top:20px;
  }
  .custom{
    display: flex;
    align-items: center;
    padding:5px 0;
     .custom-title {
      margin-left: 30px;
      font-size: 28px;
      vertical-align: middle;
    }
    .custom-img{
      width: 35px;
    }
  }
 
    
</style>