<template>
  <div :class="{ 'report-service-page': true, active: active }">
    <PageSkeleton :animate="active">
      <ReportLeader
        text="执笔为剑"
        center
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        学习是一种积累
      </div>
      <div class="report-text slide-in slide-in-2">
        日计虽不足
      </div>
      <div class="report-text slide-in slide-in-3">
        岁计则有余
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-4">
        我们
      </div>
      <div class="report-text slide-in slide-in-5">
        进行了<span class="text-bold">{{ reportData.wxInteractCount }}</span>次对话沟通
      </div>
      <div class="report-text" />
      <div
        v-if="reportData.studyPlanCount"
        class="report-text slide-in slide-in-6"
      >
        制定了<span class="text-bold">{{ reportData.studyPlanCount }}</span>次专属学习计划
      </div>
      <div
        v-if="reportData.classReminderCount"
        :class="'report-text slide-in slide-in-' + (6 + (reportData.studyPlanCount ? 1 : 0))"
      >
        轻轻敲响了<span class="text-bold">{{ reportData.classReminderCount }}</span>次上课铃声
      </div>
      <div
        v-if="reportData.answerCount"
        :class="'report-text slide-in slide-in-' + (6 + (reportData.studyPlanCount ? 1 : 0) + (reportData.classReminderCount ? 1 : 0))"
      >
        解答了<span class="text-bold">{{ reportData.answerCount }}</span>次提问
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import { inject } from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
  active: {
    type: Boolean,
    default: false
  }
});
const reportData = inject("reportData")
</script>
<style lang="scss" scoped>
.report-service-page {
  background: url("~@/assets/student/studyReport/postgraduate/2023/bg-service.png");
  background-size: 100%;
  text-align: center;
}
</style>