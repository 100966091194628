<template>
  <div :class="{ 'report-words-page': true, active: active }">
    <PageSkeleton :animate="active">
      <ReportLeader
        text="披荆斩棘"
        right
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        2022年
      </div>
      <div class="report-text slide-in slide-in-2">
        你完成了<span class="text-bold">{{ reportData.wordCount }}</span>次的单词打卡
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-3">
        坚持是获得自由的密钥
      </div>
      <div class="report-text slide-in slide-in-4">
        每一次发奋努力的背后
      </div>
      <div class="report-text slide-in slide-in-5">
        必有加倍的赏赐
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import { inject } from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
    active: {
        type: Boolean,
        default: false
    }
});
const reportData = inject("reportData")
</script>
<style lang="scss" scoped>
.report-words-page {
    background: url("~@/assets/student/studyReport/bg-words.png");
    background-size: 100%;
    text-align: right;
}
</style>