<template>
  <div :class="{ 'report-wish-page': true }">
    <PageSkeleton
      :animate="active"
      :show-footer="false"
    >
      <ReportLeader
        text="班主任寄语"
        center
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        你是一颗不甘平凡的星星
      </div>
      <div class="report-text slide-in slide-in-2">
        你在努力闪耀着属于自己的光
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-3">
        你的征途是星辰大海
      </div>
      <div class="report-text slide-in slide-in-4">
        你终将上岸阳光万里
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-5">
        故事还没结束
      </div>
      <div class="report-text slide-in slide-in-6">
        我们在下一站等你
      </div>
      <div
        :class="{ 'btn-replay': true, active: active }"
        @click="replayReport"
      >
        重新回顾
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
  active: {
    type: Boolean,
    default: false
  }
});
const wishEmits = defineEmits(["replay"])
const replayReport = () => {
  wishEmits("replay");
}
</script>
<style lang="scss" scoped>
.report-wish-page {
  background: url("~@/assets/student/studyReport/bg-wish.png");
  background-size: 100%;
  text-align: center;

  .btn-replay {
    width: 300px;
    height: 80px;
    border: 2px solid #333333;
    line-height: 80px;
    font-size: 34px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin: 200px auto 0 auto;
    opacity: 0;
    transform: translateY(40px);
    &.active {
      transition-property: opacity,transform;
      transition-duration: 1.1s;
      transition-timing-function: ease-in;
      transition-delay: 6s;
      transform: translateY(0);
      opacity: 1;
    }
  }

}
</style>