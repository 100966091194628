
import { defineComponent,reactive, toRefs, onMounted, computed } from 'vue';
import { Image as VanImage, CountDown  } from 'vant';
import LoginModel from "@/components/commonLogin/commonLogin.vue";
import gainShowModel from './components/gainClass.vue'
import wxModelShowModel from './components/wxModel.vue'
import noDataModel from './components/noData.vue'
import { CourseList } from '@/api/classPurchase'
import { useStore} from "vuex";
import route from "@/router";
import { calcPrice, handleDebounce} from "@/utils/tools";
import sensors from "sa-sdk-javascript";
interface dataProps{
  courseList: any,
  showLogin: boolean,
  activityId: number,
  activityTime: number
}
export default defineComponent({
  name: 'classPurchase',
  components: {
    VanImage,
    CountDown,
    LoginModel,
    gainShowModel,
    wxModelShowModel,
    noDataModel
  },
  setup(props){

    const store = useStore();
    const activityInfo = computed(()=>{
      return store.state.classPurchase.activityInfo
    })
    const handleGainClass= handleDebounce((info) :void =>{

      info.activityId= data.activityId
      store.dispatch('handleSetCurrentClass', info)
      store.dispatch('handleBrowseLog', {activityId: store.state.classPurchase.activityId, type:2})
      if(!store.state.user['token']){
        data.showLogin= true
      }else{
        store.dispatch('handleActivityIsBuy')
      }

    },500)

    const data:dataProps = reactive({
      courseList: [],
      showLogin: false,
      activityId: null,
      activityTime: null
    })

    onMounted(():void =>{
      data.activityId = Number(route.currentRoute.value.query.id)
      store.dispatch('handleSetActivited', data.activityId)
      store.dispatch('handleBrowseLog', {activityId: data.activityId, type:1})
      store.dispatch('handleActivityInfo', data.activityId).then(res=>{
        data.activityTime=  new Date(res.expireTime.replace(/-/g, "/")).getTime() as number - new Date().getTime() as number
        sensors.setOnceProfile({ user_source: "渠道", first_view_product: "渠道", first_view_trench_id: String(res.channel),first_view_activity_id: String(res.id),first_visit_time: new Date(),});
      })
      handleCourseList()
    })

    const handleCourseList= ():void =>{
      CourseList( data.activityId).then( (res: any) =>{
        res.map(item=>{
          item.underlinedPrice= calcPrice(item.underlinedPrice)
          return item
        })
        store.dispatch('handleSetClassList', res)
        data.courseList= res;
      })
    }

    const handleCloseShow =():void => {
      data.showLogin= false
    }

    let toRefsData=toRefs(data)

    return {
      ...toRefsData,
      activityInfo,
      handleGainClass,
      handleCloseShow
    }

  }
});
