import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40c9ca1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gainModel" }
const _hoisted_2 = { class: "gainModel-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popup = _resolveComponent("Popup")!
  const _component_ConfigProvider = _resolveComponent("ConfigProvider")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ConfigProvider, { "theme-vars": _ctx.themeVars }, {
      default: _withCtx(() => [
        _createVNode(_component_Popup, {
          show: _ctx.gainClassShow,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.gainClassShow) = $event)),
          onClose: _ctx.handleClose,
          closeable: "",
          round: "",
          "close-on-click-overlay": false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, "您已领取过《" + _toDisplayString(_ctx.currentClass.title) + "》课程了哦~", 1),
              _createElementVNode("div", {
                class: "flex-xy-center classPurchase-down",
                onTouchend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDown && _ctx.handleDown(...args)))
              }, "下载 / 打开APP", 32)
            ])
          ]),
          _: 1
        }, 8, ["show", "onClose"])
      ]),
      _: 1
    }, 8, ["theme-vars"])
  ]))
}