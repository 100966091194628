
import {defineComponent, reactive, toRefs, onMounted, onUnmounted, computed} from 'vue';
import { Image as VanImage } from 'vant';
import {useStore} from "vuex";
import wxModelShowModel from '@/views/landPage/classPurchase/components/wxModel.vue'
import route from "@/router";
import classActivityStyle from "@/utils/classActivity/classActivityStyle";
export default defineComponent({
  name: 'classPurchase',
  components: {
    VanImage,
    wxModelShowModel
  },
  setup(){
     let store = useStore()
    const activityInfo = computed(()=>{
      return store.state.classPurchase.activityInfo
    })
     const currentClass= reactive({
        currentClass: JSON.parse(route.currentRoute.value.query.currentClass as string)
     })
     let handleDown = ():void =>{
       store.dispatch('handleBrowseLog', {activityId: JSON.parse(route.currentRoute.value.query.currentClass as string).activityId, type:3})
       const ua = window.navigator.userAgent.toLowerCase();
       const match = ua.match(/MicroMessenger/i);
       if (match && match.includes('micromessenger')) {
         store.dispatch('handleSetWxModelShow', true)
         return;
       }
       if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
         const loadDateTime = new Date();
         location.href = "hadeedu://hade";
         window.setTimeout(()=> { //如果没有安装app,便会执行setTimeout跳转下载页
           const timeOutDateTime = new Date();
           if (Number(timeOutDateTime) - Number(loadDateTime) < 5000) {
             location.href = "https://apps.apple.com/cn/app/%E5%93%88%E5%BE%B7%E6%95%99%E8%82%B2/id1602171074"; //ios下载地址
           } else {
             window.close();
           }
         }, 2500);

       } else {
         const loadDateTime = new Date();
         location.href = "hadeedu://hade";
         window.setTimeout(()=> {//如果没有安装app,便会执行setTimeout跳转下载页
           const timeOutDateTime = new Date();
           if (Number(timeOutDateTime) - Number(loadDateTime) < 5000) {
             location.href = activityInfo.value.androidDownloadUrl
           } else {
             window.close();
           }
         }, 2500);
       }
     }
    onMounted(()=>{
      let DLhref = window.location.href
      if (DLhref.indexOf('&replace=1') <= -1) {
        window.location.replace(DLhref+'&replace=1')
      }
      store.dispatch('handleActivityInfo', JSON.parse(route.currentRoute.value.query.currentClass as string).activityId)
    })
    onUnmounted(()=>{
      store.dispatch('handleSetWxModelShow', false)
    })
     let toRefsData= toRefs(currentClass)
    return {
      activityInfo,
      handleDown,
      ...toRefsData,
      bgTop:require('@/assets/landPage/classPurchase/bg-top.png')
    }

  }
});
