<template>
  <div :class="{ 'report-practice-page': true, active: active }">
    <PageSkeleton
      :animate="active"
      clear-left
    >
      <ReportLeader
        :texts="['攻无','不克']"
        :highlight-index="1"
        highlight-color="#2A77FF"
        right
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        2023年
      </div>
      <div class="report-text slide-in slide-in-2">
        <span v-if="reportData.submitReportCount <= 0">你还未在APP上完成过刷题练习</span>
        <span v-else>你完成了<span class="text-highlight">{{ reportData.submitReportCount }}</span>次刷题练习</span>
      </div>
      <div class="report-text" />
      <div v-if="reportData.submitReportCount <= 0">
        <div class="report-text slide-in slide-in-3">
          留白也是一种表达方式
        </div>
        <div class="report-text slide-in slide-in-4">
          但温故而知新
        </div>
        <div class="report-text slide-in slide-in-5">
          期待与你发生更多碰撞
        </div>
      </div>
      <div v-if="reportData.submitReportCount > 0 && reportData.submitReportCount <= 5">
        <div class="report-text slide-in slide-in-3">
          外界评价不重要
        </div>
        <div class="report-text slide-in slide-in-4">
          默默前行最可贵
        </div>
        <div class="report-text slide-in slide-in-5">
          想去的地方
        </div>
        <div class="report-text slide-in slide-in-6">
          怎么能半路返航
        </div>
      </div>
      <div v-else-if="reportData.submitReportCount > 5 && reportData.submitReportCount <= 15">
        <div class="report-text slide-in slide-in-3">
          迈的步子虽然不大
        </div>
        <div class="report-text slide-in slide-in-4">
          但每一步都很稳
        </div>
        <div class="report-text slide-in slide-in-5">
          你在暗自努力惊艳所有人
        </div>
      </div>
      <div v-else-if="reportData.submitReportCount > 15 && reportData.submitReportCount <= 30">
        <div class="report-text slide-in slide-in-3">
          学练并举方能成竹在胸
        </div>
        <div class="report-text slide-in slide-in-4">
          你在自己热爱的小小世界
        </div>
        <div class="report-text slide-in slide-in-5">
          闪闪发光
        </div>
      </div>
      <div v-else-if="reportData.submitReportCount > 30">
        <div class="report-text slide-in slide-in-3">
          勤奋是你的勋章
        </div>
        <div class="report-text slide-in slide-in-4">
          你是那个志在山顶的人
        </div>
        <div class="report-text slide-in slide-in-5">
          你是当之无愧的学习之王
        </div>
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import { inject } from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
  active: {
    type: Boolean,
    default: false
  }
});
const reportData = inject("reportData")
</script>
<style lang="scss" scoped>
.report-practice-page {
  background: url("~@/assets/student/studyReport/2023/bg-practice.png");
  background-size: 100%;
  text-align: right;
  .text-highlight {
    color: #2A77FF;
  }
}
</style>