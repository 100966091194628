<template>
  <div class="reservation-dialog" @touchmove="(e) => e.preventDefault()">
    <div class="dialog-content">
      <div class="title">
        {{
          dialogType == 1
            ? "确认邮寄信息"
            : dialogType == 2 || dialogType == 4
            ? "确认预约信息"
            : "确认代领人信息"
        }}
      </div>
      <div class="content-list" v-if="dialogType == 2 || dialogType == 4">
        <div class="list-item">预约时间：{{ reservationDate }}</div>
        <div class="list-item">领取地点：{{ takeAddress }}</div>
      </div>
      <div class="content-list" v-if="dialogType == 3">
        <div class="list-item list-item-flex">
          <div>真实姓名</div>
          <div>{{ name }}</div>
        </div>
        <div class="list-item list-item-flex">
          <div>身份证号码</div>
          <div class="need-hide">{{ phone }}</div>
        </div>
      </div>
      <div class="content-list" v-if="dialogType == 1">
        <div class="list-item list-item-flex">
          <div>收件人</div>
          <div>{{ name }}</div>
        </div>
        <div class="list-item item-center list-item-flex">
          <div>联系电话</div>
          <div>{{ phone }}</div>
        </div>
        <div class="list-item list-item-flex">
          <div>收件地址</div>
          <div class="need-hide">{{ cityName }}{{ address }}</div>
        </div>
      </div>
      <div class="content-tip">
        {{
          dialogType == 2
            ? "*请本人并携带身份证原件，按时到校区领取。周末及节假日，校区无人，容易跑空哦！"
            : dialogType == 3
            ? "*证书属于贵重物品，确认代领人信息后将无法修改，稍后将签署代领委托书。领取时校区老师将会依次核对代领人信息。"
            : dialogType == 4
            ? "*请联系代领人按时到校区，并携带代领人身份证原件与委托书截图，现场老师将进行核对。周末及节假日，校区无人，容易跑空哦！"
            : "*请填写正确的收货信息，确认后将不允许修改！"
        }}
      </div>
      <div class="content-btn">
        <div class="btn-left" @click="closeDialog">
          {{
            dialogType == 2 || dialogType == 4
              ? "暂不预约"
              : dialogType == 1 || dialogType == 3
              ? "修改信息"
              : "取消"
          }}
        </div>
        <div class="btn-right" @click="submitDialog">
          {{
            dialogType == 2 || dialogType == 4
              ? "确认预约"
              : dialogType == 1 || dialogType == 3
              ? "确认无误"
              : "确认"
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps } from "vue";
const props = defineProps([
  "name",
  "phone",
  "cityName",
  "address",
  "cardId",
  "dialogType",
  "reservationDate",
  "takeAddress",
]);
const emit = defineEmits(["closeDialog", "submitDialog"]);
const closeDialog = () => {
  emit("closeDialog");
};
const submitDialog = () => {
  emit("submitDialog");
};
</script>
<style lang="scss" scoped>
.reservation-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-content {
  width: 650px;
  border-radius: 40px;
  background: #fff;
  padding: 60px 40px 42px 40px;
  box-sizing: border-box;
}
.title {
  font-size: 32px;
  text-align: center;
  color: #333;
  font-weight: 600;
  padding-bottom: 60px;
}
.content-list {
  margin-bottom: 24px;
  .list-item {
    font-size: 28px;
    color: #333;
    line-height: 1;
  }
  .list-item:first-child {
    margin-bottom: 30px;
  }
  .item-center {
    margin-bottom: 30px;
  }
  .list-item-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .need-hide {
    padding-left: 40px;
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
  }
}
.content-tip {
  font-size: 24px;
  color: #ff0e0e;
  margin-bottom: 60px;
  text-align: justify;
}
.content-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn-left,
  .btn-right {
    width: 260px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    border-radius: 44px;
    border: 1px solid #222222;
    color: #222;
    font-size: 28px;
  }
  .btn-right {
    color: #fff;
    background: #1e4290;
    border: 1px solid #1e4290;
  }
}
</style>
