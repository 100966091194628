<template>
  <div class="reservation-info-page">
    <reservationStep
      :stepType="receiveType"
      :stepNum="stepNum"
    ></reservationStep>
    <div class="step-content">
      <div class="info-list">
        <div class="info-list-title">委托书信息</div>
        <div class="info-list-content">
          <div class="content-left">签署状态</div>
          <div class="content-right">
            {{ isContract ? "已签署" : "未签署" }}
          </div>
        </div>
        <div class="info-list-content">
          <div class="content-left">签约链接</div>
          <div class="content-right">
            <div class="right-url">{{ contractUrl }}</div>
            <div class="right-icon"></div>
            <div class="right-text" @click="copyText">复制</div>
          </div>
        </div>
        <div class="info-list-content" v-if="!isWX">
          <div class="content-center" @click="showContract(contractUrl)">
            点击跳转浏览器签署委托书
          </div>
        </div>
      </div>
    </div>
    <div class="step-content-bottom">
      *认真阅读委托书内容，在浏览器完成电子签后，返回App完成完成邮寄预约。
    </div>
  </div>
  <div class="page-bottom-tip" @click="showDialog = true">
    <img src="@/assets/certificate/tip.png" alt="" /> 签署遇到问题？
  </div>
  <div class="page-bottom">
    <div class="bottom-btn" @click="updateStatus">我已签署委托书</div>
  </div>
  <div class="dialog-layer" v-show="showDialog">
    <div class="dialog-content">
      <div class="title">签署遇到问题</div>
      <div class="content">
        <div class="text">
          在签署过程中遇到任何问题，请及时联系班主任老师。如遇到本人信息与支付宝认证信息不一致时，请在班主任老师修改成一致后，再点击【重新发起委托书】
        </div>
      </div>
      <div class="content-btn">
        <div class="btn-left" @click="reContractUrl">重新发起委托书</div>
        <div class="btn-right" @click="showDialog = false">我知道了</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { Toast } from "vant";
import reservationStep from "./components/reservationStep.vue";
import { useRouter, useRoute } from "vue-router";
import { certificateContract, reContract } from "@/api/certificate";
import commonJs from "@/utils/common";
import useClipboard from "vue-clipboard3";
import { wxUtil } from "@/utils/wx_util.ts";
const { toClipboard } = useClipboard();
const routeQuery = new useRoute();
const router = useRouter();
const isContract = ref(false);
const contractUrl = ref("");
const showDialog = ref(false);
//证书ID
let id = routeQuery.query.id;
//类型
let type = routeQuery.query.type;
const receiveType = ref(type);
let step = type == 1 ? 2 : 3;
const stepNum = ref(step);
const isWX = ref(false);
// 获取地址
const getPageData = async (status) => {
  const res = await certificateContract(id);
  if (res.code == 200 && res.data) {
    if (status == 2 && res.data.isContract) {
      router.push({
        name: "reservationSuccess",
        query: {
          id: id,
          type: type,
        },
      });
      return;
    }
    isContract.value = res.data.isContract;
    contractUrl.value = res.data.contractUrl;
  }
};
onMounted(() => {
  document.title = type == 1 ? "邮寄领取证书" : "他人校区代领";
  getPageData(1);
  if (wxUtil.checkIsInWx()) {
    isWX.value = true;
  }
});
const copyText = async () => {
  try {
    await toClipboard(contractUrl.value);
    Toast("复制成功");
  } catch (e) {
    Toast("复制失败");
  }
};
const updateStatus = () => {
  getPageData(2);
};

const reContractUrl = async () => {
  const res = await reContract({ id: id });
  if (res.code == 200) {
    showDialog.value = false;
    isContract.value = res.data.isContract;
    contractUrl.value = res.data.contractUrl;
  }
};
//打开委托书
const showContract = (url) => {
  let typeJudge = commonJs.judgeUserAgent();
  if (typeJudge.isiOS) {
    window.webkit.messageHandlers.openContract.postMessage(url); //Contract交互的方法名
  } else {
    window.hade.openContract(url);
  }
};
</script>
<style lang="scss" scoped>
.reservation-info-page {
  width: 100%;
  height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
}
.step-content {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
}
.info-list {
  width: 100%;
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
  border-radius: 24px;
  background: #fff;
  margin-bottom: 30px;
  .info-list-title {
    font-size: 28px;
    color: #222;
    font-weight: 600;
    padding: 30px 0;
    border-bottom: 2px solid #eee;
  }
  .info-list-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;
    color: #222;
    margin-top: 26px;
  }
  .content-right {
    color: #ff0000;
    display: flex;
    align-items: center;
    font-size: 24px;
    .right-url {
      color: #222;
    }
    .right-text {
      color: #1e4290;
      padding-left: 10px;
    }
  }
  .content-center {
    width: 100%;
    height: 56px;
    color: #1e4290;
    border-radius: 8px;
    border: 1px solid #1e4290;
    text-align: center;
    line-height: 56px;
  }
}
.content-item {
  padding: 14px 37px;
  line-height: 1;
  border-radius: 8px;
  border: 1px solid #999999;
  font-size: 24px;
  color: #999;
  margin-right: 30px;
}
.content-item:last-child {
  margin-right: 0;
}
.content-item-check {
  border: 1px solid #1e4290;
  color: #1e4290;
}
.step-content-bottom {
  width: 100%;
  padding: 0 30px;
  font-size: 24px;
  color: #ff0000;
  box-sizing: border-box;
}
.page-bottom-tip {
  position: fixed;
  left: 0;
  bottom: 190px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-size: 24px;
  img {
    width: 32px;
    height: 32px;
    margin-right: 14px;
  }
}
.page-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 152px;
  background: #fff;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  .bottom-btn {
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #1e4290;
    font-size: 28px;
    color: #fff;
    border-radius: 88px;
  }
}
.dialog-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .dialog-content {
    width: 650px;
    border-radius: 40px;
    background: #fff;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    color: #333;
    font-weight: 600;
  }
  .content {
    margin: 40px 0 60px 0;
    .text {
      font-size: 28px;
      color: #333;
      margin-bottom: 20px;
    }
  }
  .content-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-left,
    .btn-right {
      width: 265px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      border-radius: 44px;
      border: 1px solid #222222;
      color: #222;
      font-size: 28px;
    }
    .btn-right {
      color: #fff;
      background: #1e4290;
      border: 1px solid #1e4290;
    }
  }
}
</style>
