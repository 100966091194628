<template>
  <div class="calendar-select-date">
    <div class="select-content">
      <div class="title">{{ initTime(dateData.dateTimeStamp, 2) }}</div>
      <div class="close" @click="closeLayer">
        <img src="@/assets/certificate/close-layer.png" alt="" />
      </div>
      <div class="select-item" @click="selectTime(1)">
        <div class="time-top">上午时段</div>
        <div class="time-bottom">09:00-12:00</div>
      </div>
      <div class="select-item bottom0" @click="selectTime(2)">
        <div class="time-top">下午时段</div>
        <div class="time-bottom">13:30-18:30</div>
      </div>
      <div class="tip">*请勿选择周末及节假日，校区无人，容易跑空哦！</div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineExpose, defineEmits } from "vue";
const props = defineProps(["dateData", "index"]);
const emit = defineEmits(["closeSelectLayer", "chooseSelectTime"]);
import { initTime } from "@/utils/tools";
const closeLayer = () => {
  emit("closeSelectLayer");
};
const selectTime = (e) => {
  emit("chooseSelectTime", {
    reservationTimeType: e,
    index: props.index,
  });
};
defineExpose({});
</script>
<style lang="scss" scoped>
.calendar-select-date {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.select-content {
  position: relative;
  width: 100%;
  height: 650px;
  background: #ffffff;
  border-radius: 40px 40px 0px 0px;
  padding: 40px 40px 0 40px;
  box-sizing: border-box;
}
.title {
  text-align: center;
  color: #222;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 46px;
}
.close {
  position: absolute;
  right: 0;
  top: 0;
  width: 76px;
  height: 76px;
  img {
    width: 100%;
    height: 100%;
  }
}
.select-item {
  width: 100%;
  height: 162px;
  border-radius: 12px;
  border: 1px solid #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #222;
  font-size: 32px;
  margin-bottom: 40px;
  font-weight: 600;
  .time-top {
    margin-bottom: 20px;
  }
}
.bottom0 {
  margin-bottom: 0;
}
.tip {
  color: #ff0000;
  font-size: 24px;
  margin-top: 20px;
}
</style>
