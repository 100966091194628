import request from '@/utils/request'
export interface IArea{
    areaCode: string
    areaIsHot: string
    areaName: string
    areaSequence: string
    areaShort: string
    createdAt: string
    id: number
    initAddr: string
    level: number
    parentId: number
    regionId: number
    sort: number
}
export function getArea(id?:number|string): Promise<IArea[]> {
    return request({
        url: `/hd-api/os/area/select-all-by-parent-id`,
        params:{parentId:id || 0},
        method: 'get'
    })
}