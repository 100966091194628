import request from '@/utils/request'
import { AxiosPromise } from 'axios'

export function getEvaluationInfo (evaluationTemplateId: number, teachingAssistantId: number): AxiosPromise {
    return request({
        url: `/hd-api/os/app/evaluation/query/getEvaluationInfo`,
        method: 'post',
        data: {
            evaluationTemplateId,
            teachingAssistantId
        }
    })
}
interface IAnswer {
    id: number;
    answerList: Array<IAnswerList>;
}
interface IAnswerList {
    id: number;
    answer: string | number;
}
export function submitEvaluationInfo (data:IAnswer): AxiosPromise {
    return request({
        url: `/hd-api/os/app/evaluation/command/submitAnswer`,
        method: 'post',
        data
    })
}