<template>
  <div>
    <div
      v-if="isMobile"
      class="full-screen"
    >
      <Swipe
        v-if="hasReport"
        ref="swiperRef"
        vertical
        :loop="false"
        :show-indicators="false"
        style="height: 100%;"
        @change="onSwipeChange"
      >
        <SwipeItem>
          <ReportPageHome :active="active == 0" />
        </SwipeItem>
        <SwipeItem>
          <ReportPageLetter :active="active == 1" />
        </SwipeItem>
        <SwipeItem>
          <ReportPageServiceData :active="active == 2" />
        </SwipeItem>
        <SwipeItem v-if="reportData.wordCount">
          <ReportPageWords :active="active == 3" />
        </SwipeItem>
        <SwipeItem v-if="reportData.totalStudyHour">
          <ReportPageCourse :active="active == nextCount(3, reportData.wordCount, 0)" />
        </SwipeItem>
        <SwipeItem>
          <ReportPagePractice :active="active == nextCount(3, reportData.wordCount, reportData.totalStudyHour)" />
        </SwipeItem>
        <SwipeItem>
          <ReportPageWishMessage
            :active="active == nextCount(4, reportData.wordCount, reportData.totalStudyHour)"
            @replay="replay"
          />
        </SwipeItem>
      </Swipe>
      <div
        v-else
        class="full-screen"
      >
        <ReportPageEmpty />
      </div>
      <audio
        ref="audioRef"
        autoplay
        preload
        loop
        style="display:none;"
        @play="playing = true"
        @pause="playing = false"
      >
        <source
          src="~@/assets/student/studyReport/audio/report-bgm.mp3"
          type="audio/mpeg"
        >
      </audio>
    </div>
    <div v-else>
      <ReportQrcode />
    </div>
    <div
      v-show="isMobile"
      :class="{ 'bgm-controller': true, playing: playing }"
      @click="toggleBgm"
    />
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, nextTick, provide } from "vue";
import { useStore } from "vuex";
import { loadPostGraduateReportData } from "@/api/studyReport"
import { Swipe, SwipeItem } from 'vant';
import ReportPageHome from "./components/postgraduate2023/ReportPageHome.vue";
import ReportPageLetter from "./components/postgraduate2023/ReportPageLetter.vue";
import ReportPageServiceData from "./components/postgraduate2023/ReportPageServiceData.vue";
import ReportPageWords from "./components/postgraduate2023/ReportPageWords.vue";
import ReportPageCourse from "./components/postgraduate2023/ReportPageCourse.vue";
import ReportPagePractice from "./components/postgraduate2023/ReportPagePractice.vue";
import ReportPageWishMessage from "./components/postgraduate2023/ReportPageWishMessage.vue";
import ReportPageEmpty from "./components/postgraduate2023/ReportPageEmpty.vue";
import ReportQrcode from "./components/postgraduate2023/ReportQrcode.vue";
const store = useStore();
const swiperRef = ref();
const audioRef = ref();
const active = ref(-1);
const playing = ref(false);
const isMobile = ref(false);

const onSwipeChange = (index) => {
  active.value = index;
}
const replay = () => {
  swiperRef.value.swipeTo(0);
}
const toggleBgm = () => {
  if (audioRef.value.paused) {
    audioRef.value.play();
  } else {
    audioRef.value.pause();
  }
}
const hasReport = ref(false);
const reportData = reactive({
  authName: "",
  firstEnrollTime: "",
  totalDay: 0,
  wxInteractCount: 0,
  studyPlanCount: 0,
  classReminderCount:0,
  answerCount: 0,
  wordCount: 0,
  studyCourseCount: 0,
  totalStudyHour: 0,
  exceedRatio: 0,
  submitReportCount: 0
});
const checkMobileDivice = () => {
  var devices = ["iPhone", "Android"];
  var ua = window.navigator.userAgent;
  for (var i = 0; i < devices.length; i++) {
    if (ua.indexOf(devices[i]) != -1) {
      return true;
    }
  }
  return false;
}
const nextCount = (base, word, course) => {
  return base + (word ? 1 : 0) + (course ? 1 : 0);
}
onMounted(async () => {
  isMobile.value = checkMobileDivice();
  if(!store.state.user['token']){
    await nextTick();
  }
  //请求数据
  setTimeout(async () => {
    const { data } = await loadPostGraduateReportData();
    if (data) {
      hasReport.value = true;
      Object.assign(reportData, data);

      nextTick(() => {
        active.value = 0;
      })
    }
  }, 500)
})
document.addEventListener("WeixinJSBridgeReady", function () {
  setTimeout(() => {
    if (audioRef.value.paused) {
      try {
        audioRef.value.muted = false;
        audioRef.value.pause();
        audioRef.value.play();
      } catch (e) {
        //
      }
    }
  }, 500);
}, false);
provide("reportData", reportData);
</script>
<style lang="scss" scoped>
.full-screen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.bgm-controller {
  position: fixed;
  top: 40px;
  left: 40px;
  font-size: 48px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: url("~@/assets/student/studyReport/music-o-pause.png");
  background-size: 100%;

  &.playing {
    background: url("~@/assets/student/studyReport/music-o.png");
    background-size: 100%;
    animation: rotate-animate 1.5s linear infinite;
  }

  @keyframes rotate-animate {
    from {
      transform: rotateZ(0deg);
    }

    to {
      transform: rotateZ(360deg);
    }
  }
}
</style>