<template>
  <div class="certificate-receive-type-page">
    <div
      class="receive-type-list"
      :class="{ 'receive-check': receiveType == 2 }"
      @click="checkType(2)"
    >
      <div class="title">本人校区领取</div>
      <div class="text">重要证书推荐本人现场领取，亲身感受领证仪式感</div>
      <div class="icon"></div>
    </div>
    <div
      class="receive-type-list"
      :class="{ 'receive-check': receiveType == 1 }"
      @click="checkType(1)"
    >
      <div class="title">邮寄领取</div>
      <div class="text">非厦门地区学员，签署邮寄委托书，证书将邮寄派送</div>
    </div>
    <div
      class="receive-type-list"
      :class="{ 'receive-check': receiveType == 3 }"
      @click="checkType(3)"
    >
      <div class="title">他人校区代领</div>
      <div class="text">
        本人无法到场领取，签署代领委托书，由亲友代领转交证书
      </div>
    </div>
  </div>
  <div class="page-bottom">
    <div
      class="bottom-btn"
      :class="{ 'disabled-btn': receiveType == -1 }"
      @click="confirmReceive"
    >
      确定领取方式
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
const routeQuery = new useRoute();
const router = useRouter();
const receiveType = ref(-1);
const confirmReceive = () => {
  if (receiveType.value == -1) return;
  if (receiveType.value == 2) {
    router.push({
      name: "reservationSelf",
      query: {
        id: routeQuery.query.id,
      },
    });
  }
  if (receiveType.value == 1) {
    router.push({
      name: "reservationMail",

      query: {
        id: routeQuery.query.id,
      },
    });
  }
  if (receiveType.value == 3) {
    router.push({
      name: "reservationOtherInfo",
      query: {
        id: routeQuery.query.id,
      },
    });
  }
};
const checkType = (e) => {
  receiveType.value = e;
};
</script>
<style lang="scss" scoped>
.certificate-receive-type-page {
  width: 100%;
  height: 100vh;
  background: #fafafa;
  padding: 40px 30px 0 30px;
  box-sizing: border-box;
}
.receive-type-list {
  position: relative;
  width: 100%;
  height: 160px;
  background: #fff;
  border-radius: 12px;
  padding-left: 30px;
  padding-top: 40px;
  box-sizing: border-box;
  margin-bottom: 40px;
  border: 2px solid transparent;
  .title {
    font-size: 32px;
    color: #222;
    font-weight: 600;
    line-height: 1;
  }
  .text {
    font-size: 24px;
    color: #999;
    line-height: 1;
    margin-top: 26px;
  }
  .icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 112px;
    height: 48px;
    background: url("~@/assets/certificate/receive-icon.png") no-repeat;
    background-size: cover;
  }
}
.receive-check {
  border: 2px solid #1e4290;
}
.page-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 152px;
  background: #fff;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  .bottom-btn {
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #1e4290;
    font-size: 28px;
    color: #fff;
    border-radius: 88px;
  }
  .disabled-btn {
    background: #8997b3;
  }
}
</style>
