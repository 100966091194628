
export const commonJs = {
     changeIosTime:(time)=>{
        const timestamp2 = new Date(time.replace(/-/g, "/")).getTime();
        return timestamp2
    },
    validatePhone:/^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/,
    
    validityNumber:/^[0-9]*[1-9][0-9]*$/,
    getUrl(url, data){
        //看原始url地址中开头是否带?，然后拼接处理好的参数
        return url += (url.indexOf('?') < 0 ? '?' : '') + this.getParam(data)
    },
    getParam(data){
        let url = '';
        for(var k in data){
            let value = data[k] !==undefined ? data[k] : '';
            url += `&${k}=${encodeURIComponent(value)}`
        }
        return url ? url.substring(1) : ''
    },
    validateCertificate(userCardvalue){ 
        // (^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)   //15位身份证  
        var userCardreg = /(^\d{15}$)|(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)/;
        var taiwanreg=/^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/;
        var xianggangreg=/^([A-Z]\d{6,10}(\(\w{1}\))?)$/;
        var aomenreg=/^[157][0-9]{6}\([0-9]\)$/;
        if(userCardreg.test(userCardvalue)||taiwanreg.test(userCardvalue)||xianggangreg.test(userCardvalue)||aomenreg.test(userCardvalue)){
            return true
        }else{
            return false
        }
    },
     urlToBlob(the_url, fileName, callback) {
        let xhr = new XMLHttpRequest();
        xhr.open("get", the_url, true);
        xhr.responseType = "blob";
        xhr.onload = function() {
            if (this.status == 200) {
                callback && callback(this.response, fileName)
            }
        };
        xhr.send();
    },
    judgeUserAgent(){
        var u = navigator.userAgent;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        console.log(isiOS,isAndroid)
        return {
            isiOS:isiOS,
            isAndroid:isAndroid
        }

    }

}

export default commonJs