<template>
  <div class="certificate-info-page">
    <template v-if="infoData">
      <certificateImage
        @showCertificatePrivew="showCertificatePrivew"
        :imgData="infoData.certificateFile"
      ></certificateImage>
      <certificateBaseInfo
        :collegeName="infoData.collegeName"
        :majorName="infoData.majorName"
        :layeredName="infoData.layeredName"
        :certificateType="infoData.certificateType"
        :projectName="infoData.projectName"
        :issuanceDateStr="infoData.issuanceDateStr"
      ></certificateBaseInfo>
      <certificateReceiveInfo :infoData="infoData"></certificateReceiveInfo>
    </template>
  </div>
  <template
    v-if="
      infoData &&
      infoData.status != 0 &&
      infoData.status != 1 &&
      infoData.status != 7
    "
  >
    <div class="page-bottom">
      <div
        class="bottom-btn"
        :class="[
          infoData.status == 6 ? 'update-btn' : '',
          infoData.status == 4 ? 'disabled-btn' : '',
        ]"
        @click="linkToPage(infoData.status)"
      >
        {{
          infoData.status == 2
            ? "领取证书"
            : infoData.status == 3
            ? "继续签约"
            : infoData.status == 4
            ? "确认领取"
            : infoData.status == 5
            ? "确认领取"
            : infoData.status == 6
            ? "重新预约"
            : ""
        }}
      </div>
    </div>
  </template>

  <certificatePreview
    v-if="infoData"
    :layerStatus="layerStatus"
    :isFirst="isFirst"
    :infoData="infoData"
    :isWX="isWX"
    @closeCertificatePrivew="closeCertificatePrivew"
  ></certificatePreview>
  <div class="receive-dialog" v-show="showReceiveDialog">
    <div class="dialog-content">
      <div class="title">确认已领取证书</div>
      <div class="content">
        <div class="text">一、请核对证书、档案袋上的个人信息是否准确无误。</div>
        <div class="text">
          二、证书、档案袋属于个人重要证件，请务必妥善保管，避免遗失。一旦遗失，高校不能补办原件。档案袋已密封，请勿拆封。
        </div>
        <div class="text">
          三、关于证书保管，建议摊平妥善保管，由于南方天气比较潮湿，放在证书封皮内保存，容易造成证书文字信息重叠模糊。
        </div>
        <div class="tip">*如有疑问，请及时与班主任老师沟通。</div>
      </div>
      <div class="content-btn">
        <div class="btn-left" @click="closeReceiveDialog">还未领取</div>
        <div
          class="btn-right"
          @click="saveMail"
          :class="{ 'time-disabled': time > 0 }"
        >
          确认领取<span v-if="time > 0">（{{ time }}s）</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getCertificateInfo, confirmReceiveMail } from "@/api/certificate";
import certificateImage from "./components/certificateImage.vue";
import certificatePreview from "./components/certificatePreview.vue";
import certificateBaseInfo from "./components/certificateBaseInfo.vue";
import certificateReceiveInfo from "./components/certificateReceiveInfo.vue";
import { hideCardId, waitToken } from "@/utils/tools";
import { wxUtil } from "@/utils/wx_util.ts";
const routeQuery = new useRoute();
const router = useRouter();
const layerStatus = ref(false);
const isFirst = ref(false);
const showReceiveDialog = ref(false);
const time = ref(5);
const isWX = ref(false);
//证书对象
const infoData = ref(null);
let id = routeQuery.query.id;
//
let intervalId = "";
// 获取证书信息
const getPageData = async () => {
  const res = await getCertificateInfo(id);
  if (res.code == 200) {
    res.data.takeAuthNumHide = res.data.takeAuthNum
      ? hideCardId(res.data.takeAuthNum)
      : "";
    infoData.value = res.data;
    if (!res.data.isUserView) {
      isFirst.value = true;
      layerStatus.value = true;
    }
  }
};
onMounted(async () => {
  await waitToken(2000);
  getPageData();
  if (wxUtil.checkIsInWx()) {
    isWX.value = true;
  }
});
const showCertificatePrivew = () => {
  layerStatus.value = true;
};
const closeCertificatePrivew = () => {
  layerStatus.value = false;
  isFirst.value = false;
};
const linkToPage = (status) => {
  if (status == 4) {
    return;
  }
  if (status == 2) {
    router.push({
      name: "receiveType",
      query: {
        id: infoData.value.id,
      },
    });
  }
  if (status == 3) {
    router.push({
      name: "reservationEntrust",
      query: {
        id: infoData.value.id,
        type: infoData.value.receiveType,
      },
    });
  }
  if (status == 6) {
    if (infoData.value.receiveType == 2) {
      router.push({
        name: "reservationSelf",
        query: {
          id: infoData.value.id,
        },
      });
    }
    if (infoData.value.receiveType == 3) {
      router.push({
        name: "reservationOtherInfo",
        query: {
          id: infoData.value.id,
          updateinfo: 1,
        },
      });
    }
  }
  if (status == 5) {
    showReceiveDialog.value = true;
    startCountdown();
  }
};
const closeReceiveDialog = () => {
  showReceiveDialog.value = false;
  clearInterval(intervalId);
};
const startCountdown = () => {
  time.value = 5;
  // 倒计时更新函数
  const updateCountdown = () => {
    if (time.value <= 0) {
      clearInterval(intervalId); // 清除定时器
    } else {
      time.value--;
    }
  };
  // 开始倒计时
  intervalId = setInterval(updateCountdown, 1000);
};
const saveMail = async () => {
  if (time.value > 0) {
    return;
  }
  const res = await confirmReceiveMail({ id: id });
  if (res.code == 200) {
    showReceiveDialog.value = false;
    getPageData();
  }
};
</script>
<style lang="scss" scoped>
.certificate-info-page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
}
.page-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 152px;
  background: #fff;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  .bottom-btn {
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 88px;
    border: 1px solid #1e4290;
    font-size: 28px;
    border-radius: 88px;
    color: #fff;
    background: #1e4290;
  }
  .update-btn {
    color: #222;
    background: #fff;
    border: 1px solid #222;
  }
  .disabled-btn {
    background: #8997b3;
    border: 1px solid #8997b3;
  }
}
.receive-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(000, 000, 000, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  .dialog-content {
    width: 650px;
    border-radius: 40px;
    background: #fff;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 32px;
    color: #333;
    font-weight: 600;
  }
  .content {
    margin: 40px 0;
    .text {
      font-size: 28px;
      color: #333;
      margin-bottom: 20px;
    }
    .tip {
      color: #ff0e0e;
      font-size: 24px;
    }
  }
  .content-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-left,
    .btn-right {
      width: 265px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      border-radius: 44px;
      border: 1px solid #222222;
      color: #222;
      font-size: 28px;
    }
    .btn-right {
      color: #fff;
      background: #1e4290;
      border: 1px solid #1e4290;
    }
    .time-disabled {
      background: #8997b3;
      border: 1px solid #8997b3;
    }
  }
}
</style>
