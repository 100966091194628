import store from "@/store"
import request from "@/utils/request"
import Decimal from "decimal"
import * as dd from 'dingtalk-jsapi';
import {getDingSignatureInfo} from "@/api/setting"
/**
 * 手机号格式
 */

export function isPhoneNumber (tel: string) {
    const reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
    return reg.test(tel);
}

/**
 * 保留两位小数
 */

export function calcPrice (price: number, fixed = 2) {
    if (!price) price = 0;
    return (price / 100).toFixed(fixed);
}

/**
 * 函数防抖
 * @param {function} fn 函数
 * @param {number} t 等待时间（毫秒）
 * @return {function}
 */
let timeId
export function handleDebounce (fn, t) {
    const delay = t || 500

    return function (...args) {
        if (timeId) {
            clearTimeout(timeId)
        }
        const callNow = !timeId
        timeId = setTimeout(() => {
            timeId = null
        }, delay)
        if (callNow) {
            fn.apply(this, args)
        }

    }
}
export function downloadBase64 (base64, fileName) {
    if (dd.env.platform != 'notInDingTalk') {
      console.log(11111)
      dd.ready(function () {
        console.log(2222)
        console.log(dd.biz.util.previewImage)
        dd.biz.util.previewImage({
          urls: [base64],
          current: base64, 
         
        })
        
      })
    } else {
      // eslint-disable-next-line prefer-const
      // let arr = base64.split(",");
      //   const mime = arr[0].match(/:(.*?);/)[1];
      //   const bstr = atob(arr[1]);
      //   let n = bstr.length;
      //   const  u8arr = new Uint8Array(n);
      // while (n--) {
      //   u8arr[n] = bstr.charCodeAt(n);
      // }
      // const blob = new Blob([u8arr], {
      //   type: mime,
      // });
      // const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      const blob = base64ToBlob(base64);
      const event = document.createEvent("MouseEvents");
      link.style.cursor = "pointer"
      event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      link.download = fileName;
      link.href = URL.createObjectURL(blob);
      link.dispatchEvent(event)
    }
  }
   // base64转Blob对象
 export function base64ToBlob(code) {
    const parts = code.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uint8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; i++) {
        uint8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uint8Array], {type: contentType});
  }
  
  export function copyToClipboard (textToCopy) {
    // navigator clipboard 需要https等安全上下文
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard 向剪贴板写文本
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // 创建text area
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // 使text area不在viewport，同时设置不可见
      textArea.style.position = "absolute";
      textArea.style.opacity = '0';
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise<void>((res, rej) => {
        // 执行复制命令并移除文本框
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  }
export function formatDate (date: Date, fmt = "yyyy-MM-dd"): string {
    const o = {
      "M+": date.getMonth() + 1, //月份
      "d+": date.getDate(), //日
      "h+": date.getHours(), //小时
      "m+": date.getMinutes(), //分
      "s+": date.getSeconds(), //秒
      "q+": Math.floor((date.getMonth() + 3) / 3), //季度
      S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        String(date.getFullYear()).substr(4 - RegExp.$1.length),
      );
    for (const k in o)
      if (new RegExp("(" + k + ")").test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ("00" + o[k]).substr(String(o[k]).length),
        );
    return fmt;
  }
//上传文件
export async function uploadFile (file, ext = "png", keepName, cb?) {
    // if (typeof file == "string") {
    //   file = toolCommon.dataURLtoFile(file);
    // }
    //判断是否过期
    const ossSignature = store.state.settings["ossSignature"];
    const timeOut = new Date(
        ossSignature && ossSignature.expire
            ? Decimal(ossSignature.expire).mul(1000).toNumber()
            : 0,
    );
    if (new Date() > timeOut) {
        await store.dispatch("getOssSignature");
    }
    return await handleUpload(file, ext, keepName, cb);
}
async function handleUpload (file, ext, keepName, cb) {
    const ossSignature = store.state.settings["ossSignature"];
    let filename = `${ossSignature.dir}${uuid(16)}/`;
    if (keepName) {
        filename += file.name;
    } else {
        filename += `${formatDate(new Date(), "yyyyMMdd")}_${Number(new Date())}`;
        if (ext) {
            filename += "." + ext;
        }
    }
    const formData = new FormData();
    formData.append("key", filename);
    formData.append("policy", ossSignature.policy);
    formData.append("OSSAccessKeyId", ossSignature.accessid);
    formData.append("success_action_status", "200");
    formData.append("callback", ossSignature.callback);
    formData.append("signature", ossSignature.signature);

    formData.append('file', file);

    const result = await request({
        url: ossSignature.host,
        method: "post",
        data: formData,
        transformRequest: [function (data) {
            // 对发送的 data 进行任意转换处理
            data.toString = () => "[object FormData]"
            return data;
        }],
    });
    return {
        ext,
        type: file.type,
        size: file.size,
        originalName: result.fileName,
        oldName: file.name,
        path: result.url,
        id: result.id,
    };
}
/**
 * 获取 uuid
 * @param {*} len 生成uuid的长度
 */
export function uuid (len = 16):string {
    const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
        "",
    );
    const uuids = [];

    const radix = chars.length;
    let i;
    if (len) {
        // Compact form
        for (i = 0; i < len; i++) uuids[i] = chars[0 | (Math.random() * radix)];
    } else {
        // rfc4122, version 4 form
        let r;
        // rfc4122 requires these characters
        uuids[8] = uuids[13] = uuids[18] = uuids[23] = "-";
        uuids[14] = "4";
        // Fill in random data.  At i==19 set the high bits of clock sequence as
        // per rfc4122, sec. 4.1.5
        for (i = 0; i < 36; i++) {
            if (!uuids[i]) {
                r = 0 | (Math.random() * 16);
                uuids[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuids.join("");
}
/**指定时长内尝试获取token 最长5秒*/
export async function waitToken (timeout:number):Promise<void> {
  const maxWait = new Promise((resolve)=>{
      setTimeout(()=>{
        resolve("timeout");
      },Math.min(timeout,5000))
    })
  const loadToken = new Promise((resolve)=>{
    const interval = setInterval(()=>{
      const token = store.state.user["token"];
      if(token){
        clearInterval(interval);
        resolve(token);
      }
    },50)
  })
  await Promise.race([maxWait,loadToken])
}

export function initTime (date:number,type:number) {
  const daysOfWeek = ["一", "二", "三", "四", "五", "六", "日"];
  const time = date * 1000;
  const yy = new Date(time).getFullYear();
  const mm = new Date(time).getMonth() + 1;
  const dd = new Date(time).getDate().toString().padStart(2, '0');
  const week = daysOfWeek[new Date(time).getDay() - 1];
  if(type == 1){
    return `${yy}.${mm}.${dd}（周${week}）`;
  }
  if(type == 2){
    return `${yy}年${mm}月${dd}日（周${week}）`;
  }
}

export function hideCardId (idCard) {
  // 首先检查身份证号是否至少有4位，以确保可以隐藏后四位
  if (idCard.length < 4) {
      return idCard; // 或者根据需要处理这种情况，比如返回错误信息
  }
  
  // 计算非星号显示部分的长度
  const nonAsteriskPartLength = idCard.length - 4;
  
  // 使用slice获取非星号部分，并生成相应的星号字符串
  // const hiddenPart = new Array(nonAsteriskPartLength).fill('*').join('');
  
  // 返回由原字符串的非隐藏部分和星号组成的字符串
  return idCard.slice(0, nonAsteriskPartLength) + '****';
}