<template>
  <div :class="{ 'report-course-page': true, active: active }">
    <PageSkeleton
      :animate="active"
      rotate
    >
      <ReportLeader
        text="厚积薄发"
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        2022年
      </div>
      <div class="report-text slide-in slide-in-2">
        你参与了<span class="text-bold">{{ reportData.studyCourseCount }}</span>门课程的学习
      </div>
      <div class="report-text slide-in slide-in-3">
        共计<span class="text-bold">{{ reportData.totalStudyHour }}+</span>小时
      </div>
      <div class="report-text slide-in slide-in-4">
        击败了<span class="text-bold">{{ reportData.exceedRatio }}%</span>的同学
      </div>
      <div class="report-text" />
      <div v-if="reportData.totalStudyHour < 5">
        <div class="report-text slide-in slide-in-5">
          放弃不难
        </div>
        <div class="report-text slide-in slide-in-6">
          但坚持一定很酷
        </div>
      </div>
      <div v-else-if="reportData.totalStudyHour > 5 && reportData.totalStudyHour <= 10">
        <div class="report-text slide-in slide-in-5">
          行李很轻
        </div>
        <div class="report-text slide-in slide-in-6">
          梦想很重
        </div>
        <div class="report-text slide-in slide-in-7">
          感谢你从未停止求知和分享
        </div>
      </div>
      <div v-else-if="reportData.totalStudyHour > 10 && reportData.totalStudyHour <= 30">
        <div class="report-text slide-in slide-in-5">
          忙碌想把你埋了
        </div>
        <div class="report-text slide-in slide-in-6">
          但你是一颗种子
        </div>
        <div class="report-text slide-in slide-in-7">
          总能找到破土的方向
        </div>
      </div>
      <div v-else-if="reportData.totalStudyHour > 30 && reportData.totalStudyHour <= 80">
        <div class="report-text slide-in slide-in-5">
          让你变好的事情
        </div>
        <div class="report-text slide-in slide-in-6">
          过程都不会是轻松的
        </div>
        <div class="report-text slide-in slide-in-7">
          未来的你一定会感谢现在努力的自己
        </div>
      </div>
      <div v-else-if="reportData.totalStudyHour > 80">
        <div class="report-text slide-in slide-in-5">
          这并不是一串简单的数字
        </div>
        <div class="report-text slide-in slide-in-6">
          而是你热爱专注的厚度
        </div>
        <div class="report-text" />
        <div class="report-text slide-in slide-in-7">
          你的好运藏在实力里
        </div>
        <div class="report-text slide-in slide-in-8">
          也藏在不为人知的努力里
        </div>
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import { inject } from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
  active: {
    type: Boolean,
    default: false
  }
});
const reportData = inject("reportData")
</script>
<style lang="scss" scoped>
.report-course-page {
  background: url("~@/assets/student/studyReport/bg-course.png");
  background-size: 100%;
}
</style>