<template>
  <div :class="{ 'report-letter-page': true, active: active }">
    <PageSkeleton :animate="active">
      <ReportLeader
        text="一「研」为定"
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        亲爱的<span
          v-if="reportData.authName"
          class="text-bold"
        >{{ reportData.authName }}</span>同学
      </div>
      <div class="report-text slide-in slide-in-2">
        <span class="text-bold clear-left">{{ reportData.firstEnrollTime }}</span>我们第一次相遇
      </div>
      <div class="report-text slide-in slide-in-3">
        从此你的生活有了点小不同
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-4">
        <span class="text-bold clear-left">{{ reportData.totalDay }}</span>天你用心学习
      </div>
      <div class="report-text slide-in slide-in-5">
        付出并没有被遗忘
      </div>
      <div class="report-text slide-in slide-in-6">
        成长也并不孤单
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-7">
        时光记录了
      </div>
      <div class="report-text slide-in slide-in-8">
        你每一个认真的模样
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import {inject} from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
    active: {
        type: Boolean,
        default: false
    }
});
const reportData = inject("reportData")
</script>
<style lang="scss" scoped>
.report-letter-page {
    background: url("~@/assets/student/studyReport/postgraduate/2023/bg-letter.png");
    background-size: 100%;
}
</style>