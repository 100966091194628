
import Pay from '@/components/payComment/payIndex.vue'
import {
    defineComponent,
    onMounted,
    reactive,
} from 'vue'
// import { Dialog, } from 'vant';
import { useRoute } from "vue-router";
import { useStore } from 'vuex'
import { orderDetail } from '@/api/orderpay'
export default defineComponent({
    props: {},
    computed: {},
    components: {
        Pay
    },
    setup () {
        const route = useRoute();
        const store = useStore();
        store.commit('hidePaying')
        //表单数据
        let info = reactive({
            hasPay: route.query.hasPay,
            orderNumber: route.query.orderNumber,
            detail: {
                payStatus: 0,
                isPaid:false,
                shopName: "",
                studentName: "",
                phone: "",
                orderNumber: "",
                tradeNo: "",
                paidTime: "",
                payType: 1,
                orderPrice: 0,
                previewUrl: "",
                firstPayPrice: null,
                remark: "",
                commonContractUrl:"",
                commonContractStatus:null,
                supplementContractUrl:"",
                supplementContractStatus:null,
                channelTypeId:0
            },
            returnUrl: location.href,
            payStatus: {
                0: '订单已取消', 1: '待支付', 2: '支付成功', 3: '退款申请中...', 4: '退款中...', 5: '已退款',6:"订单退款失败",7:"订单退款已拒绝",8:"订单已部分退款",9:"支付成功"/*待补协议 */,10:"订单待审核",11:"订单部分支付"
            },
            payType: { 1: '微信', 2: '支付宝', 3: '虚拟币支付', 4: '苹果支付', 5: '学习卡兑换', 6: '赠送', 7: '优惠券支付', 8: '线下支付', 9: '积分兑换', 10: '免费' }
        })
        // 生命周期
        onMounted(async () => {
            let data = await orderDetail(
                info.orderNumber
            );
            Object.assign(info.detail, data);
        })

        const showPay = async () => {
            store.commit('showPaying')
        }

        // 暴露给模板
        return {
            showPay,
            info,
        }
    },
})
