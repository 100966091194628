<template>
  <div class="reservation-success-page">
    <div class="success-img">
      <img src="@/assets/certificate/success-icon.png" alt="" />
    </div>
    <div class="title">预约成功</div>
    <div class="success-btn" @click="linkToPage">查看证书详情</div>
    <div class="tip">
      <span v-if="receiveType == 2"
        >请本人并携带身份证原件，按时到校区领取。<br />周末及节假日，校区无人，容易跑空哦！</span
      >
      <span v-if="receiveType == 3"
        >请联系代领人携带代领人身份证原件，按时到校区领取。<br />周末及节假日，校区无人，容易跑空哦！</span
      >
      <span v-if="receiveType == 1"
        >校区老师将在7个工作日内进行邮寄，请注意查收！</span
      >
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
const routeQuery = new useRoute();
const router = useRouter();
//证书ID
let id = routeQuery.query.id;
//领取方式 1=邮寄领取, 2=本人现场领取, 3=他人现场领取
const receiveType = ref(routeQuery.query.type);

const linkToPage = () => {
  router.push({
    name: "certificateInfo",
    query: {
      id: id,
    },
  });
};
onMounted(() => {
  document.title =
    routeQuery.query.type == 1
      ? "邮寄领取证书"
      : routeQuery.query.type == 2
      ? "本人现场领取"
      : "他人校区代领";
});
</script>
<style lang="scss" scoped>
.reservation-success-page {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding-top: 128px;
  box-sizing: border-box;
}
.success-img {
  width: 540px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
.title {
  width: 100%;
  text-align: center;
  color: #1e4290;
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 48px;
}
.success-btn {
  margin: 0 50px;
  height: 88px;
  border-radius: 44px;
  color: #fff;
  text-align: center;
  line-height: 88px;
  font-size: 28px;
  background: #1e4290;
}
.tip {
  width: 100%;
  text-align: center;
  margin-top: 24px;
  color: #a2a2a2;
  font-size: 24px;
}
</style>
