<template>
  <div class="createdPost">
    <div class="main">
      <Popup v-model:show="show">
        <div class="textInfo">
          <!-- <div
            class="copyHandle"
            @click="onCopy('text')"
          >
            复制文案
          </div>
          <div class="limitTwo">
            {{ posterList[currentIndex].copywriter }}
          </div> -->
        </div>
        <swiper
          class="swpier"
          :modules="modules"
          :pagination="false"
          :effect="'coverflow'"
          :grab-cursor="true"
          :centered-slides="true"
          :slides-per-view="'auto'"
          :coverflow-effect="{
            rotate: 30,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
        >
          <swiper-slide
            v-for="(item, index) in posterList"
            :key="index"
            class="slide"
          >
            <!-- <div
              :id="'container'+index"
              class="payBgImg opacity0"
            > -->
            <!-- <img id="cavasImg" style="display: none;" src="" alt=""> -->
            <!-- </div> -->
            <div
              id="payBgImg"
              ref="payBgImg"
              :class="'payBgImgSlide' + index"
              :style="`width: 100%;height: 100%; border-radius: 15px;background-size:100% 100%;position:relative;`"
            >
              <img class="bgImgBack" :src="item.bgImg" alt="" />
              <div
                v-show="
                  (item.showCode && wxUtil.checkIsInWx()) ||
                  !wxUtil.checkIsInWx()
                "
                id="imgContainer"
              >
                <VueQr
                  :ref="item.qr"
                  :correct-level="3"
                  :text="item.url"
                  :size="440"
                  :logo-margin="6"
                  :margin="5"
                  class="qrcode"
                  :scale="168"
                  :white-margin="true"
                  :callback="qrBack"
                />
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div
          class="shareInfo"
          :style="wxUtil.checkIsInWx() ? 'height:170px' : 'height:125px;'"
        >
          <div class="flexBox h-center">
            <div
              v-if="wxUtil.checkIsInWx()"
              class="shareTExt mr100"
              @click="saveImg"
            >
              <img src="@/assets/activity/shareCode.png" alt="" />
              <div>生成专属海报</div>
            </div>
            <div v-else class="shareTExt mr100" @click="saveImg">
              <img src="@/assets/activity/saveImg.png" alt="" />
              <div>保存图片</div>
            </div>
            <div class="shareTExt" @click="onCopy('link')">
              <img src="@/assets/activity/copyLink.png" alt="" />
              <div>复制链接</div>
            </div>
          </div>
          <div v-if="wxUtil.checkIsInWx()" class="shareTips">
            请先点击“生成专属海报”，再长按图片转发好友
          </div>
          <div class="flexBox h-center">
            <div class="cancelBtn" @click="closeShare">取消</div>
          </div>
        </div>
        <div
          v-if="showPosterMask"
          class="createPosterMask"
          @click="closePoster"
        >
          <!-- <Icon
            class="chaIcon"
            name="cross"
            size="24"
            color="#ffffff"
            @click="closePoster"
          /> -->
          <div class="tipText">长按图片，转发给亲友吧~</div>
          <div id="container" class="payBgImg opacity0 flexBox h-center" />
        </div>
      </Popup>
    </div>
  </div>
</template>
<script>
import { ref, reactive, onMounted, watch, nextTick, computed } from "vue";
import { useStore } from "vuex";
import { downloadBase64, copyToClipboard } from "@/utils/tools";
import useClipboard from "vue-clipboard3";
import VueQr from "vue-qr/src/packages/vue-qr.vue";
import html2canvas from "@/utils/html2canvas";
import Canvas2Image from "@/utils/canvas2image";
import { Toast, Button, Popup, Icon } from "vant";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination, EffectCoverflow } from "swiper";
import { activitySharing } from "@/api/activity";
import { useRoute } from "vue-router";
import { wxUtil } from "@/utils/wx_util.ts";
import { getDingSignatureInfo } from "@/api/setting";
import * as dd from "dingtalk-jsapi";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
export default {
  components: {
    Popup,
    Swiper,
    SwiperSlide,
    VueQr,
  },
  props: {
    list: {
      type: Array,
    },
    params: {
      type: Object,
    },
  },
  setup(props, context) {
    const { toClipboard } = useClipboard();
    const store = useStore();
    const show = ref(true);
    const payBgImg = ref(),
      pngData = ref(""),
      currentIndex = ref(0),
      posterList = ref([]),
      showPosterMask = ref(false);
    const route = new useRoute();
    console.log(props);

    posterList.value = props.list.map((item) => {
      return {
        showCode: false,
        copywriter: item.copywriter,
        url: item.shortLink,
        logoScale: 0.2,
        logo: require("@/assets/activity/logo2.png"),
        qr: ref(null),
        dpi: window.devicePixelRatio * 4,
        bgImg: item.posterImgUrl,
      };
    });
    const closeShare = () => {
      let image = document.getElementById("cavasImg");
      if (image) {
        image.remove();
      }
      context.emit("closeShare");
    };
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = (swiper) => {
      console.log("slide change");
      currentIndex.value = swiper.activeIndex;
      // let image = document.getElementById("cavasImg")
      // console.log(image)
      // if(image){
      //     image.remove()
      //     posterList.value.forEach(item=>{
      //       item.showCode = false
      //     })

      // }
    };
    const onCopy = async (data) => {
      try {
        if (data == "text") {
          await toClipboard(posterList.value[currentIndex.value].copywriter);
        } else {
          await toClipboard(posterList.value[currentIndex.value].url);
        }
        Toast("复制成功");
      } catch (e) {
        Toast("复制失败");
      }
    };
    const qrBack = async (dataUrl) => {
      console.log("qrback:");
      console.log(dataUrl);
      // posterList[currentIndex.value].qr.value = dataUrl;

      // await nextTick()
      // initUrl()
    };
    const saveImg = async () => {
      let lastIndex = currentIndex.value;
      if (wxUtil.checkIsInWx()) {
        store.commit("showLoading");
        posterList.value[currentIndex.value].showCode = true;
        setTimeout(() => {
          initUrl(lastIndex);
        }, 300);
      } else {
        setTimeout(() => {
          initUrl();
        }, 300);
      }
    };
    const closePoster = () => {
      posterList.value.forEach((item) => {
        item.showCode = false;
      });
      let image = document.getElementById("cavasImg");
      if (image) {
        image.remove();
      }
      showPosterMask.value = false;
    };
    const initUrl = (lastIndex) => {
      // if(document.getElementById('cavasImg')) return
      if (
        document.getElementsByClassName("payBgImgSlide" + currentIndex.value)[0]
      ) {
        (window.html2canvas || html2canvas)(
          document.getElementsByClassName(
            "payBgImgSlide" + currentIndex.value
          )[0],
          {
            allowTaint: true,
            taintTest: false,
            useCORS: true,
            scale: dd.env.platform != "notInDingTalk" ? 1 : 4, //按比例增加分辨率
            dpi: window.devicePixelRatio * 4,
            // scale:window.devicePixelRatio,
            scrollY: 0,
            scrollX: 0,
          }
        ).then(async (canvas) => {
          shareHandle("保存图片");
          pngData.value = canvas.toDataURL("image/png");
          console.log(pngData.value);
          if (wxUtil.checkIsInWx()) {
            showPosterMask.value = true;
            nextTick(() => {
              setTimeout(() => {
                console.log("测试测试测试");
                console.log(document.getElementById("container"));
                let container = document.getElementById("container");
                var img = Canvas2Image.convertToImage(canvas);
                img.setAttribute("width", "100%");
                img.setAttribute("height", "100%");
                img.setAttribute("id", "cavasImg");
                container.appendChild(img);
                store.commit("hideLoading");
              }, 100);
            });

            //
            // document.getElementsByClassName('payBgImgSlide'+currentIndex.value)[0].style.display = "none";
          } else {
            downloadBase64(pngData.value, "海报.png");
          }
        });
      }
    };
    const shareHandle = async (typeString) => {
      await activitySharing({
        ...props.params,
        posterId: route.query.posterId,
        sharedType: typeString,
      });
    };
    onMounted(async () => {
      // initUrl()
      if (dd.env.platform != "notInDingTalk") {
        let data = await getDingSignatureInfo({
          url: location.href,
        });
        console.log("获取钉钉配置");
        console.log(data);
        dd.config({
          agentId: data.agentId, // 必填，微应用ID
          corpId: data.corpId, //必填，企业ID
          timeStamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，自定义固定字符串。
          signature: data.signature, // 必填，签名
          type: 0, //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
          jsApiList: [
            "runtime.info",
            "biz.contact.choose",
            "device.notification.confirm",
            "device.notification.alert",
            "device.notification.prompt",
            "biz.ding.post",
            "biz.util.openLink",
            "biz.util.previewImage",
            "previewImage",
            "saveImage",
          ], // 必填，需要使用的jsapi列表，注意：不要带dd。
        });

        dd.error(function (err) {
          alert("dd error: " + JSON.stringify(err));
        }); //该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
      }
    });

    return {
      show,
      closeShare,
      onSwiper,
      onSlideChange,
      onCopy,
      modules: [EffectCoverflow, Pagination],
      initUrl,
      pngData,
      payBgImg,
      currentIndex,
      saveImg,
      posterList,
      wxUtil,
      showPosterMask,
      closePoster,
    };
  },
};
</script>
<style lang="scss" scoped>
// @import url("../../../assets/css/swiper.min.css");
.coverflow-example {
  height: 380px;
  position: relative;
}

.swiper {
  // height: auto;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 50px;
  z-index: 0;
  position: relative;

  .slide {
    width: 480px;
    height: 840px;
    // border-radius: 15px;
    position: relative;
    .bgImgBack {
      display: block;
      width: 100%;
      height: 100%;
      // object-fit: cover;
      // border-radius: 15px;
    }
  }
}
.createdPost {
  font-size: 28px;
  .main {
    :deep(.van-popup) {
      background: transparent !important;
      height: 100vh;
      overflow: hidden;
      width: 100vw;
    }

    .textInfo {
      color: #fff;
      padding: 40px 25px 20px 25px;
      font-size: 24px;

      .copyHandle {
        background: #ffffff;
        width: 120px;
        height: 36px;
        line-height: 36px;
        border-radius: 6px;
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #1e4290;
        margin-bottom: 20px;
        text-align: center;
      }
    }
    #imgContainer {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 6.05%;
      left: 41.2%;
      .qrcode {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 88px;
        height: 88px;
      }
    }
  }
}

.opacity0 {
  opacity: 1;
  z-index: 99;
  position: absolute;
  top: 75px;
  left: 0;
  width: 100vw;
  height: 100vw * 1.75;
  // height:1122px;
  // background: #fff;
  // margin:90px auto;
  // left: -550px;
}
.payBgImg {
  // border-radius: 15px;
}
.bgImgBack {
  width: 100%;
  height: 100%;
  // border-radius: 15px;
}
.createPosterMask {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // height: 250px;
  background: #000;
  top: 0;
  // pointer-events:none;
  z-index: 1;
  .chaIcon {
    position: absolute;
    top: 28px;
    right: 28px;
  }
  .tipText {
    font-size: 28px;
    color: #fff;
    margin-top: 25px;
    text-align: center;
    font-weight: bolder;
  }
}
.shareInfo {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  // height: 250px;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
  .shareTips {
    font-size: 24px;
    color: #b3b3b3;
    margin: 20px 40px;
    text-align: center;
  }
  .mr100 {
    margin-right: 100px;
  }
  .shareTExt {
    color: #666;
    font-size: 24px;
    img {
      width: 98px;
      margin-bottom: 7px;
    }
  }
  .cancelBtn {
    width: 620px;
    height: 80px;
    line-height: 80px;
    background: #f5f7fa;
    border-radius: 40px;
    color: #333;
    font-size: 28px;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
