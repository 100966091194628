<template>
  <div class="MyClassFile">
    <Popup
      v-model:show="show"
      :closeable="true"
      round
      :close-on-click-overlay="false"
      @close="handleClose"
    >
      <div class="selectClass">
        <div class="boxTitle">
          切换班级
        </div>
        <div class="classInfo">
          <div
            v-for="(item, index) in classList"
            :key="index"
            class="classItem"
            :class="{ 'activeHead': item.classId == currentClass.classId }"
            @click="checkClass(item)"
          >
            {{ item.className }}
          </div>
        </div>
      </div>
    </Popup>
    <div
      v-if="loading"
      class="loading"
    >
      <Loading
        color="#ffffff"
        size="24px"
        type="spinner"
        vertical
      >
        加载中...
      </Loading>
    </div>
    <div class="content">
      <Sticky type="primary">
        <div
          class="headBox flexBox between"
          @click="showClassBox"
        >
          <div class="flexBox">
            <div class="className limitOne">
              {{ currentClass.className }}
            </div>
            <img
              class="bSangle"
              src="@/assets/course/bSangle.png"
            >
          </div>
          <div
            class="logout c-1E4290"
            @click="Logout"
          >
            退出账号
          </div>
        </div>
        <Tabs
          v-if="pageShow"
          v-model:active="subjectActive"
          @click-tab="onClickTab"
        >
          <Tab
            v-for="(item, index) in subjectList"
            :key="index"
            :title="item.subjectName"
          />
        </Tabs>
      </Sticky>
      <template v-if="wareList && wareList.length">
        <div
          v-for="(item, index) in wareList"
          :key="index"
          class="flexBox itemTiao"
        >
          <img
            v-if="getFileType(item.wareUrl) == 'pdf'"
            class="fileImg"
            src="@/assets/file/pdfImg.png"
          >
          <img
            v-else-if="getFileType(item.wareUrl) == 'image'"
            class="fileImg"
            :src="item.ware_url"
          >
          <div
            v-else
            style=" width:32px;height:32px;margin-right:15px;"
          />
          <div
            class="fileName"
            @click="toUrl(item)"
          >
            {{ item.wareName }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="noData">
          <img
            src="@/assets/course/empty.png"
            alt=""
          >
          <div class="noDataText">
            暂无课件
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script  setup>
import { onMounted, reactive, ref, watch, computed } from 'vue';
import { Cell, CellGroup, Popup, Toast, Sticky, Tabs, Tab,Loading  } from 'vant';
import { useRoute, useRouter } from "vue-router";
import { getStuClass, getSubject, getWare } from '@/api/course'
import commonJs from '../../utils/common'
import { useStore } from 'vuex'
import { handleDebounce } from "@/utils/tools"
const show = ref(false), pageShow = ref(false),loading=ref(false)
// const href = ref('https://hd-img-uat.hadecj.com/files/20220725/NAEGX9IAOMAaZOdu/20220725_1658721711541.pdf')
const router = useRouter()
const store = useStore()
const classList = ref(), currentClass = ref({
  className: '',
  classId: ''
}), subjectList = ref(), subjectActive = ref(0), wareList = ref()
const handleClose = () => {
  show.value = false
}
store.commit('setRoute', {
  name: 'MyClassFile',
  query: {}
})
const checkClass = (data) => {
  currentClass.value = data
  show.value = false
  subjectActive.value = 0
  subjectList.value = []
  changeSubject()
}
const showClassBox = () => {
  show.value = true
}
const onClickTab = () => {
  getWareList()
}
onMounted(async () => {
  classList.value = await getStuClass()
  setTimeout(() => {
    pageShow.value = true
  }, 50)

  if (!classList.value.length) return
  // if(classList.value.length>1){
  //   show.value = true

  // }else{
  currentClass.value = classList.value[0]
  changeSubject()
  // }
})
const changeSubject = async () => {
  subjectList.value = await getSubject(currentClass.value.classId)
  console.log(subjectList.value)
  wareList.value = []
  if (subjectList.value.length) {
    getWareList()
  } else {
    wareList.value = []
  }

}
const getWareList = async () => {
  wareList.value = await getWare(currentClass.value.classId, subjectList.value[subjectActive.value].subjectId)
}
const toUrl = handleDebounce((data) => {
  if (getFileType(data.wareUrl) == 'pdf') {
    // let routeData = router.resolve({
    //   name: "PreviewPdf",
    //   query: {
    //       file:(data.wareUrl),
    //       fileName:data.wareName
    //   },
    // })
    // window.open(routeData.href, "_blank");
    
    xhrequest(data)
  } else {
    download(data.wareUrl, data.ware_filename)
  }
}, 1000)
const xhrequest = (v) => {
  let typeJudge = commonJs.judgeUserAgent()
  if(typeJudge.isiOS){
    loading.value = true
    fetch(v.wareUrl)
      .then((response) => response.blob())
      .then((res) => {
          let blod = new Blob([res]);
          downloadFile(res, v.wareFilename);
      });
    }else{
        window.open(v.wareUrl, "_blank")
    }

}
const downloadFile = (data, fileName) => {
  // const reader = new FileReader()
  // // 传入被读取的blob对象
  // reader.readAsDataURL(data)

  // // 读取完成的回调事件
  // reader.onload = (e) => {
  //   let a = document.createElement('a')
  //   a.download = fileName
  //   a.style.display = 'none'
  //   // 生成的base64编码
  //   let url = e.target.result;

  //   a.href = url
  //   document.body.appendChild(a)
  //   a.click()
  //   document.body.removeChild(a)
  //   store.commit('hideLoading')

  //   //  window.open(url, "_blank");
  // }
  let a = document.createElement('a')
  const url = window.URL.createObjectURL(data);
  a.download = fileName
  a.href = url
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a);
  loading.value = false
  window.URL.revokeObjectURL(url);
}
const getFileType = (fileName) => {
  // 后缀获取
  let suffix = '';
  // 获取类型结果
  let result = '';
  try {
    const flieArr = fileName.split('.');
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) { return false; }
  suffix = suffix.toLocaleLowerCase();
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imglist.find(item => item === suffix);
  if (result) {
    return 'image';
  }
  // 匹配txt
  const txtlist = ['txt'];
  result = txtlist.find(item => item === suffix);
  if (result) {
    return 'txt';
  }
  // 匹配 excel
  const excelist = ['xls', 'xlsx'];
  result = excelist.find(item => item === suffix);
  if (result) {
    return 'excel';
  }
  // 匹配 word
  const wordlist = ['doc', 'docx'];
  result = wordlist.find(item => item === suffix);
  if (result) {
    return 'word';
  }
  // 匹配 pdf
  const pdflist = ['pdf'];
  result = pdflist.find(item => item === suffix);
  if (result) {
    return 'pdf';
  }
  // 匹配 ppt
  const pptlist = ['ppt', 'pptx'];
  result = pptlist.find(item => item === suffix);
  if (result) {
    return 'ppt';
  }
  // 匹配 视频
  const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
  result = videolist.find(item => item === suffix);
  if (result) {
    return 'video';
  }
  // 匹配 音频
  const radiolist = ['mp3', 'wav', 'wmv'];
  result = radiolist.find(item => item === suffix);
  if (result) {
    return 'radio';
  }
  // 其他 文件类型
  return 'other';
}
const download = (href, title) => {
  const a = document.createElement('a');
  a.setAttribute('href', href);
  a.setAttribute('download', title);
  a.click();

}
const Logout = () => {
  store.dispatch('Logout')
}

</script>
<style  lang="scss" scoped>
.loading{
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgba(0,0,0,0.3);
  z-index:9999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}
.selectClass {
  font-size: 14px;
  width: 550px;
  min-height: 500px;
  max-height: 1000px;
  overflow-y: scroll;
  padding: 30px;
  color: #666;

  .boxTitle {
    font-size: 30px;
    color: #333;
    text-align: center;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 30px;
  }

  .classInfo {
    font-size: 28px;
    color: #666;
    padding: 15px 0;

    .classItem {
      padding: 15px 0;
      text-align: center;
    }

    .classItem:active {
      color: #0570db;
      font-weight: bolder;
    }
  }

}

.activeHead {
  color: #0570db;
  font-weight: bolder;
}

.content {
  padding: 0 30px;

  .bSangle {
    margin-left: 20px;
    width: 28px;
    height: auto;
  }

  .itemTiao {
    border-bottom: 1px solid #f1f1f1;
    padding: 20px 0;

    .fileImg {
      width: 64px;
      height: 64px;
      margin-right: 30px;
    }

    .fileName {
      font-size: 28px;
      color: #666666;
    }
  }

  .headBox {
    font-size: 30px;
    color: #333333;
    padding: 25px 0 0 0;

    .logout {
      color: #222;
      font-size: 30px;
    }

    .className {
      max-width: 480px;
    }

  }
}

.noData {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 80%;
  }

  .noDataText {
    color: rgb(140, 140, 140);
    font-size: 32px;
    text-align: center;
    margin-top: -125px;
  }
}
</style>