
import { defineComponent, reactive, toRefs, computed } from 'vue';
import { Popup, ConfigProvider, Field, Toast } from 'vant';
import { smsCode } from '@/api/classPurchase'
import {isPhoneNumber,handleDebounce} from "@/utils/tools"
import { useStore} from "vuex";
import sensorsApi from "@/sensors/sensors";
export default defineComponent({
  name: 'classPurchase',
  components:{
    Popup,
    ConfigProvider,
    Field
  },
  props:{
    showModel:{
      default: false,
      type: Boolean
    }
  },
  setup(props, { emit}) {
    const data= reactive({
      isGainActived: false,
      mobile: '',
      sms_code: '',
      type: 2,
      timer: null,
      count: -1,
      codeShow: true,
      loading: false,
      anonymousId:"",
    })
    const store = useStore()
    let show = computed( ()=>{
      return props.showModel
    })
    let activityInfo = computed(()=>{
      return store.state.classPurchase.activityInfo;
    })

    sensorsApi.sensors.quick("isReady", () => {
      data.anonymousId = sensorsApi.sensors.quick("getAnonymousID");
    });

    const themeVars = {
      popupRoundBorderRadius: '10px',
      cellHorizontalPadding: '0px',
      fieldInputTextColor: '#333333',
      fieldPlaceholderTextColor: 'rgba(204, 204, 204, 1)',
      popupCloseIconSize: '18px'
    };

    let onSubmit =handleDebounce(() => {
      if( data.mobile.length !=11 || !data.sms_code) return
      sensorsApi.sensorsList.clickRegister();
      try {
        store.dispatch('handleLogin',{
          mobile: data.mobile,
          sms_code: data.sms_code,
          type: 2,
          client: 1,
          anonymous_id:data.anonymousId,
          trench_id: activityInfo.value.channel,
          activity_id: activityInfo.value.id,
          user_source:"渠道"}).then(()=>{
          handleClose()
        })
      }catch{
        console.log('登录请求失败')
      }

    },500)

    let getCode = () => {
      if(!data.codeShow || data.mobile.length!=11)return;
      if (data.loading) return;
      if (!isPhoneNumber(data.mobile)) {
        Toast( `请检查手机号码是否正确～`);
        return false;
      }
      if (data.count > 0) return;
      sensorsApi.sensorsList.clickGetCode();
      data.loading = true
      smsCode({
        mobile:data.mobile,
        sms_type:'login',
        anonymous_id: data.anonymousId}).then(() => {
        data.loading = false
        if (!data.timer) {
          data.count = 60
          data.codeShow = false
          data.timer = setInterval(() => {
            if (data.count > 0 && data.count <= 60) {
              data.count--
            } else {
              data.codeShow = true
              clearInterval(data.timer)
              data.timer = null
            }
          }, 1000)

        }
      }).catch(() => {
         data.loading = false
      })
    }

    const handleClose = ():void => {
      emit('handleCloseShow')
    }

    let toRefData = toRefs(data)

    return {
      themeVars,
      ...toRefData,
      show,

      onSubmit,
      getCode,
      handleClose
    };
  },
});
