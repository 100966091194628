
import { defineComponent, computed} from "vue";
import { useStore} from "vuex";

export default defineComponent ({
  name: "wxModel",
  setup(){
    const store = useStore();
    let wxModelShow = computed( ()=>{
      return store.state.classPurchase.wxModelShow
    })
    const handleClose = ():void =>{
      store.dispatch('handleSetWxModelShow' , false)
    }
    return{
      wxModelShow,
      handleClose
    }
  }
})
