<template>
  <div class="certificate-pdf">
    <div class="pdf-title">扫描版证书</div>
    <div class="pdf-content" @click="showLayer">
      <img :src="imgData.path" alt="" />
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineExpose, defineEmits } from "vue";
const props = defineProps(["imgData"]);
const emit = defineEmits(["showCertificatePrivew"]);
const showLayer = () => {
  emit("showCertificatePrivew");
};
</script>
<style lang="scss" scoped>
.certificate-pdf {
  width: 100%;
  background: #fff;
  padding: 0 40px;
  box-sizing: border-box;
  .pdf-title {
    font-size: 28px;
    color: #222;
    font-weight: 600;
    padding: 30px 0;
    line-height: 1;
  }
  .pdf-content {
    width: 100%;
    padding-bottom: 30px;
    img {
      width: 100%;
    }
  }
}
</style>
