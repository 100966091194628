<template>
  <div :class="{ 'report-wish-page': true }">
    <PageSkeleton
      :animate="active"
      :show-footer="false"
    >
      <ReportLeader
        :texts="['老师','想对你说']"
        :highlight-index="0"
        highlight-color="#0A64FF"
        center
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        学习是一个看不见的储蓄罐
      </div>
      <div class="report-text slide-in slide-in-2">
        你投入的每一份努力都不会白费
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-3">
        即使没有人鼓掌
      </div>
      <div class="report-text slide-in slide-in-4">
        也要感谢自己的付出
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-5">
        2024年
      </div>
      <div class="report-text slide-in slide-in-6">
        砥砺前行
      </div>
      <div class="report-text slide-in slide-in-7">
        初心如雪
      </div>
      <div class="report-text" />
      <div class="report-text slide-in slide-in-8">
        下一站一起出发
      </div>
      <div
        :class="{ 'btn-replay': true, active: active }"
        @click="replayReport"
      >
        重新回顾
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
  active: {
    type: Boolean,
    default: false
  }
});
const wishEmits = defineEmits(["replay"])
const replayReport = () => {
  wishEmits("replay");
}
</script>
<style lang="scss" scoped>
.report-wish-page {
  background: url("~@/assets/student/studyReport/2023/bg-wish.png");
  background-size: 100%;
  text-align: center;

  .btn-replay {
    width: 300px;
    height: 80px;
    border: 2px solid #333333;
    line-height: 80px;
    font-size: 34px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin: 100px auto 0 auto;
    opacity: 0;
    transform: translateY(40px);
    &.active {
      transition-property: opacity,transform;
      transition-duration: 1.1s;
      transition-timing-function: ease-in;
      transition-delay: 8s;
      transform: translateY(0);
      opacity: 1;
    }
  }

}
</style>