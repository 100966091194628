<template>
  <div class="reservation-info-page">
    <reservationStep :stepType="1" :stepNum="1"></reservationStep>
    <div class="step-content">
      <div class="info-list">
        <div class="info-list-title">邮寄原因</div>
        <div class="info-list-content">
          <div
            class="content-item"
            :class="{ 'content-item-active': index === takeReasonIndex }"
            v-for="(item, index) in takeReasonList"
            :key="item"
            @click="chooseTakeReason(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="info-list">
        <div class="info-list-title">邮寄信息</div>
        <div class="info-list-content">
          <div class="content-left">收件人</div>
          <Field
            class="content-right"
            v-model="userAddress.consignee"
            placeholder="请输入真实姓名"
          />
        </div>
        <div class="info-list-content">
          <div class="content-left">联系电话</div>
          <Field
            class="content-right"
            @blur="checkPhone"
            v-model="userAddress.mobile"
            placeholder="请输入"
          />
        </div>
        <div class="info-list-content">
          <div class="content-left">省/市/区</div>
          <div class="content-right" @click="showArea = true">
            <template v-if="cityName">
              {{ cityName }}
            </template>
            <span v-else>请选择</span>
          </div>
        </div>
        <div class="info-list-content">
          <div class="content-left">详细地址</div>
          <Field
            class="content-right"
            v-model="userAddress.address"
            placeholder="请输入详细收件地址"
          />
        </div>
      </div>
    </div>
    <div class="step-content-bottom">
      请填写正确的收货信息，确认后将不允许修改。证书属于贵重物品，将通过顺丰到付的方式邮寄。
    </div>
  </div>
  <div class="page-bottom">
    <div
      class="bottom-btn"
      :class="{
        'disabled-btn':
          takeReasonIndex == -1 ||
          userAddress.consignee == '' ||
          userAddress.mobile == '' ||
          userAddress.provinceId == '' ||
          userAddress.address == '' ||
          cityName == '',
      }"
      @click="confirmReceive"
    >
      确认邮寄信息
    </div>
  </div>
  <Popup v-model:show="showArea" position="bottom">
    <Area
      :area-list="areaList"
      columns-num="3"
      @confirm="areaConfirm"
      @cancel="showArea = false"
    />
  </Popup>
  <reservationDialog
    v-if="showDialog"
    :dialogType="1"
    :address="userAddress.address"
    :name="userAddress.consignee"
    :phone="userAddress.mobile"
    :cityName="cityName"
    @closeDialog="closeDialog"
    @submitDialog="saveReceive"
  ></reservationDialog>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Field, Area, Popup, Toast } from "vant";
import reservationStep from "./components/reservationStep.vue";
import reservationDialog from "./components/reservationDialog.vue";
import { areaList } from "@vant/area-data";
import { mailReceive, defaultAddress } from "@/api/certificate";
const routeQuery = new useRoute();
const router = useRouter();
const cityName = ref("");
const showArea = ref(false);
const takeReasonList = ref(["人在外地", "时间冲突", "其他个人原因"]);
const takeReasonIndex = ref(-1);
const userAddress = ref({
  provinceId: "",
  cityId: "",
  districtId: "",
  address: "",
  consignee: "",
  mobile: "",
});
const showDialog = ref(false);
//证书ID
let id = routeQuery.query.id;
// 获取默认地址
const getAddress = async () => {
  const res = await defaultAddress();
  if (res.code == 200 && res.data) {
    userAddress.value.provinceId = res.data.provinceId;
    userAddress.value.cityId = res.data.cityId;
    userAddress.value.districtId = res.data.districtId;
    userAddress.value.address = res.data.address;
    userAddress.value.mobile = res.data.mobile;
    userAddress.value.consignee = res.data.userName;
    cityName.value =
      res.data.provinceName + res.data.cityName + res.data.districtName;
  }
};
onMounted(() => {
  getAddress();
});

//选择地区
const areaConfirm = (data) => {
  cityName.value = "";
  for (let index = 0; index < data.length; index++) {
    cityName.value = cityName.value + data[index].name;
  }
  userAddress.value.provinceId = data[0].code;
  userAddress.value.cityId = data[1].code;
  userAddress.value.districtId = data[2].code;
  showArea.value = false;
};
//选择代领原因
const chooseTakeReason = (e) => {
  takeReasonIndex.value = e;
};
//校验手机号
const checkPhone = () => {
  const regex = /^1\d{10}$/;
  if (!regex.test(userAddress.value.mobile)) {
    Toast("手机号码格式不正确");
    return;
  }
};
//提交预约
const saveReceive = async () => {
  const res = await mailReceive({
    id: id,
    takeReason: takeReasonList.value[takeReasonIndex.value],
    userAddress: userAddress.value,
  });
  if (res.code == 200) {
    router.push({
      name: "reservationEntrust",
      query: {
        id: id,
        type: 1,
      },
    });
  }
};
const confirmReceive = () => {
  if (
    takeReasonIndex.value == -1 ||
    userAddress.value.provinceId == "" ||
    userAddress.value.consignee == "" ||
    userAddress.value.mobile == "" ||
    userAddress.value.address == "" ||
    cityName.value == ""
  ) {
    return;
  }
  showDialog.value = true;
};
const closeDialog = () => {
  showDialog.value = false;
};
</script>
<style lang="scss" scoped>
.reservation-info-page {
  width: 100%;
  height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
}
.step-content {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin-top: 40px;
}
.info-list {
  width: 100%;
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
  border-radius: 24px;
  background: #fff;
  margin-bottom: 30px;
  .info-list-title {
    font-size: 28px;
    color: #222;
    font-weight: 600;
    padding: 30px 0;
    border-bottom: 2px solid #eee;
  }
  .info-list-content {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #222;
    margin-top: 26px;
  }
  .content-right {
    flex: 1;
    padding: 0 0 0 40px;
    text-align: right;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    span {
      color: #c8c9cc;
    }
    :deep(.van-field__control) {
      font-size: 24px;
      color: #222;
      text-align: right;
    }
  }
}
.content-item {
  width: 188px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #999999;
  font-size: 24px;
  color: #999;
  margin-right: 30px;
}
.content-item:last-child {
  margin-right: 0;
}
.content-item-active {
  border: 1px solid #1e4290;
  color: #1e4290;
}
.step-content-bottom {
  width: 100%;
  padding: 0 30px;
  font-size: 24px;
  color: #999;
  box-sizing: border-box;
}
.page-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 152px;
  background: #fff;
  padding: 20px 50px 0 50px;
  box-sizing: border-box;
  .bottom-btn {
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #1e4290;
    font-size: 28px;
    color: #fff;
    border-radius: 88px;
  }
  .disabled-btn {
    background: #8997b3;
  }
}
</style>
