
import { Options, Vue} from 'vue-class-component'
import { ref } from 'vue';
import router from '@/router'
import { Form, Field, CellGroup, Button,Checkbox,Toast } from 'vant'
import { useStore } from 'vuex'
import { smsCode } from '@/api/user'
@Options({
  components: {
    Form,
    Field,
    CellGroup,
    Button,
    Checkbox
  },
})

export default class Home extends Vue {
  mobile = ''
  password = ''
  sms_code = ''
  store = null
  // 1是密码登录  2是验证码登录
  loginType=2
  isCloseEye=true  //是否闭上眼睛
  count=60  //倒计时
  checked = ref(false);
  showCount=false  //是否显示倒计时
  pattern = /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
  focusName=ref('')
  currentRoute = {}
  mounted(): void {
    this.store = useStore()
    this.currentRoute = this.store.state.currentRoute
  }
  onSubmit(values: { mobile: string; password: string,sms_code:number }): void {
    let canSub = this.canSubmit()
    if(!canSub) return 
    if(!this.pattern.test(this.mobile)){
       Toast("手机号码格式不正确")
       return 
    }
    if(!this.checked){
      Toast("请勾选用户协议和隐私政策")
      return 
    }
    let currentRoute= this.currentRoute
    console.log(currentRoute)
    this.store.dispatch('Login', {...values,type:this.loginType,client:1}).then(()=>{
      console.log(currentRoute)
      console.log(router)
      if(currentRoute['name'] && currentRoute['name']!='Login' &&currentRoute['name']!='NoData'){
        router.push({name: currentRoute['name'],query:currentRoute['query']})
      }
      else if(currentRoute['name']=='Login' &&currentRoute['name']=='NoData'){
        router.push({name: currentRoute['name'],query:currentRoute['query']})
      }else{
         router.go(-1)
      }
        
    })
  }
  changeType() : void{
    console.log(this.mobile)
    this.loginType = this.loginType == 1?2:1
  }
  changeEye(){
    this.isCloseEye = !this.isCloseEye
  }
  countdown(){
    setTimeout(()=>{
      this.count--
      if(this.count<0){
         this.showCount = false
         this.count = 60
      }else{
        this.countdown()
      }
    },1000)
  }
  changeCount(){
    if(!this.mobile){
      Toast("请输入手机号")
      return false
    }
    if(!this.pattern.test(this.mobile)){
       Toast("手机号码格式不正确")
       return 
    }
   
    this.sendCode()
  }
  sendCode(){
    smsCode({
      mobile:this.mobile,
      sms_type:'login'
    }).then((res) => {
      this.showCount = !this.showCount
      this.countdown()
      Toast('发送成功')
    })    
  }
  canSubmit(){
    if(this.loginType==1){
      return this.mobile.length && this.password.length
    }else{
      return  this.mobile.length && this.sms_code.length
    }
  }
  focusHandle(event,target){
    this.focusName = target
  }
  toLink(url){
    location.href=url
  }
  
}
