<template>
    <div class="FeeRecord">
        <div class="cardList">
              <CardItem :list="info.data.list"></CardItem>
        </div>
         <div class="reLogin" @click="toIndex" >返回首页</div>
        <!-- <div class="noData">暂无更多购买记录</div> -->
    </div>
</template>
<script>
import CardItem from './components/feeCard.vue'
import {
  onMounted,
  reactive
} from 'vue'
import { useRoute,useRouter } from "vue-router";
import { getRecordList } from '@/api/reportFee'
export default {
   name:'FeeRecord',
    components: {
     CardItem 
  },
  setup(){
    // 生命周期
    const route = useRoute();
    const router = useRouter()
    const info = reactive({
        data:{}
    })
    onMounted(async () => {
       let list = await getRecordList(route.query.feePlanStudentId)
       if(!list.length){
           router.replace({
               name:'NoData',
               query:{
                data:"暂无购买记录",
                feePlanId: route.query.feePlanId
               }
           })
       }
       list.forEach(element => {
           element.isChecked = true
       });
       info.data["list"] = list
    })
     const toIndex = ()=>{
            router.push({
                name:'Settlement',
                query:{
                    feePlanId:route.query.feePlanId
                }
            })
        }
    return {
        info,
        toIndex
    }
  }

}
</script>
<style lang="scss" scoped>
    .FeeRecord{
        min-height: 100vh;
        overflow-y:auto;
        background: #F5F8FF;
        padding-bottom:30px;
        .cardList{
            padding:30px 40px 0 40px;
        }
        .noData{
            font-size: 28px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            text-align: center;
        }
        
    }
     .reLogin{
        width: 620px;
        height: 80px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 80px;
        border-radius: 40px;
        margin:74px auto 50px auto;
        background: #157CFF;
        text-align: center;
    }
</style>