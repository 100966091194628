import Decimal from "decimal";

export default {
  scale: price => {
    return Decimal.mul(price, 100).toNumber();
  },
  resume: price => {
    return Decimal.div(price, 100).toNumber();
  },
  nochange: price => {
    return Decimal.ride(price, 1).toNumber();
  },
  add: (v1, v2) => {
    return Decimal(v1).add(v2).toNumber();
  },
  sub: (v1, v2) => {
    return Decimal(v1).sub(v2).toNumber();
  },
  mul: (v1, v2) => {
    return Decimal(v1).mul(v2).toNumber();
  },
  div: (v1, v2) => {
    return Decimal(v1).div(v2).toNumber();
  },
};
