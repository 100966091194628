<template>
  <template v-for="field in fields">
    <div
      v-if="field.component == 'input'"
      v-show="field.visible"
      :key="field.name"
    >
      <Field
        v-model="formState[field.name]"
        :required="field.required"
        :rules="field.rules"
        :type="field.inputType || 'text'"
        :maxlength="field.maxlength"
        :placeholder="(field.readonly || !editable) ? '未填写' : (field.placeholder || '请输入')"
        :name="field.name"
        :readonly="field.readonly || !editable"
        :label-width="field.labelWidth ? field.labelWidth : '3.4rem'"
        :autosize="field.inputType == 'textarea'"
      >
        <template #label>
          <span>{{ field.label }}</span>
        </template>
      </Field>
    </div>
    <div
      v-if="field.component == 'select'"
      v-show="field.visible"
      :key="field.name"
    >
      <Field
        v-model="field.text"
        readonly
        :is-link="editable"
        :required="field.required"
        :placeholder="!editable ? '未选择' : (field.placeholder || '请选择')"
        :rules="field.rules"
        :name="field.name"
        :label-width="field.labelWidth ? field.labelWidth : '3.4rem'"
        @click="popUpPicker(field)"
      >
        <template #label>
          <span>{{ field.label }}</span>
        </template>
      </Field>
      <HdPicker
        v-model:show="field.show"
        v-model:value="formState[field.name]"
        v-model:text="field.text"
        :columns="field.options"
      />
    </div>
    <div
      v-if="field.component == 'area'"
      v-show="field.visible"
      :key="field.name"
    >
      <Field
        v-model="field.text"
        readonly
        :is-link="editable"
        :required="field.required"
        :placeholder="!editable ? '未选择' : (field.placeholder || '请选择')"
        :rules="field.rules"
        :name="field.name"
        :label-width="field.labelWidth ? field.labelWidth : '3.4rem'"
        @click="popUpPicker(field)"
      >
        <template #label>
          <span>{{ field.label }}</span>
        </template>
      </Field>
      <!--省市区选择器-->
      <AreaSelector
        v-model:show="field.show"
        v-model:text="field.text"
        v-model:provice-id="formState[field.provinceIdKey]"
        v-model:city-id="formState[field.cityIdKey]"
        v-model:area-id="formState[field.areaIdKey]"
        :level="field.level ? field.level : 2"
        :optional="!field.required"
        @selected="data => areaSelected(data, field)"
      />
    </div>
    <div
      v-if="field.component == 'image'"
      v-show="field.visible"
      :key="field.name"
    >
      <Field
        readonly
        :is-link="editable"
        :required="field.required"
        :placeholder="!editable ? '' : (field.placeholder || '请上传')"
        :rules="field.rules"
        :name="field.name"
        :label-width="field.labelWidth ? field.labelWidth : '3.4rem'"
        @click.prevent.stop="chooseFile(field, formState[field.name] && formState[field.name].persist)"
      >
        <template #label>
          <span>{{ field.label }}</span><span
            v-if="field.help"
            class="help-icon"
            @click.prevent.stop="field.helpAction"
          />
        </template>
        <template #input>
          <div class="upload-status">
            <div
              v-if="!editable && !formState[field.name]"
              class="text-muted"
            >
              未上传
            </div>
            <div
              v-else-if="!formState[field.name]"
              class="text-muted"
            >
              {{ field.placeholder || "请上传"
              }}
            </div>
            <div
              v-else
            >
              {{
                field.text
              }}
            </div>
            <img
              v-if="formState[field.name] && formState[field.name].persist"
              src="~@/assets/student/statusInfo/check.png"
              class="check-icon"
            >
          </div>
        </template>
      </Field>
      <UploadOssFile
        v-show="false"
        :ref="el => dynamicRef(el, field.name)"
        :multiple="false"
        @success="data => uploadSuccess(data, field)"
      />
      <div
        v-if="formState[field.name] && !formState[field.name].persist"
        class="idcard-upload-container"
      >
        <div class="image-preview">
          <img
            :src="formState[field.name].path"
            class="image"
            @click="previewImg(formState[field.name].path)"
          >
          <div
            v-show="editable"
            class="close-btn"
            @click="clearImg(field)"
          />
        </div>
      </div>
    </div>
    <!--custom process idcard-->
    <div
      v-if="field.name == 'idCard'"
      v-show="field.visible"
      :key="field.name"
    >
      <Field
        :required="field.required"
        readonly
        :rules="field.rules"
        :placeholder="!editable ? '' : '请上传清晰的证件正反面照片'"
        :label-width="field.labelWidth ? field.labelWidth : '3.4rem'"
      >
        <template #label>
          <span>{{ field.label }}</span>
        </template>
      </Field>
      <div class="idcard-upload-container">
        <div class="mr-40">
          <div
            v-if="formState.idCardFront || !editable"
            class="image-preview"
          >
            <div v-if="!editable || formState.idCardFront.persist">
              <img
                src="~@/assets/student/statusInfo/idcard-face.png"
                class="image"
              >
              <div :class="['uploaded-tip', formState.idCardFront ? 'text-success' : '']">
                {{ formState.idCardFront ? '已上传' : '未上传' }}
              </div>
              <div
                v-show="editable"
                class="close-btn"
                @click="clearImg(field, 'idCardFront')"
              />
            </div>
            <div v-else>
              <img
                :src="formState.idCardFront.path"
                class="image"
                @click="previewImg(formState.idCardFront.path, formState.idCardBack && !formState.idCardBack.persist ? formState.idCardBack.path : null)"
              >
              <div
                v-show="editable"
                class="close-btn"
                @click="clearImg(field, 'idCardFront')"
              />
            </div>
          </div>
          <div v-else>
            <UploadOssFile
              :multiple="false"
              :readonly="!editable"
              @success="data => uploadSuccess(data, field, 'idCardFront')"
            >
              <img
                src="~@/assets/student/statusInfo/idcard-face.png"
                class="image-preview"
              >
            </UploadOssFile>
            <div class="upload-tip">
              上传正面人像照片
            </div>
          </div>
        </div>
        <div>
          <div
            v-if="formState.idCardBack || !editable"
            class="image-preview"
          >
            <div v-if="!editable || formState.idCardBack.persist">
              <img
                src="~@/assets/student/statusInfo/idcard-badge.png"
                class="image"
              >
              <div :class="['uploaded-tip', formState.idCardBack ? 'text-success' : '']">
                {{ formState.idCardBack ? '已上传' : '未上传' }}
              </div>
              <div
                v-show="editable"
                class="close-btn"
                @click="clearImg(field, 'idCardBack')"
              />
            </div>
            <div v-else>
              <img
                :src="formState.idCardBack.path"
                class="image"
                @click="previewImg(formState.idCardBack.path, formState.idCardFront && !formState.idCardFront.persist ? formState.idCardFront.path : null)"
              >
              <div
                v-show="editable"
                class="close-btn"
                @click="clearImg(field, 'idCardBack')"
              />
            </div>
          </div>
          <div v-else>
            <UploadOssFile
              :multiple="false"
              :readonly="!editable"
              @success="data => uploadSuccess(data, field, 'idCardBack')"
            >
              <img
                src="~@/assets/student/statusInfo/idcard-badge.png"
                class="image-preview"
              >
            </UploadOssFile>
            <div class="upload-tip">
              上传背面国徽照片
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup>
import { ref, inject } from "vue";
import { Field, Dialog } from 'vant';
import UploadOssFile from "@/components/UploadOssFile.vue"
import AreaSelector from "@/components/AreaSelector.vue"
import HdPicker from "@/components/HdPicker.vue"
defineProps({
    fields: {
        type: Array,
        default: () => []
    },
})
const formState = inject("formState");
const editable = inject("editable");

const uploadSuccess = (data, field, key) => {
    formState[key ? key : field.name] = data;
    if (field.hasOwnProperty("text")) {
        field.text = "重新上传";
    }
}
const previewImg = (img1, img2) => {
    const imgs = [img1];
    if (img2) {
        imgs.push(img2);
    }
    ImagePreview(imgs);
}
const clearImg = (field, key) => {
    if (field.hasOwnProperty("text")) {
        field.text = "";
    }
    formState[key ? key : field.name] = null;
}

const popUpPicker = field => {
    if (editable.value) {
        field.show = true;
    }
}
const dynamicRefs = ref([]);
const dynamicRef = (el, name) => {
    dynamicRefs.value.push({ name, el });
}
const chooseFile = (field, needCheck) => {
    if (!editable.value) return;
    const refObj = dynamicRefs.value.find(dr => dr.name == field.name);
    if (needCheck) {
        Dialog.confirm({
            title: '提示',
            message:
                `你已成功上传[${field.label}],如果没有更新，无需重新上传。`,
            cancelButtonText: "暂不上传",
            cancelButtonColor: "#AAAAAA",
            confirmButtonText: "重新上传",
            confirmButtonColor: "#333333",
            beforeClose: action => onConfirmReUploadImage(action, refObj)
        });
    } else if (refObj) {
        refObj.el.chooseFile();
    }
}
const onConfirmReUploadImage = async (action, refObj) => {
    if (action == "confirm") {
        refObj.el.chooseFile();
        return true;
    }
    return true;
}

const areaSelected = (data, field) => {
    if (field.onChange) {
        field.onChange(data);
    }
}
</script>
<style lang="scss" scoped>
.idcard-upload-container {
    padding: 24px var(--van-cell-horizontal-padding) 40px var(--van-cell-horizontal-padding);
    display: flex;
}

.mr-40 {
    margin-right: 40px;
}

.upload-tip {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 34px;
    text-align: center;
    margin-top: 20px;
}

.image-preview {
    width: 216px;
    height: 144px;
    position: relative;

    .image {
        width: 216px;
        height: 144px;
        border-radius: 8px;
        border: 2px solid #EEEEEE;
        object-fit: contain;
    }

    .uploaded-tip {
        width: 216px;
        height: 144px;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 144px;
        text-align: center;
        font-size: 28px;
        pointer-events: none;
    }

    .close-btn {
        width: 34px;
        height: 34px;
        position: absolute;
        top: -17px;
        right: -17px;
        background: url('~@/assets/student/statusInfo/close-btn.png') no-repeat;
        background-size: cover;
        background-position: center;
        cursor: pointer;
    }
}

.text-muted {
    color: var(--van-field-placeholder-text-color);
}

.text-success {
    color: var(--van-success-color);
}
.upload-status{
    display: flex;
    align-items: center;
    line-height: 1;
    .check-icon{
        margin-left: 20px;
        width: 28px;
    }
}
.help-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: text-bottom;
    background: url('~@/assets/student/statusInfo/question.png') no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    margin-left: 20px;
}
</style>