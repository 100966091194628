<template>
  <template v-if="(targetFileds.includes('intentionPublic') || targetFileds.includes('intentionSchool')) && intentionList && intentionList.length">
    <div
      v-for="(item, index) in intentionList"
      :key="index"
    >
      <div class="colleage-header">
        <div>{{ item.className }}</div>
      </div>
      <Field
        v-if="targetFileds.includes('intentionPublic')"
        v-model="item.intentionPublic"
        readonly
        :label-width="'3.4rem'"
      >
        <template #label>
          <span>统考考区</span>
        </template>
      </Field>
      <Field
        v-if="targetFileds.includes('intentionSchool')"
        v-model="item.intentionSchool"
        readonly
        :label-width="'3.4rem'"
      >
        <template #label>
          <span>校考考区</span>
        </template>
      </Field>
    </div>
  </template>
</template>
<script setup>
import { inject,computed} from "vue";
import { Field } from 'vant';
defineProps({
    targetFileds: {
        type: Array,
        default: () => {
            return []
        }
    }
})
const formState = inject("formState");
const intentionList = computed(()=>{
  const {intentionList:list} = formState;
  console.log(list);
  if(list && list.length){
    return list.map(l=>{
      l.intentionPublic = l.generalProvince + (l.generalCity ? "/" + l.generalCity : "");
      l.intentionSchool = l.schoolCity;
      return l;
    })
  }
  return [];
})
</script>
<style lang="scss" scoped>
.colleage-header {
    background-color: #F5F7FA;
    font-size: var(--van-cell-font-size);
    color: #333;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    .layer{
        color: #1E4290;
    }
}
</style>