<template>
  <div :class="{ 'report-exam-page': true, active: active }">
    <PageSkeleton :animate="active">
      <ReportLeader
        :texts="['王者', '荣耀']"
        :highlight-index="1"
        highlight-color="#7D4CFF"
        left
        :active="active"
      />
      <div class="report-text slide-in slide-in-1">
        2023年
      </div>
      <div class="report-text slide-in slide-in-2">
        <span>你通过了<span class="text-highlight">{{ reportData.passSubjectCount }}</span>科考试</span>
      </div>
      <div v-if="reportData.maxPassSubjectScore >= 85">
        <div class="report-text" />
        <div class="report-text slide-in slide-in-3">
          其中
        </div>
        <div
          v-for="(subject, index) in reportData.maxPassSubjects"
          :key="index"
          class="report-text slide-in"
          :class="['slide-in-' + (index + 4)]"
        >
          <span class="text-highlight clear-left">{{ subject }}</span>
        </div>
        <div
          class="report-text slide-in"
          :class="['slide-in-' + (reportData.maxPassSubjects.length + 4)]"
        >
          取得了<span class="text-highlight">{{ reportData.maxPassSubjectScore }}</span>的好成绩
        </div>
      </div>
      <div class="report-text" />
      <div
        class="report-text slide-in"
        :class="['slide-in-' + nextAni(3)]"
      >
        越努力越幸运
      </div>
      <div
        class="report-text slide-in"
        :class="['slide-in-' + nextAni(4)]"
      >
        今朝秣马坚如铁
      </div>
      <div
        class="report-text slide-in"
        :class="['slide-in-' +nextAni(5)]"
      >
        他日功成朝天阙
      </div>
    </PageSkeleton>
  </div>
</template>
<script setup>
import { inject } from "vue";
import PageSkeleton from "./PageSkeleton.vue";
import ReportLeader from "./ReportLeader.vue";
defineProps({
  active: {
    type: Boolean,
    default: false
  }
});
const reportData = inject("reportData")
const nextAni = (base)=>{
  if(reportData.maxPassSubjectScore >= 85){
    return reportData.maxPassSubjects.length + base + 2;
  }
  return base;
}
</script>
<style lang="scss" scoped>
.report-exam-page {
  background: url("~@/assets/student/studyReport/2023/bg-exam.png");
  background-size: 100%;
  text-align: left;

  .text-highlight {
    color: #7D4CFF;
  }
}
</style>