<template>
  <overlay :show="layerStatus" @click="closeLayer">
    <div class="certificate-layer">
      <div class="layer-bg" v-if="isFirst">
        <!-- <div class="layer-content" @click="showImgPreview">
          <img :src="infoData.certificateFile.path" alt="" />
        </div> -->
      </div>
      <div class="layer-content" @click="showImgPreview">
        <img :src="infoData.certificateFile.path" alt="" />
      </div>
      <template v-if="!isWX">
        <div class="layer-bottom" :class="{ 'flex-btn': !isFirst }">
          <template v-if="!isFirst">
            <div
              class="bottom-btn"
              @click.stop="savaCertificate(infoData.certificateFile.path)"
            >
              保存证书
            </div>
          </template>
          <template v-else>
            <div
              class="btn-left"
              @click.stop="savaCertificate(infoData.certificateFile.path)"
            >
              保存证书
            </div>
            <div class="btn-right" @click.stop="linkToPage">领取证书</div>
          </template>
        </div>
      </template>
      <template v-if="isWX">
        <div class="layer-bottom" :class="{ 'flex-btn': !isFirst }">
          <div class="bottom-btn" @click.stop="linkToPage">领取证书</div>
        </div>
      </template>
    </div>
  </overlay>
</template>
<script setup>
import { ref, defineProps, defineExpose, defineEmits } from "vue";
import { ImagePreview, Overlay } from "vant";
import { useRouter } from "vue-router";
import commonJs from "@/utils/common";
const props = defineProps(["layerStatus", "infoData", "isFirst", "isWX"]);
const emit = defineEmits(["closeCertificatePrivew"]);
const router = useRouter();
const closeLayer = () => {
  emit("closeCertificatePrivew");
};
const showImgPreview = () => {
  ImagePreview({
    images: [props.infoData.certificateFile.path],
    showIndex: false,
    minZoom: 1,
  });
};
const savaCertificate = (url) => {
  let typeJudge = commonJs.judgeUserAgent();
  if (typeJudge.isiOS) {
    window.webkit.messageHandlers.savaCertificate.postMessage(url); //Contract交互的方法名
  } else {
    window.hade.savaCertificate(url);
  }
};
const linkToPage = () => {
  router.push({
    name: "receiveType",
    query: {
      id: props.infoData.id,
    },
  });
};
</script>
<style lang="scss" scoped>
.certificate-layer {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100vh;
  // background: rgba(000, 000, 000, 0.7);
  // z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  .layer-bg {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 840px;
    transform: translateY(-50%);
    background: url("~@/assets/certificate/first-bg.png") no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    z-index: 1;
  }
  .layer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 630px;
    height: 888px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      border-radius: 16px;
    }
  }
  .layer-bottom {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 168px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 44px 44px 0 0;
    padding: 0 40px;
    box-sizing: border-box;
    justify-content: space-between;
    .btn-left,
    .btn-right {
      width: 315px;
      height: 88px;
      border-radius: 44px;
      border: 1px solid #222222;
      text-align: center;
      line-height: 88px;
      font-size: 28px;
      color: #222;
    }
    .btn-right {
      color: #fff;
      background: #1e4290;
      border: 1px solid #1e4290;
    }
  }
  .flex-btn {
    padding: 0 50px;
  }
  .bottom-btn {
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 88px;
    background: #1e4290;
    font-size: 28px;
    color: #fff;
    border-radius: 88px;
  }
}
</style>
